import React from "react";
import Icon from "../Icons/Icon";
import styles from "./Button.module.scss";

const Button = ({
  label,
  hasIcon,
  iconPosition,
  icon,
  color,
  handleClick,
  size,
  background,
  padding,
  height,
  iconColor,
  border,
}) => {
  const hasLIcon = hasIcon && iconPosition === "left";
  const hasRIcon = hasIcon && iconPosition === "right";
  return (
    <div
      className={`${styles.main} ${styles[color]}`}
      onClick={handleClick}
      style={{
        width: size,
        height: height,
        background: background,
        padding: padding,
        border: border,
      }}
    >
      {hasLIcon && icon !== null && icon !== undefined && icon !== "" ? (
        <Icon icon={icon} color={iconColor} />
      ) : (
        <></>
      )}
      <span className={styles.label}>{label}</span>
      {hasRIcon && icon !== null && icon !== undefined && icon !== "" ? (
        <Icon icon={icon} color={iconColor} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Button;
