import React, { useEffect, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import {
  SelectInput,
  TagInput,
  TextInput,
  ValueInput,
} from "../../../Components/Atoms/Input/Input";
import { Button, Icon, ProgressBar, Toast } from "../../../Components/Atoms";
import styles from "./_Main.module.scss";
import RTEditor from "../../../Components/Atoms/Input/InputTypes/MarkdownEditor/RTEditor";
import {
  useCreateAVariantMutation,
  useGetAProductQuery,
  useGetAllCategoriesQuery,
  useUpdateAProductMutation,
  useUpdateAVariantMutation,
} from "../../../redux/services/client/accounts";
import DeleteProduct from "../../../Components/Modals/DeleteProduct/DeleteProduct";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router";
import {
  VARIANT_COLORS,
  VARIANT_SIZES,
  VARIANT_VOLUME,
} from "../AddProduct/AddProduct.helper";
import VariantCard from "../../../Components/Cards/Variants/VariantCard";

const UpdateProduct = () => {
  const currentPath = window.location.pathname;
  const productID = currentPath.split("/").pop().toLowerCase();
  const [categoryID, setCategoryID] = useState({});
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });
  const [variantsCount, setVariantsCount] = useState(0);
  const [showContainer, setShowContainer] = useState(false);
  const [isEmptyForm, setIsEmptyForm] = useState(false);
  const variants = Array.from({ length: variantsCount }, (_, index) => index);
  const [variantContainerKey, setVariantContainerKey] = useState(0);

  const [variantStates, setVariantStates] = useState(
    variants.map(() => ({
      size: "",
      color: {},
      volume: "",
      description: "",
      imageDataUrl: "",
      productsAvailable: "",
      price: "",
      id: "",
    }))
  );

  const [currentVariant, setCurrentVariant] = useState({
    productsAvailable: "",
    color: "",
    productPrice: "",
    productSize: "",
    productImage: "",
    productDescription: "",
    productVolume: "",
    index: "",
    colorName: "",
    id: "",
  });

  const isEditingVariant = Object.values(currentVariant).some(
    (value) => value !== ""
  );

  const navigate = useNavigate();
  const phoneScreenWidth = 451;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const [modalOpen, setModalOpen] = useState(false);

  const { message, status, heading } = toastMessage;

  const [imageLoadProgress, setImageLoadProgress] = useState(0);

  const { data: categoryDetails } = useGetAllCategoriesQuery();

  const {
    data: productDetails,
    isError: getProductError,
    error: productError,
  } = useGetAProductQuery({
    id: productID,
  });

  const {
    title,
    image: { url = {} } = {},
    description,
    price,
    inventory_count,
    variants: product_variants,
    color,
    size,
    volume,
    category,
  } = productDetails?.data || {};

  const [imageDataUrl, setImageDataUrl] = useState(url || "");

  const [productData, setProductData] = useState({
    productTitle: title,
    productDescription: description,
    productPrice: price,
    inventoryCount: inventory_count,
    color: color,
    size: size,
    volume: volume,
  });

  const isVariantSelected =
    productData.color || productData.volume || productData.size;

  const [
    updateProduct,
    {
      isLoading,
      isSuccess: productUpdateSuccess,
      isError: productUpdateError,
      error,
    },
  ] = useUpdateAProductMutation();

  const [
    createVariant,
    {
      isLoading: createVariantLoading,
      isSuccess: createVariantSuccess,
      isError: createAVariantError,
      variantCreateError,
    },
  ] = useCreateAVariantMutation();

  const [
    updateVariant,
    {
      isLoading: updateVariantLoading,
      isSuccess: updateVariantSuccess,
      isError: updateAVariantError,
      variantUpdateError,
    },
  ] = useUpdateAVariantMutation();

  const formatCategories = (categories) => {
    return categories?.map((category) => ({
      label: category.title || "",
      value: category.id || "",
    }));
  };

  const handleAddVariant = async () => {
    // console.log(variantStates);
    const lastVariant = variantStates[variantStates.length - 1];

    const hasNonEmptyValue =
      lastVariant &&
      Object.values(lastVariant).some((value) => {
        if (typeof value === "object" && value !== (null || undefined)) {
          return Object.values(value).some((nestedValue) => nestedValue !== "");
        }
        return value !== "";
      });
    if (!showContainer) {
      setShowContainer(true);
      setVariantContainerKey((prevKey) => prevKey + 1);
      setVariantsCount((prevCount) => prevCount + 1);
      setVariantStates((prevStates) => [
        ...prevStates,
        {
          size: "",
          color: {},
          volume: "",
          description: "",
          imageDataUrl: "",
          productsAvailable: "",
          price: "",
        },
      ]);
    } else {
      if (!hasNonEmptyValue) {
        // console.log("falsyyy");
        setIsEmptyForm(true);
      } else {
        if (isEmptyForm) {
          setIsEmptyForm(false);
        }
        await createVariant({
          size: lastVariant.size,
          color: lastVariant.color?.value,
          volume: lastVariant.volume,
          description: lastVariant.description,
          image: lastVariant.imageDataUrl,
          inventory_count: lastVariant.productsAvailable,
          price: lastVariant.price,
          id: productID,
        });

        setVariantStates((prevStates) => [
          ...prevStates,
          {
            size: "",
            color: {},
            volume: "",
            description: "",
            imageDataUrl: "",
            productsAvailable: "",
            price: "",
          },
        ]);
        setShowContainer(false);
      }
    }
  };

  const convertBase64 = (file, callback) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const base64 = fileReader.result.split(",")[1];
      callback(base64);
    };

    fileReader.readAsDataURL(file);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      convertBase64(file, (base64) => {
        const newImageDataUrl = `data:${file.type};base64,${base64}`;
        setImageDataUrl(newImageDataUrl);
      });
    }
  };

  const handleFileUploadForVariant = (event) => {
    const file = event.target.files[0];

    if (file) {
      convertBase64(file, (base64) => {
        const newImageDataUrl = `data:${file.type};base64,${base64}`;
        document.getElementById(
          "variantLabel"
        ).style.background = `linear-gradient(0deg, rgba(16, 18, 19, 0.70) 0%, rgba(16, 18, 19, 0.70) 100%), url(${newImageDataUrl}) lightgray 50% / cover no-repeat`;
        if (isEditingVariant) {
          setCurrentVariant((prevState) => ({
            ...prevState,
            productImage: newImageDataUrl,
          }));
        } else {
          setVariantStates((prevStates) => [
            ...prevStates.slice(0, prevStates.length - 1),
            {
              ...prevStates[prevStates.length - 1],
              imageDataUrl: newImageDataUrl,
            },
          ]);
        }
      });
    }
  };

  const handleVariantEdit = ({
    productsAvailable,
    color,
    productPrice,
    productSize,
    productImage,
    productDescription,
    productVolume,
    index,
    colorName,
    id,
  }) => {
    if (!showContainer) {
      setShowContainer(true);
    }
    setCurrentVariant({
      productsAvailable,
      color,
      productPrice,
      productSize,
      productImage,
      productDescription,
      productVolume,
      index,
      colorName,
      id,
    });
  };

  const handleUpdateVariant = async () => {
    if (currentVariant.index !== "") {
      const variantId = parseInt(currentVariant?.id, 10);
      // console.log("id1 is", parseInt(currentVariant?.id, 10));
      // console.log("id1 is", currentVariant?.id);
      const currentVariants = {
        size: currentVariant.productSize,
        color: currentVariant.productColor?.value,
        volume: currentVariant.productVolume,
        description: currentVariant.productDescription,
        image: currentVariant.productImage,
        inventory_count: currentVariant.productsAvailable,
        price: currentVariant.productPrice,
        variant_id: variantId,
        id: productID,
      };

      await updateVariant(currentVariants);
    }
  };

  const handleInputChange = (field, value) => {
    setProductData({
      ...productData,
      [field]: value,
    });
  };

  const formatProductVariants = (variants) => {
    return variants?.map((variant) => ({
      size: variant.size || "",
      color: { value: variant.color } || {},
      volume: variant.volume || "",
      description: variant.description || "",
      imageDataUrl: variant.image?.url || "",
      productsAvailable: variant.inventory_count || "",
      price: variant.price || "",
      id: variant.id || "",
    }));
  };

  useEffect(() => {
    setProductData({
      productTitle: title,
      productDescription: description,
      productPrice: price,
      inventoryCount: inventory_count,
      color: color,
      size: size,
      volume: volume,
    });
    setImageDataUrl(url);
    setVariantStates(formatProductVariants(product_variants));
    setVariantsCount(product_variants?.length);
  }, [productDetails]);

  useEffect(() => {
    if (
      imageDataUrl !== url &&
      imageDataUrl !== "" &&
      imageDataUrl !== undefined
    ) {
      const interval = setInterval(() => {
        if (imageLoadProgress < 100) {
          setImageLoadProgress((prevState) => (prevState += 10));
        } else {
          setImageLoadProgress(0);
          clearInterval(interval);
        }
      }, 50);
    }

    return () => {};
  }, [imageDataUrl]);

  useEffect(() => {
    if (getProductError && productError?.status === 401) {
      setToastMessage({
        message: productError?.data?.message || "Something went wrong",
        status: "error",
        heading: productError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (getProductError && productError?.status !== 401) {
      setToastMessage({
        message: productError?.data?.message || "Something went wrong",
        status: "error",
        heading: productError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [getProductError, productError?.data, productError?.status]);

  // useEffect(() => {
  //   if (isEmptyForm) {
  //     setToastMessage({
  //       message: "Form is empty, cannot another variant",
  //       status: "error",
  //       heading: "Error",
  //     });
  //   }

  //   console.log(variantStates);
  //   console.log("variantsCount is", variantsCount);

  //   return () => {};
  // }, [isEmptyForm, variantStates, showContainer, variantsCount]);

  useEffect(() => {
    if (isEmptyForm) {
      setToastMessage({
        message: "Form is empty, cannot another variant",
        status: "error",
        heading: "Error",
      });
    }
    return () => {};
  }, [isEmptyForm]);

  useEffect(() => {
    if (productUpdateSuccess) {
      setToastMessage({
        message: "Product updated successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (productUpdateError && error?.status === 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (productUpdateError && error?.status !== 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [productUpdateSuccess, productUpdateError, error?.data, error?.status]);

  useEffect(() => {
    if (createVariantSuccess) {
      setToastMessage({
        message: "Variant created successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (createAVariantError && variantCreateError?.status === 401) {
      setToastMessage({
        message: variantCreateError?.data?.message || "Something went wrong",
        status: "error",
        heading: variantCreateError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (productUpdateError && variantCreateError?.status !== 401) {
      setToastMessage({
        message: variantCreateError?.data?.message || "Something went wrong",
        status: "error",
        heading: variantCreateError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [
    createVariantSuccess,
    createAVariantError,
    variantCreateError?.data,
    variantCreateError?.status,
  ]);

  // console.log(variantStates);

  useEffect(() => {
    if (updateVariantSuccess) {
      setToastMessage({
        message: "Variant updated successfully",
        status: "success",
        heading: "Success",
      });

      setCurrentVariant({
        productsAvailable: "",
        color: "",
        productPrice: "",
        productSize: "",
        productImage: "",
        productDescription: "",
        productVolume: "",
        index: "",
        colorName: "",
        id: "",
      });
      setShowContainer(false);
    }

    if (updateAVariantError && variantUpdateError?.status === 401) {
      setToastMessage({
        message: variantUpdateError?.data?.message || "Something went wrong",
        status: "error",
        heading: variantUpdateError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (updateAVariantError && variantUpdateError?.status !== 401) {
      setToastMessage({
        message: variantUpdateError?.data?.message || "Something went wrong",
        status: "error",
        heading: variantUpdateError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [
    updateVariantSuccess,
    updateAVariantError,
    variantUpdateError?.data,
    variantUpdateError?.status,
  ]);

  const handleUpdateProduct = async () => {
    const dynamicProductData = {};

    dynamicProductData.id = productID;

    if (productData.productTitle !== title) {
      dynamicProductData.title = productData.productTitle.trim();
    }

    if (productData.productDescription !== description) {
      dynamicProductData.description = productData.productDescription;
    }

    if (productData.productPrice !== price) {
      dynamicProductData.price = productData.productPrice;
    }

    if (productData.inventoryCount !== inventory_count) {
      dynamicProductData.inventory_count = productData.inventoryCount;
    }

    if (imageDataUrl !== url) {
      dynamicProductData.image = imageDataUrl;
    }

    if (productData.size !== size) {
      dynamicProductData.size = productData.size;
    }
    if (productData.color !== color) {
      dynamicProductData.color = productData.color;
    }
    if (productData.volume !== volume) {
      dynamicProductData.volume = productData.volume;
    }

    if (categoryID?.value !== category?.id) {
      dynamicProductData.business_category_id = categoryID?.value;
    }

    if (Object.keys(dynamicProductData).length > 0) {
      await updateProduct(dynamicProductData);
    }
  };

  return (
    <>
      <DeleteProduct
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        productImage={imageDataUrl}
        inventoryAvailable={productData.inventoryCount}
        price={productData.productPrice}
        productTitle={productData.productTitle}
        productId={productID}
      />
      {isEmptyForm ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {productUpdateSuccess || productUpdateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {createVariantSuccess || createAVariantError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      {updateVariantSuccess || updateAVariantError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <section className={styles["update-product"]}>
        <Form headerTitle={"Update Product"} hasTitle={false}>
          <label
            htmlFor="browseFiles"
            className={styles["product-btn"]}
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundAttachment: "fixed",
              background:
                "linear-gradient(0deg, rgba(16, 18, 19, 0.70) 0%, rgba(16, 18, 19, 0.70) 100%), url(" +
                imageDataUrl +
                ") lightgray 50% / cover no-repeat",
            }}
          >
            <input
              id="browseFiles"
              type="file"
              accept=".jpg, .jpeg, .png, .webm"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <span className={styles["product-btn__plus"]}>
              <Icon icon={"plus-small"} color={"white"} />
            </span>
            {imageDataUrl ? "Change product image" : "Upload product image"}
          </label>
          {imageLoadProgress > 0 && imageLoadProgress < 100 && (
            <ProgressBar progress={`${imageLoadProgress}%`} />
          )}
          <h1 className="form-title-main">Product Information</h1>
          <SelectInput
            hasIcon
            hasLabel
            enabled={categoryDetails?.data?.length > 0}
            label={"Category"}
            placeholder={category?.title || "Select Category"}
            option={formatCategories(categoryDetails?.data)}
            selectedOption={(item) => setCategoryID(item)}
          />
          <TextInput
            hasLabel
            label={"Title"}
            placeholder="eg. 50KG Bag of Rice"
            value={productData.productTitle}
            onChange={(e) => handleInputChange("productTitle", e.target.value)}
          />
          <div className="description-long">
            <label>Description</label>
            <textarea
              value={productData.productDescription}
              onChange={(e) =>
                handleInputChange("productDescription", e.target.value)
              }
              placeholder="Enter description here"
            />
          </div>
          <TagInput
            label={"Size"}
            placeholder={size || "Select size category"}
            allTags={(size) => handleInputChange("size", size)}
            suggestion={VARIANT_SIZES}
            hasLabel
            type={"productVariant"}
          />
          <div className="half-width-form">
            <SelectInput
              hasIcon
              hasLabel
              label={"Color"}
              placeholder={
                color ? (
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 6,
                      marginLeft: "-4px",
                    }}
                  >
                    {" "}
                    <span
                      className={styles["dropdown-icon-container"]}
                      style={{
                        width: 16,
                        height: 16,
                        minWidth: 16,
                        minHeight: 16,
                        maxWidth: 16,
                        maxHeight: 16,
                        background: color,
                        borderRadius: 16,
                      }}
                    ></span>{" "}
                    {VARIANT_COLORS.find((item) => item.value === color)?.label}
                  </p>
                ) : (
                  "Select"
                )
              }
              option={VARIANT_COLORS}
              enabled
              selectedOption={(color) =>
                handleInputChange("color", color.value)
              }
              hasListIcon
            />

            <TagInput
              label={"Volume"}
              placeholder={volume || "Select"}
              allTags={(volume) => handleInputChange("volume", volume)}
              suggestion={VARIANT_VOLUME}
              hasLabel
              type={"productVariant"}
            />
          </div>
          <div className="half-width-form">
            <ValueInput
              hasLabel
              label={"Price"}
              quantifier={"NGN"}
              value={productData.productPrice}
              onChange={(e) =>
                handleInputChange("productPrice", e.target.value.trim())
              }
            />
            <ValueInput
              hasLabel
              label={"Total Products Available"}
              quantifier={"#"}
              value={productData.inventoryCount}
              onChange={(e) =>
                handleInputChange("inventoryCount", e.target.value.trim())
              }
            />
          </div>
          {isVariantSelected && (
            <div className={styles["flex-col-8"]}>
              <h1 className="form-title-main">Product Variants</h1>
              <label style={{ marginTop: -8 }}>
                Use 'Add Variants' to include different options for your
                product, such as sizes or colors.
              </label>
              {showContainer && !isEditingVariant
                ? variantStates
                    .slice(0, variantStates.length - 1)
                    .map((variant, index) => (
                      <VariantCard
                        key={index}
                        index={index}
                        productColor={variant.color}
                        productPrice={variant.price}
                        productSize={variant.size}
                        productsAvailable={variant.productsAvailable}
                        productImage={variant.imageDataUrl}
                        productDescription={variant.description}
                        productVolume={variant.volume}
                        variantInfo={handleVariantEdit}
                        color={variant?.color?.value}
                        colorName={variant?.color?.value}
                        id={variant.id}
                        display={
                          isEditingVariant && index === currentVariant.index
                            ? "none"
                            : ""
                        }
                      />
                    ))
                : variantStates?.map((variant, index) => (
                    <VariantCard
                      key={index}
                      index={index}
                      productColor={variant.color}
                      productPrice={variant.price}
                      productSize={variant.size}
                      productsAvailable={variant.productsAvailable}
                      productImage={variant.imageDataUrl}
                      productDescription={variant.description}
                      productVolume={variant.volume}
                      variantInfo={handleVariantEdit}
                      color={variant?.color?.value}
                      colorName={variant?.color?.value}
                      id={variant.id}
                      display={
                        isEditingVariant && index === currentVariant.index
                          ? "none"
                          : ""
                      }
                    />
                  ))}

              {showContainer && (
                <div
                  key={variantContainerKey}
                  className={styles["variant-container"]}
                >
                  <label
                    id="variantLabel"
                    htmlFor={`browseFiles_${variantStates.length + 1}`}
                    className={styles["product-btn"]}
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundAttachment: "fixed",
                      background:
                        "linear-gradient(0deg, rgba(16, 18, 19, 0.70) 0%, rgba(16, 18, 19, 0.70) 100%), url(" +
                        currentVariant.productImage +
                        ") lightgray 50% / cover no-repeat",
                    }}
                  >
                    <input
                      id={`browseFiles_${variantStates.length + 1}`}
                      type="file"
                      accept=".jpg, .jpeg, .png, .webm"
                      onChange={(event) => handleFileUploadForVariant(event)}
                      style={{ display: "none" }}
                    />
                    <span className={styles["product-btn__plus"]}>
                      <Icon icon={"plus-small"} color={"white"} />
                    </span>
                    {isEditingVariant
                      ? "Change variant image"
                      : "Upload variant image"}
                  </label>
                  {productData?.size && (
                    <TagInput
                      label={"Size"}
                      placeholder={
                        isEditingVariant
                          ? currentVariant.productSize
                          : "Select size category"
                      }
                      allTags={(size) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productSize: size,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              size,
                            },
                          ]);
                        }
                      }}
                      suggestion={VARIANT_SIZES}
                      hasLabel
                      type={"productVariant"}
                    />
                  )}
                  <div className="half-width-form">
                    {productData?.color && (
                      <SelectInput
                        hasIcon
                        hasLabel
                        label={"Color"}
                        placeholder={
                          isEditingVariant ? (
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 6,
                                marginLeft: "-4px",
                              }}
                            >
                              {" "}
                              <span
                                className={styles["dropdown-icon-container"]}
                                style={{
                                  width: 16,
                                  height: 16,
                                  minWidth: 16,
                                  minHeight: 16,
                                  maxWidth: 16,
                                  maxHeight: 16,
                                  background: currentVariant.color,
                                  borderRadius: 16,
                                }}
                              ></span>{" "}
                              {
                                VARIANT_COLORS.find(
                                  (item) => item.value === currentVariant.color
                                )?.label
                              }
                            </p>
                          ) : (
                            "Select"
                          )
                        }
                        option={VARIANT_COLORS}
                        enabled
                        selectedOption={(variantColor) => {
                          if (isEditingVariant) {
                            setCurrentVariant((prevState) => ({
                              ...prevState,
                              productColor: variantColor,
                            }));
                          } else {
                            setVariantStates((prevStates) => [
                              ...prevStates.slice(0, prevStates.length - 1),
                              {
                                ...prevStates[prevStates.length - 1],
                                color: variantColor,
                              },
                            ]);
                          }
                        }}
                        hasListIcon
                      />
                    )}

                    {productData?.volume && (
                      <TagInput
                        label={"Volume"}
                        placeholder={
                          isEditingVariant
                            ? currentVariant.productVolume
                            : "Select"
                        }
                        allTags={(volume) => {
                          if (isEditingVariant) {
                            setCurrentVariant((prevState) => ({
                              ...prevState,
                              productVolume: volume,
                            }));
                          } else {
                            setVariantStates((prevStates) => [
                              ...prevStates.slice(0, prevStates.length - 1),
                              {
                                ...prevStates[prevStates.length - 1],
                                volume,
                              },
                            ]);
                          }
                        }}
                        suggestion={VARIANT_VOLUME}
                        hasLabel
                        type={"productVariant"}
                      />
                    )}
                  </div>
                  <div className="half-width-form">
                    <ValueInput
                      hasLabel
                      label={"Price"}
                      quantifier={"NGN"}
                      onChange={(e) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productPrice: e.target.value,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              price: e.target.value,
                            },
                          ]);
                        }
                      }}
                      placeholder={
                        isEditingVariant && currentVariant.productPrice
                      }
                    />
                    <ValueInput
                      hasLabel
                      label={"Total Products Available"}
                      quantifier={"#"}
                      onChange={(e) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productsAvailable: e.target.value,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              productsAvailable: e.target.value,
                            },
                          ]);
                        }
                      }}
                      placeholder={
                        isEditingVariant && currentVariant.productsAvailable
                      }
                    />
                  </div>
                  <div className="description-long">
                    <label>Description</label>
                    <textarea
                      onChange={(e) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productDescription: e.target.value,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              description: e.target.value,
                            },
                          ]);
                        }
                      }}
                      placeholder={
                        isEditingVariant
                          ? currentVariant.productDescription
                          : "Enter description here"
                      }
                    />
                  </div>
                  {isEditingVariant && (
                    <div className={styles["create-variant-btn"]}>
                      {" "}
                      <Button
                        color={"completed-opacity"}
                        hasIcon={updateVariantLoading}
                        icon={updateVariantLoading && "spinner-alt"}
                        iconPosition={"left"}
                        iconColor={"completed"}
                        label={updateVariantLoading ? "" : "Save Changes"}
                        height={36}
                        size={137}
                        handleClick={handleUpdateVariant}
                      />{" "}
                    </div>
                  )}
                </div>
              )}
              {!isEditingVariant && (
                <button className="product-btn" onClick={handleAddVariant}>
                  {createVariantLoading ? (
                    <Icon icon={"spinner-alt"} color={"white"} />
                  ) : (
                    <>
                      {" "}
                      <span className="product-btn__plus">
                        <Icon icon={"plus-small"} color={"white"} />
                      </span>
                      {showContainer
                        ? "Create Variant"
                        : !showContainer && variantsCount > 0
                        ? "Add Another Variant"
                        : "Add Variants"}{" "}
                    </>
                  )}
                </button>
              )}
            </div>
          )}
          <div className={styles["lower-btns"]}>
            <Button
              label="Delete Product"
              color={"error-opacity"}
              size={!isPhoneScreen ? "35%" : "50%"}
              height={36}
              handleClick={() => setModalOpen(!modalOpen)}
            />
            <Button
              color={"completed"}
              label={isLoading ? "" : "Update Product"}
              hasIcon
              iconPosition="right"
              icon={isLoading ? "spinner-alt" : ""}
              handleClick={handleUpdateProduct}
              height={36}
              size={!isPhoneScreen ? "65%" : "50%"}
            />
          </div>
        </Form>
      </section>
    </>
  );
};

export default UpdateProduct;
