import LightLogo from "./logos/logo-light.svg";
import DarkLogo from "./logos/logo-dark.svg";
import useLocalStorage from "use-local-storage";
import { Link } from "react-router-dom";
import styles from "./Logo.module.scss";

const Logo = ({ width, height }) => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const localTheme = useLocalStorage("theme")[0];
  const theme =
    localTheme !== null && localTheme !== undefined && localTheme !== ""
      ? localTheme
      : defaultDark
      ? "dark"
      : "light";
  const logo = theme === "dark" ? DarkLogo : LightLogo;
  return (
    <Link to={"/clients/dashboard"} className={styles.link}>
      <img
        className={styles.logo}
        style={{ width: width, height: height }}
        src={logo}
        alt="/"
      />
    </Link>
  );
};

export default Logo;
