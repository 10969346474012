import { createSlice } from "@reduxjs/toolkit";
const sampleClient = {
  value: {
    id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    emailIsUsable: false,
    hasValidEmail: false,
    alreadyExists: false,
    password: "",
    gender: "",
    account_type: "",
    otpValue: "",
    validOtp: false,
    hasValidPassword: false,
    phoneNumber: "",
    reference: "",
    isInvited: false,
    hasProjects: false,
    countries: [],
    states: [],
    cities: [],
    links: {},
    profileDetails: null,
    selectedCart: [],
    isReadyToGoLive: false,
    activeStatus: "new",
  },
};

export const clientSlice = createSlice({
  name: "client",
  initialState: sampleClient,
  reducers: {
    updateClientData: (state, action) => {
      let keys = Object.keys(action.payload);
      keys.map((key) => {
        state.value[key] = action.payload[key];
        return true;
      });
    },
    hasValidEmail: (state) => {
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      return emailPattern.test(state.value.email);
    },
  },
});

export const emptyClient = {
  value: {
    id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    emailIsUsable: false,
    hasValidEmail: false,
    alreadyExists: false,
    password: "",
    gender: "",
    account_type: "",
    otpValue: "",
    validOtp: false,
    hasValidPassword: false,
    phoneNumber: "",
    reference: "",
    isInvited: false,
    hasProjects: false,
    countries: [],
    states: [],
    cities: [],
    links: {},
    profileDetails: null,
    selectedCart: [],
    isReadyToGoLive: false,
    activeStatus: "new",
  },
};

export const { updateClientData, hasValidEmail } = clientSlice.actions;

export default clientSlice.reducer;
