import React, { useEffect, useState } from "react";
import styles from "./Cart.module.scss";
import Form from "../../../Components/Bases/Form/Form";
import CartItem from "../../../Components/Cards/Cart/CartItem/CartItem";
import { Button, LineBreak, Loader, Toast } from "../../../Components/Atoms";
import {
  useGetCartQuery,
  useGetShopProfileQuery,
} from "../../../redux/services/client/buyer";
import { useNavigate } from "react-router-dom";
import { localStorageCart } from "../helpers";
import CurrencyFormat from "react-currency-format";

const Cart = () => {
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;
  const currentPath = window.location.pathname;

  const pathnameParts = currentPath.split("/");

  const shopSlug = pathnameParts[1];

  const existingAwashopData = JSON.parse(
    localStorage.getItem(localStorageCart)
  );

  const matchingCartItem = existingAwashopData?.find(
    (item) => item.slug === shopSlug
  );

  const { cartId } = matchingCartItem || {};

  const {
    isFetching,
    isError: profileQueryError,
    error: profileError,
  } = useGetShopProfileQuery({
    shop_slug: shopSlug,
  });
  const {
    data: cartDetails,
    isSuccess,
    isError,
    error,
    isLoading: cartFetching,
  } = useGetCartQuery(
    {
      shop_slug: shopSlug,
      cart_id: cartId,
    },
    { skip: !cartId }
  );

  const navigate = useNavigate();

  const {
    service_charge: charge,
    vat_charge: tax,
    cost_total: subtotal,
    total,
  } = cartDetails?.data || {};

  const originalOrderItems = cartDetails?.data?.order_items;
  const sortedOrderItems = originalOrderItems
    ? [...originalOrderItems].sort((a, b) => b.id - a.id)
    : [];

  // useEffect(() => {
  //   if (profileFetchSuccess) {
  //     dispatch(
  //       updateBuyerData({
  //         shopSlug: slug,
  //       })
  //     );
  //   }

  //   return () => {};
  // }, [profileFetchSuccess, profileDetails]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     dispatch(
  //       updateBuyerData({
  //         cartId: cartDetails?.data?.id,
  //       })
  //     );
  //   }

  //   return () => {};
  // }, [isSuccess, cartDetails]);

  useEffect(() => {
    if (isError || profileQueryError) {
      if (error?.status === 404 || profileError?.status === 404) {
        navigate("*");
      }
    }

    if (isError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isError, profileQueryError, error?.data, profileError, error]);
  return (
    <section className={styles.cart} style={{ width: "100%" }} id="myCart">
      {isError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"myCart"}
        />
      ) : (
        ""
      )}
      {isFetching ? (
        <Loader size={70} />
      ) : cartFetching ? (
        <Loader size={50} />
      ) : (
        <Form headerTitle={"My Cart"}>
          {sortedOrderItems?.map((item) => (
            <CartItem
              productName={item.product.title}
              productDescription={item.product.description}
              subtotal={item.subtotal}
              price={item.product.price}
              productImage={item.product.image?.url}
              itemsAvailable={item.product.inventory_count}
              productId={item.product_id}
              key={item.id}
              quantity={item.quantity}
              itemID={item.id}
              cartID={cartId}
              shopSlug={shopSlug}
            />
          ))}

          <LineBreak color={"var(--shade-10)"} />
          <ul className={styles.form}>
            <h6 className={styles.title}>Summary</h6>
            <li>
              Subtotal{" "}
              <p className={styles.price}>
                NGN{" "}
                <span className={styles.bold}>
                  {" "}
                  <CurrencyFormat
                    value={subtotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </span>
              </p>
            </li>
            <li>
              Charge{" "}
              <p className={styles.price}>
                NGN{" "}
                <span className={styles.bold}>
                  {" "}
                  <CurrencyFormat
                    value={charge}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </span>
              </p>
            </li>
            <li>
              Tax{" "}
              <p className={styles.price}>
                NGN{" "}
                <span className={styles.bold}>
                  {" "}
                  <CurrencyFormat
                    value={tax}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </span>
              </p>
            </li>
          </ul>
          <LineBreak color={"var(--shade-10)"} />
          <div className={styles.bottom}>
            Total{" "}
            <p className={styles.total}>
              NGN{" "}
              <span className={styles["total-bold"]}>
                {" "}
                <CurrencyFormat
                  value={total}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </span>
            </p>
          </div>
          <Button
            color={"completed"}
            label={"Continue to Checkout"}
            height={36}
            handleClick={() => navigate(`/${shopSlug}/cart/checkout`)}
          />
        </Form>
      )}
    </section>
  );
};

export default Cart;
