import React from "react";
import { Icon } from "../../Atoms";
import styles from "./Form.module.scss";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../../ScrollToTop";

const Form = ({
  headerTitle,
  customChildren,
  children,
  size,
  title,
  hasTitle,
  position,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <ScrollToTopOnMount />
      <div
        className={styles.form}
        style={{
          maxWidth: size || 549,
          width: size || 549,
          position: position,
        }}
      >
        <div className={styles.header}>
          <button className={styles.btn} onClick={() => navigate(-1)}>
            <Icon icon="arrow-small-left" color="shade-0" />
          </button>
          <h3 className={styles.title}>{headerTitle}</h3>
        </div>
        {customChildren}
        {hasTitle ? <h1 className={styles["title-main"]}>{title}</h1> : ""}
        {children}
      </div>
    </>
  );
};

Form.defaultProps = {
  hasTitle: true,
};

export default Form;
