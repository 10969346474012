import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const countriesApi = createApi({
  reducerPath: "countriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://space-backend-xkivl.ondigitalocean.app/api/v1/fe`,
    // prepareHeaders: (headers) => {
    //   let token = localStorage.getItem("authToken");
    //   if (token) {
    //     headers.set("Authorization", `Bearer ${token}`);
    //   }
    //   return headers;
    // },
    headers: {
      "Content-Type": "application/json",
    },
  }),
  endpoints: (builder) => ({
    getCountriesList: builder.query({
      query: () => ({
        url: "locations/countries",
        method: "GET",
      }),
    }),
    getStatesList: builder.query({
      query: (countryAlpha) => ({
        url: `locations/${countryAlpha.country_alpha2}/states`,
        method: "GET",
      }),
    }),
    getCitiesList: builder.query({
      query: (params) => ({
        url: `locations/${params.country_alpha2}/${params.state_code}/cities`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCountriesListQuery,
  useGetStatesListQuery,
  useGetCitiesListQuery,
} = countriesApi;
