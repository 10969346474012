//Add Link Component
import React from "react";
import { CompositeDecorator, EditorState, Modifier } from "draft-js";

const Link = ({ entityKey, contentState, children }) => {
  let { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      style={{ color: "blue", fontStyle: "italic" }}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

// call all together
export const onAddLink = (editorState, setEditorState) => {
  const decorator = createLinkDecorator();
  const selection = editorState.getSelection();

  // get selected content
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
  const currentKey = currentBlock.getEntityAt(selection.getStartOffset());
  const entity = currentKey ? currentContent.getEntity(currentKey) : null;
  const entityType = entity ? entity.getType() : null;

  const setLink = () => {
    let linkUrl = window.prompt("Add link http:// ");

    // check if link was entered and if actual text was selected
    if (linkUrl && selection) {
      // create entity for link
      currentContent.createEntity("LINK", "MUTABLE", {
        url: linkUrl,
      });

      // get key of link entity
      let entityKey = currentContent.getLastCreatedEntityKey();

      // apply created entity to selection
      const entry = Modifier.applyEntity(currentContent, selection, entityKey);

      // create new state & set editor to new state
      let newState = EditorState.createWithContent(entry, decorator);
      setEditorState(newState);
    }
  };

  if (entity == null) {
    setLink();
  } else if (entityType === "LINK") {
    const entry = Modifier.applyEntity(currentContent, selection, null);
    let newState = EditorState.createWithContent(entry, decorator);
    setEditorState(newState);
  } else {
    setLink();
  }
};
