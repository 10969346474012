import React, { useEffect, useRef, useState } from "react";
import Icon from "../Icons/Icon";
import { useRequestToGoLiveMutation } from "../../../redux/services/client/accounts";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../Toasts/Toast";
import styles from "../../Bases/DashboardBase/DashboardBase.module.scss";
import { clientPages } from "../../../Routes/routes";

const RequestToGoLive = ({ verificationStatus, liftHeight }) => {
  const containerRef = useRef(null);

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });
  const { message, status, heading } = toastMessage;
  const [
    requestToGoLive,
    {
      isSuccess: requestToGoLiveSuccess,
      isError: requestToGoLiveError,
      error: goLiveError,
      isLoading: requestToGoLiveLoading,
    },
  ] = useRequestToGoLiveMutation();

  const navigate = useNavigate();

  const handleRequestToGoLive = async () => {
    await requestToGoLive();
  };

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.offsetHeight;
      liftHeight(height);
    }
  }, [verificationStatus, containerRef?.current, liftHeight]);

  useEffect(() => {
    if (requestToGoLiveSuccess) {
      setToastMessage({
        message: "Your business is pending credibility check!",
        status: "success",
        heading: "Success",
      });
    }

    if (requestToGoLiveError && goLiveError?.status === 401) {
      setToastMessage({
        message: goLiveError?.data?.message || "Something went wrong",
        status: "error",
        heading: goLiveError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    }

    if (requestToGoLiveError && goLiveError?.status !== 401) {
      setToastMessage({
        message: goLiveError?.data?.message || "Something went wrong",
        status: "error",
        heading: goLiveError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [
    requestToGoLiveSuccess,
    requestToGoLiveError,
    goLiveError?.data,
    goLiveError?.status,
  ]);
  return (
    <>
      {requestToGoLiveSuccess ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      {requestToGoLiveError && goLiveError?.status !== 401 ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {verificationStatus === "new" || verificationStatus === "pending" ? (
        <div className={styles["request-live"]} ref={containerRef}>
          <div className={styles["request-live__top"]}>
            <p className={styles["request-live-text"]}>Request to Go Live</p>
            <Icon
              icon={"shield-check"}
              color={"white"}
              width={24}
              height={24}
            />
          </div>
          <ul className={styles["request-live__bottom"]}>
            <li>
              <span className={`${styles.icon} ${styles.checked}`}>
                <Icon icon="check" color="white" width={8} height={8} />
              </span>{" "}
              Phone number & ID verified
            </li>
            <li>
              <span className={styles.icon}>
                <Icon icon="info" color="white" width={8} height={8} />
              </span>{" "}
              {verificationStatus === "new" ? (
                <p>
                  <Link
                    to={"/clients/dashboard/product/add"}
                    style={{
                      listStyle: "none",
                      color: "var(--shade-0)",
                    }}
                  >
                    {" "}
                    Add products
                  </Link>{" "}
                  and{" "}
                  <Link
                    to={"/clients/dashboard/links"}
                    style={{
                      listStyle: "none",
                      color: "var(--shade-0)",
                    }}
                  >
                    update social media links
                  </Link>{" "}
                  to increase credibility of your business before requesting to
                  go live.
                </p>
              ) : verificationStatus === "pending" ? (
                "Your request to go live is processing"
              ) : (
                ""
              )}
            </li>
          </ul>
          {verificationStatus === "new" ? (
            <button
              className={styles["request-live-btn"]}
              onClick={handleRequestToGoLive}
            >
              {requestToGoLiveLoading ? (
                <Icon
                  icon={"spinner-alt"}
                  width={14}
                  height={14}
                  color={"white"}
                />
              ) : (
                "Request to Go Live"
              )}
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RequestToGoLive;
