import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "../Input/InputTypes/Select/Select.module.scss";
import Card from "../Card/Card";
import Icon from "../Icons/Icon";
import Search from "../Input/InputTypes/Search/Search";
import { useSelector } from "react-redux";
import CountryFlag from "../CountryFlag/CountryFlag";

const CountrySelect = ({
  label,
  enabled,
  selectedOption,
  classes,
  placeholder,
  countryList,
  hasCountryCode,
  searchPlaceholder,
  type,
  width,
  top,
  hasLabel,
}) => {
  const [selected, setSelected] = useState(
    placeholder || {
      countryName: "",
      flag: "",
    }
  );
  const [dropdown, setDropdown] = useState(false);
  const handleSelection = (name, alpha2, postal_code, slug) => {
    setSelected({
      countryName: name,
      flag: slug,
    });
    selectedOption({ alpha2, postal_code, name });
  };
  return (
    <div
      className={`${classes} space-ui-select-input`}
      style={{ minWidth: width, maxWidth: width }}
    >
      {hasLabel && <label htmlFor="select-input">{label}</label>}
      <Card
        className={styles["input-container"]}
        id="select-input"
        style={{
          marginTop: hasLabel && "10px",
          cursor: !enabled ? "not-allowed" : "",
        }}
      >
        <div
          className={styles["input-container__inner"]}
          onClick={() => enabled && setDropdown(!dropdown)}
          style={{ cursor: !enabled ? "not-allowed" : "" }}
        >
          <div className={styles.left}>
            {type === "country" ? (
              <CountryFlag country={"nigeria" || selected.flag} />
            ) : (
              ""
            )}

            <p
              className={styles["main-text"]}
              style={{
                color:
                  !enabled && type !== "country"
                    ? "var(--shade-100)"
                    : "var(--shade-0)",
              }}
            >
              {selected.countryName || placeholder}
            </p>
          </div>
          <Icon icon="chevronDown" color={enabled ? "shade-0" : "shade-100"} />
        </div>
        {dropdown ? (
          <Search
            countryList={countryList}
            setDropdown={setDropdown}
            dropdown={dropdown}
            placeholder={searchPlaceholder}
            setSelectedCountry={setSelected}
            handleSelection={handleSelection}
            hasCountryCode={hasCountryCode}
            type={type}
            top={top}
          />
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

CountrySelect.defaultProps = {
  enabled: true,
  type: "country",
  hasLabel: true,
};

CountrySelect.propTypes = {
  hasLabel: PropTypes.bool,
  hasIcon: PropTypes.bool,
  selected: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  enabled: PropTypes.bool,
  Select: PropTypes.func,
};

export default React.memo(CountrySelect);
