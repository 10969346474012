import React, { useEffect } from "react";
import EmptyState from "../../Components/Atoms/EmptyState/EmptyState";
import styles from "./Main.module.scss";

const Main = () => {
  useEffect(() => {
    const auth = localStorage.getItem("authToken");

    if (auth !== null && auth !== undefined) {
      localStorage.removeItem("authToken");
    }
    return () => {};
  }, []);

  return (
    <section className={styles["error-page"]}>
      <EmptyState title={"404"} subtitle={"Page not found"} />
    </section>
  );
};

export default Main;
