import React from "react";
import styles from "./ProgressBar.module.scss";
const ProgressBar = ({ progress }) => {
  return (
    <div className={styles["progressBar-container"]}>
      <div
        className={styles.progressAnimation}
        style={{ width: progress }}
      ></div>
    </div>
  );
};

export default ProgressBar;
