import React, { useEffect, useState } from "react";
import styles from "../Onboarding/Steps/Form.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../Components/Atoms";
import Form from "../Onboarding/Steps/Form";
import { TextInput } from "../../Components/Atoms/Input/Input";
import Base from "../Onboarding/Base/Base";
import { updateClientData } from "../../redux/features/newClient";
import { useLazyRequestPasswordResetQuery } from "../../redux/services/client/onboarding";

const VerifyEmail = () => {
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const client = useSelector((state) => state.client.value);

  const { message, status, heading } = toastMessage;

  const dispatch = useDispatch();

  const [requestReset, { data, isFetching, isSuccess, isError, error }] =
    useLazyRequestPasswordResetQuery();

  const validateEmail = (emailAddress) => {
    const emailPattern =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/;
    return emailPattern.test(emailAddress);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleContinueToVerify();
    }
  };

  const handleEmailInput = (e) => {
    const { value } = e.target;
    const emailAddress = value.trim();

    dispatch(updateClientData({ email_address: emailAddress }));
  };

  const handleContinueToVerify = async () => {
    await requestReset({ email: client?.email_address });
  };

  useEffect(() => {
    if (isSuccess) {
      setToastMessage({
        message:
          "Password Reset email would be sent if an account exists with this email",
        status: "success",
        heading: "Success",
      });
    }

    if (isError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isSuccess, isError, error?.data]);

  return (
    <>
      {isSuccess ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {isError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <Base classes={styles["steps-form"]} stepsPage={false}>
        <Form
          buttonLabel={isFetching ? "" : "Continue"}
          color={"completed"}
          handleButtonClick={handleContinueToVerify}
          onKeyPress={handleKeyPress}
          icon={isFetching ? "spinner-alt" : ""}
        >
          <div className={styles.inner}>
            <label className={styles.title}>
              Enter Email To Reset Password
            </label>
            <div style={{ width: "100%" }}>
              <TextInput
                hasLabel
                label={"Email"}
                placeholder={"Enter Email"}
                onChange={handleEmailInput}
                outline={
                  client?.email_address?.length > 2 &&
                  !validateEmail(client?.email_address)
                }
              />
            </div>
          </div>
        </Form>
      </Base>
    </>
  );
};

export default React.memo(VerifyEmail);
