import React, { useState } from "react";
import styles from "./DynamicSearch.module.scss";
import Card from "../Card/Card";
import Icon from "../Icons/Icon";

const DynamicSearch = ({
  setSelectedItem,
  setDropdown,
  placeholder,
  hasIcon,
  iconColor,
  dropdown,
  onInput,
  height,
  menuOptions,
  handleDynamicSelection,
}) => {
  const [filteredList, setFilteredList] = useState(menuOptions);
  const [searchTerm, setSearchTerm] = useState("");
  const [emptyState, setEmptyState] = useState("empty");

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setEmptyState(searchTerm === "" ? "empty" : "");
    const filteredSearchList = menuOptions?.filter(({ label }) =>
      label?.toLowerCase().includes(searchTerm)
    );
    setFilteredList(filteredSearchList);
  };

  const handleMenuClick = (item) => {
    handleDynamicSelection(item);
    setSearchTerm("");
    setDropdown(false);
  };

  return (
    <>
      <div
        className={styles.backdrop}
        onClick={() => setDropdown(!dropdown)}
      ></div>
      <Card className={`${styles.Search} ${styles[emptyState]}`}>
        <div
          className={styles.inner}
          style={{ minHeight: height, height: height }}
        >
          <div className={styles.fixed}>
            <div>
              <Icon icon="search" color="shade-100" />
              <input
                type="search"
                value={searchTerm}
                onChange={handleSearch}
                onInput={onInput}
                placeholder={placeholder || "Search here"}
                autoFocus
              />
            </div>
          </div>
          {dropdown ? (
            <ul>
              {filteredList?.map((item, i) => (
                <li onClick={() => handleMenuClick(item)} key={i}>
                  <div className={styles.details}>
                    {hasIcon ? (
                      <Icon icon={item?.icon} color={iconColor} />
                    ) : (
                      ""
                    )}

                    <p>{item.label}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
      </Card>
    </>
  );
};

DynamicSearch.defaultProps = {
  hasIcon: false,
};

export default React.memo(DynamicSearch);
