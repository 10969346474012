import React from "react";
import ReactDOM from "react-dom";
import styles from "./Dropdown.module.scss";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router";
import Icon from "../Icons/Icon";

const ListItemActions = ({ setDropdown, handleSelection }) => {
  const handleListClick = (e) => {
    const selectedValue = e.currentTarget.innerText;
    handleSelection(selectedValue);
    setDropdown(false);
  };

  return (
    <>
      <div className={styles.backdrop} onClick={() => setDropdown(false)}></div>
      <ul className={`${styles.dropdown} ${styles["list-item-actions"]}`}>
        <li onClick={(e) => handleListClick(e)}>
          <Icon icon="pencil" color="shade-100" />
          Edit
        </li>
        <li onClick={(e) => handleListClick(e)}>
          <Icon icon="download" color="shade-100" />
          Download
        </li>
      </ul>
    </>
  );
};

const TaskPriorities = ({ setDropdown, handleSelection }) => {
  // const handleListClick = (e) => {
  //   const selectedValue = e.currentTarget.innerText;
  //   handleSelection(selectedValue);
  //   setDropdown(false);
  // };
  // return (
  //   <>
  //     <ul className={`${styles.dropdown} ${styles["task-priorities"]}`}>
  //       <li onClick={(e) => handleListClick(e)}>
  //         <TaskPriority priority="high" />
  //       </li>
  //       <li onClick={(e) => handleListClick(e)}>
  //         <TaskPriority priority="medium" />
  //       </li>
  //       <li onClick={(e) => handleListClick(e)}>
  //         <TaskPriority priority="low" />
  //       </li>
  //     </ul>
  //   </>
  // );
};

const Projects = ({ setDropdown, options, handleSelection }) => {
  const handleListClick = (item) => {
    const selectedValue = item.value;
    handleSelection(selectedValue);
    setDropdown(false);
  };

  return (
    <>
      <div
        className={`${styles.backdrop} ${styles.overlay}`}
        onClick={() => setDropdown(false)}
      ></div>
      <ul className={`${styles.dropdown} ${styles.projects}`}>
        {options.map((item, index) => (
          <li key={index} onClick={() => handleListClick(item)}>
            <span>
              <Icon icon={item.icon} />
              {item.label}
            </span>
            {item.counter ? <p>{`${item.counter}+`}</p> : ""}
          </li>
        ))}
      </ul>
    </>
  );
};

const Accounts = ({ setDropdown, options, handleSelection }) => {
  const handleListClick = (item) => {
    handleSelection(item);
    setDropdown(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <div
        className={`${styles.backdrop} ${styles.overlay}`}
        onClick={() => setDropdown(false)}
      ></div>
      <ul className={`${styles.dropdown} ${styles.accounts}`}>
        {options.map((item, index) => (
          <li key={index} onClick={() => handleListClick(item)}>
            <span>
              <img
                className={styles["company-logo"]}
                src={item.icon}
                alt="company-logo"
              />
              {item.label}
            </span>
            {item.counter ? <p>{`${item.counter}+`}</p> : ""}
          </li>
        ))}
        <button onClick={() => navigate(clientPages.createCompany)}>
          <Icon icon="plus" color="shade-100" />
          Create a Business
        </button>
      </ul>
    </>
  );
};

const TaskStatus = ({ setDropdown, handleSelection, size }) => {
  const handleListClick = (e) => {
    const selectedValue = e.currentTarget.innerText;
    handleSelection(selectedValue);
    setDropdown(false);
  };

  return (
    <>
      <ul
        className={`${styles.dropdown} ${styles["task-status"]}`}
        style={{ width: size }}
      >
        <p>Change Status</p>
        <li
          className={styles["list-item1"]}
          onClick={(e) => handleListClick(e)}
        >
          TODO
        </li>
        <li
          className={styles["list-item2"]}
          onClick={(e) => handleListClick(e)}
        >
          IN PROGRESS
        </li>
        <li
          className={styles["list-item3"]}
          onClick={(e) => handleListClick(e)}
        >
          READY FOR QA
        </li>
        <li
          className={styles["list-item4"]}
          onClick={(e) => handleListClick(e)}
        >
          DONE
        </li>
      </ul>
    </>
  );
};

const Regular = ({
  setDropdown,
  options,
  handleSelection,
  size,
  top,
  left,
  right,
  bottom,
  portal,
  maxHeight,
}) => {
  const handleListClick = (item) => {
    const selectedValue = item;
    handleSelection(selectedValue);
    setDropdown(false);
  };

  if (portal) {
    return ReactDOM.createPortal(
      <>
        <div
          className={`${styles.backdrop}`}
          onClick={() => setDropdown(false)}
        ></div>
        <ul
          className={`${styles.dropdown} ${styles.regular}`}
          style={{
            width: size,
            top: top,
            left: left,
            right: right,
            bottom: bottom,
            position: portal && "fixed",
            maxHeight: maxHeight,
          }}
        >
          {options.map((item, index) => (
            <li key={index} onClick={() => handleListClick(item)}>
              <span>
                {item.icon ? (
                  <Icon icon={item.icon} color={item.iconColor} />
                ) : (
                  ""
                )}
                {item.avatar ? (
                  <img
                    src={item.avatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                ) : (
                  ""
                )}
                {item.label}
              </span>
              {item.counter ? <p>{`${item.counter}+`}</p> : ""}
            </li>
          ))}
        </ul>
      </>,
      document.getElementById("portal")
    );
  } else {
    return (
      <>
        <div
          className={`${styles.backdrop}`}
          onClick={() => setDropdown(false)}
        ></div>
        <ul
          className={`${styles.dropdown} ${styles.regular}`}
          style={{
            width: size,
            top: top,
            left: left,
            right: right,
            bottom: bottom,
          }}
        >
          {options.map((item, index) => (
            <li key={index} onClick={() => handleListClick(item)}>
              <span>
                {item.icon ? (
                  <Icon icon={item.icon} color={item.iconColor} />
                ) : (
                  ""
                )}
                {item.avatar ? (
                  <img
                    src={item.avatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                ) : (
                  ""
                )}
                {item.label}
              </span>
              {item.counter ? <p>{`${item.counter}+`}</p> : ""}
            </li>
          ))}
        </ul>
      </>
    );
  }
};

const Dropdown = ({
  type,
  setDropdown,
  options,
  handleSelection,
  size,
  top,
  bottom,
  left,
  right,
  portal,
  maxHeight,
}) => {
  let DropdownComponent;

  switch (type) {
    case "list-item-actions":
      DropdownComponent = ListItemActions;
      break;
    case "projects":
      DropdownComponent = Projects;
      break;
    case "accounts":
      DropdownComponent = Accounts;
      break;
    case "task-status":
      DropdownComponent = TaskStatus;
      break;
    case "regular":
      DropdownComponent = Regular;
      break;
    case "task-priorities":
      DropdownComponent = TaskPriorities;
      break;
    default:
      DropdownComponent = Regular;
  }

  return (
    <DropdownComponent
      setDropdown={setDropdown}
      options={options}
      handleSelection={handleSelection}
      size={size}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      portal={portal}
      maxHeight={maxHeight}
    />
  );
};

export default Dropdown;
