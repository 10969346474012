import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const buyerApi = createApi({
  reducerPath: "buyerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      let token = localStorage.getItem("authToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    headers: {
      "Content-Type": "application/json",
    },
  }),
  tagTypes: ["Cart"],
  endpoints: (builder) => ({
    getShopProfile: builder.query({
      query: (shop) => ({
        url: `shops/${shop.shop_slug}`,
        method: "GET",
      }),
    }),
    getAllProducts: builder.query({
      query: (shop) => ({
        url: `shops/${shop.shop_slug}/products`,
        method: "GET",
      }),
    }),
    getCart: builder.query({
      query: (cart) => ({
        url: `shops/${cart.shop_slug}/cart/${cart.cart_id}`,
        method: "GET",
      }),
      providesTags: ["Cart"],
    }),
    addToCart: builder.mutation({
      query: (order_item) => ({
        url: `shops/${order_item.shop_slug}/cart/add`,
        method: "POST",
        body: {
          order_item: {
            product_id: order_item.product_id,
            quantity: order_item.quantity,
          },
        },
      }),
      invalidatesTags: ["Cart"],
    }),
    addToExistingCart: builder.mutation({
      query: (order_item) => ({
        url: `shops/${order_item.shop_slug}/cart/${order_item.cart_id}/add`,
        method: "POST",
        body: {
          order_item: {
            product_id: order_item.product_id,
            quantity: order_item.quantity,
          },
        },
      }),
      invalidatesTags: ["Cart"],
    }),
    updateItemOnCart: builder.mutation({
      query: (order_item) => ({
        url: `shops/${order_item.shop_slug}/cart/${order_item.cart_id}/${order_item.item_id}`,
        method: "PUT",
        body: {
          order_item: {
            quantity: order_item.quantity,
          },
        },
      }),
      invalidatesTags: ["Cart"],
    }),
    removeItemFromCart: builder.mutation({
      query: (order_item) => ({
        url: `shops/${order_item.shop_slug}/cart/${order_item.cart_id}/${order_item.item_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cart"],
    }),
    updateRecipientDetails: builder.query({
      query: (params) => ({
        url: `shops/${params.shop_slug}/cart/${params.cart_id}/recipient`,
        method: "POST",
        body: {
          recipient: {
            first_name: params.recipient.first_name,
            last_name: params.recipient.last_name,
            phone: params.recipient.phone,
            email: params.recipient.email,
          },
          address: {
            country: params.address.country,
            state: params.address.state,
            city: params.address.city,
            post_code: params.address.post_code,
            street: params.address.street,
            house_number: params.address.house_number,
          },
        },
      }),
    }),
    initiatePayment: builder.query({
      query: (params) => ({
        url: `shops/${params.shop_slug}/cart/${params.cart_id}/pay/${params.provider}`,
        method: "POST",
        body: {},
      }),
    }),
    verifyPayment: builder.query({
      query: (params) => ({
        url: `shops/${params.shop_slug}/cart/${params.cart_id}/verify`,
        method: "GET",
      }),
    }),
    getCountriesList: builder.query({
      query: (shop_slug) => ({
        url: `shops/${shop_slug}/locations/countries`,
        method: "GET",
      }),
    }),
    getStatesList: builder.query({
      query: (params) => ({
        url: `shops/${params.shop_slug}/locations/${params.country_alpha2}/states`,
        method: "GET",
      }),
    }),
    getCitiesList: builder.query({
      query: (params) => ({
        url: `shops/${params.shop_slug}/locations/${params.country_alpha2}/${params.state_code}/cities`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetShopProfileQuery,
  useGetAllProductsQuery,
  useAddToCartMutation,
  useAddToExistingCartMutation,
  useUpdateItemOnCartMutation,
  useRemoveItemFromCartMutation,
  useGetCartQuery,
  useLazyInitiatePaymentQuery,
  useLazyUpdateRecipientDetailsQuery,
  useLazyVerifyPaymentQuery,
  useGetCountriesListQuery,
  useGetStatesListQuery,
  useGetCitiesListQuery,
  useVerifyPaymentQuery,
} = buyerApi;
