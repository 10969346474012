import React, { useEffect, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import { Icon, Loader, Toast } from "../../../Components/Atoms";
import styles from "./_Main.module.scss";
import OrdersCardMini from "../../../Components/Cards/Orders/OrdersCardMini/OrdersCardMini";
import { PENDING_TASKS } from "../../../Components/Cards/Product/helpers";
import { useGetAllOrdersQuery } from "../../../redux/services/client/accounts";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router";

const OwnerSales = () => {
  const { data, isSuccess, isError, isLoading, error } = useGetAllOrdersQuery();
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const navigate = useNavigate();

  useEffect(() => {
    if (isError && error?.status === 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      navigate(clientPages.login);
    }

    if (isError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isError, error?.data, error?.status]);

  const renderOrders = () => {
    if (isLoading) {
      return <Loader size={70} />;
    }

    if (isSuccess && data) {
      return data?.data?.map((order) => (
        <OrdersCardMini
          key={order.id}
          buyerName={
            order.recipient.first_name + " " + order.recipient.last_name
          }
          orderID={order.reference}
          avatar={order.order_items.map((item) => item.product.image.url)}
          totalPrice={order.payment.total}
          subTotal={order.sub_total}
          total={order.payment.cost_total}
          tax={order.vat_charge}
          charge={order.delivery_charge}
          orders={order.order_items.map((item) => ({
            productTitle: item.product.title,
            productDescription: item.product.description,
            numberOfItems: item.quantity,
            price: item.subtotal,
            productImage: item.product.image.url,
            recipientName:
              order.recipient.first_name + " " + order.recipient.last_name,
            recipientStreet: order.address.street,
            recipientCountry: order.address.country,
            recipientState: order.address.state,
            recipientCity: order.address.city,
            recipientPostCode: order.address.post_code,
          }))}
        />
      ));
    }
  };

  return (
    <section className={styles.sales} id="salesPage">
      {isError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"salesPage"}
        />
      ) : (
        ""
      )}
      <Form headerTitle={"My Sales"} hasTitle={false} position={"relative"}>
        <div className={styles.search}>
          <Icon icon={"search"} color={"shade-100"} />
          <input
            className={styles.input}
            placeholder="Search order id or customer name"
          />
        </div>
        {renderOrders()}
        <p className={styles.end}>end of records</p>
      </Form>
    </section>
  );
};

export default OwnerSales;
