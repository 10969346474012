import React, { useState } from "react";
import styles from "./OrdersCardDetails.module.scss";
import { BlurLoader } from "../../../Atoms";

const OrdersCardDetails = ({
  image,
  productTitle,
  productDescription,
  numberOfItems,
  totalPrice,
}) => {
  return (
    <div className={styles.card}>
      <figure className={styles.image}>
        <img src={image} alt="product image" loading="lazy" />
      </figure>
      <div className={styles.right}>
        <h6 className={styles.title}>{productTitle}</h6>
        <p className={styles.desc}>{productDescription}</p>
        <div className={styles.bottom}>
          {`${numberOfItems}${numberOfItems < 2 ? " item" : "x items"}`}
          <p className={styles.price}>
            NGN <span className={styles.bold}>{totalPrice}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrdersCardDetails;
