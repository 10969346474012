import React, { useState } from "react";
import Icon from "../../../Icons/Icon";
import styles from "./Phone.module.scss";
import Card from "../../../Card/Card";
import CountryFlag from "../../../CountryFlag/CountryFlag";
import Search from "../Search/Search";

const Phone = ({
  name,
  hasLabel,
  placeholder,
  label,
  onChange,
  value,
  hasIcon,
  icon,
  iconColor,
  disabled,
  maxLength,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    country_code: "+234",
    slug: "nigeria",
  });

  return (
    <div
      className={styles["phone-input-container"]}
      style={{ cursor: disabled ? "not-allowed" : "" }}
    >
      {hasLabel ? <label>{label}</label> : ""}
      <Card
        className={styles.phone}
        style={{
          marginTop: hasLabel ? "10px" : "",
        }}
      >
        <div className={styles.left}>
          <div
            className={styles["phone__countryCode"]}
            onClick={() => setDropdown(!dropdown)}
            style={{ cursor: disabled ? "not-allowed" : "" }}
          >
            <div className={styles["phone__countryCode-characters"]}>
              <span>
                <CountryFlag
                  country={selectedCountry.slug}
                  className={styles["phone__countryFlag"]}
                />
              </span>
              <span className={styles.box}>{selectedCountry.country_code}</span>
            </div>
            <Icon icon="chevronDown" color="shade-0" />
          </div>
          <input
            type="number"
            name={name}
            placeholder={placeholder || "810 555 5555"}
            onChange={onChange}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            style={{
              cursor: disabled ? "not-allowed" : "",
              color: disabled ? "var(--shade-100)" : "",
            }}
          />
        </div>
        {hasIcon ? <Icon icon={icon} color={iconColor} width={12.5} /> : ""}
        {dropdown ? (
          <Search
            dropdown={dropdown}
            setSelectedCountry={setSelectedCountry}
            setDropdown={setDropdown}
          />
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

Phone.defaultProps = {
  hasLabel: true,
  hasIcon: false,
  iconColor: "shade-0",
};

export default Phone;
