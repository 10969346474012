import { createSlice } from "@reduxjs/toolkit";
const sampleClient = {
  value: {
    shopSlug: "",
    cartId: "",
    cartIsUpdated: false,
    itemId: "",
    orderItems: null,
  },
};

export const buyerSlice = createSlice({
  name: "buyer",
  initialState: sampleClient,
  reducers: {
    updateBuyerData: (state, action) => {
      let keys = Object.keys(action.payload);
      keys.map((key) => {
        state.value[key] = action.payload[key];
        return true;
      });
    },
  },
});

export const { updateBuyerData } = buyerSlice.actions;

export default buyerSlice.reducer;
