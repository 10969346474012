import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateClientData } from "../../../../redux/features/newClient";
import {
  useGetCitiesListQuery,
  useGetCountriesListQuery,
  useGetStatesListQuery,
  useLazySubmitCompanyDataQuery,
  useSubmitCompanyDataQuery,
} from "../../../../redux/services/client/onboarding";

export function useStepFiveLogic(
  setNextStep,
  legalBusinessNameRef,
  registrationNumberRef,
  taxNumberRef,
  postCodeRef,
  aptStreetRef
) {
  const [checked, setChecked] = useState(false);
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [checkEmptyAddress, setCheckEmptyAddress] = useState(false);
  const [errorName, setErrorName] = useState("");

  const [selectedCountry, setSelectedCountry] = useState({
    countryName: null,
    postalCode: null,
    alpha2: null,
  });

  const [selectedState, setSelectedState] = useState({
    stateName: null,
    stateCode: null,
  });

  const [selectedCity, setSelectedCity] = useState({
    cityName: "",
  });

  const client = useSelector((state) => state.client.value);
  const dispatch = useDispatch();

  const { countryName, alpha2, postalCode } = selectedCountry;
  const { stateName, stateCode } = selectedState;
  const { cityName } = selectedCity;

  const stateDropdownFormatter = (arr) => {
    return arr.map((item) => ({
      name: item.name,
      alpha2: item.code,
    }));
  };

  const {
    data: countriesData,
    isSuccess: countriesFetchSuccess,
    isError: countriesFetchError,
    refetch,
  } = useGetCountriesListQuery();

  useEffect(() => {
    if (countriesFetchSuccess) {
      dispatch(
        updateClientData({
          countries: countriesData.data.countries,
        })
      );
    }
    // else if (countriesFetchError) {
    //   setInterval(() => {
    //     refetch();
    //   }, 3000);
    // }
    return () => {};
  }, [countriesFetchSuccess]);

  const { data: statesData, isSuccess: statesQuerySuccess } =
    useGetStatesListQuery(
      {
        country_alpha2: alpha2,
      },
      { skip: !alpha2 }
    );

  useEffect(() => {
    if (statesQuerySuccess) {
      dispatch(
        updateClientData({
          states: stateDropdownFormatter(statesData.data.states),
        })
      );
    }
    return () => {};
  }, [statesQuerySuccess, statesData]);

  const { data: citiesData, isSuccess: citiesQuerySuccess } =
    useGetCitiesListQuery(
      {
        country_alpha2: alpha2,
        state_code: stateCode,
      },
      { skip: !stateCode }
    );

  useEffect(() => {
    if (citiesQuerySuccess) {
      dispatch(
        updateClientData({
          cities: citiesData.data.cities,
        })
      );
    }
    return () => {};
  }, [citiesQuerySuccess, citiesData]);

  const [submitCompanyData, { data, isSuccess, isFetching, isError, error }] =
    useLazySubmitCompanyDataQuery();

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        setNextStep(5);
      }
    }
  }, [isSuccess, data, setNextStep]);

  const handleFormSubmit = async () => {
    if (checked) {
      const inputRefs = [legalBusinessNameRef, aptStreetRef];
      const otherComponents = [countryName, cityName, stateName];
      const componentNames = ["Country Name", "City Name", "State Name"];

      let name;

      const firstEmptyInputRef = inputRefs.find(
        (inputRef) => inputRef.current?.getValue().trim() === ""
      );

      const firstEmptyComponent = otherComponents?.find(
        (location) => location === null || location === ""
      );

      const index = otherComponents?.indexOf(firstEmptyComponent);

      name = componentNames[index];

      setErrorName(name);

      if (firstEmptyInputRef) {
        setCheckEmpty(true);
        firstEmptyInputRef.current?.focus();
      } else if (firstEmptyComponent !== undefined) {
        setCheckEmptyAddress(true);
        return;
      } else {
        await submitCompanyData({
          registration_number:
            registrationNumberRef?.current?.getValue().trim() || "",
          legal_name: legalBusinessNameRef?.current?.getValue().trim() || "",
          tax_number: taxNumberRef?.current?.getValue().trim() || "",
          address_attributes: {
            street: aptStreetRef?.current?.getValue().trim() || "",
            post_code: postCodeRef?.current?.getValue().trim() || "",
            city: cityName || "",
            state: stateName || "",
            country: countryName || "",
          },
        });
      }
    }
  };

  const handleInput = () => {
    if (checkEmpty) {
      setCheckEmpty(false);
    }
  };

  const handleCountrySelection = ({ alpha2, postal_code, name }) => {
    setSelectedCountry({
      countryName: name,
      alpha2: alpha2,
      postalCode: postal_code,
    });
  };

  const handleStateSelection = ({ alpha2, name }) => {
    setSelectedState({
      stateName: name,
      stateCode: alpha2,
    });
  };

  const handleCitySelection = ({ name }) => {
    setSelectedCity({
      cityName: name,
    });
  };

  useEffect(() => {
    if (checkEmptyAddress) {
      setCheckEmptyAddress(false);
    }

    return () => {};
  }, [countryName, cityName, stateName]);

  return {
    data,
    isSuccess,
    isError,
    error,
    checked,
    setChecked,
    handleInput,
    checkEmpty,
    postalCode,
    citiesQuerySuccess,
    client,
    handleCitySelection,
    statesQuerySuccess,
    handleStateSelection,
    countriesFetchSuccess,
    handleCountrySelection,
    handleFormSubmit,
    isFetching,
    checkEmptyAddress,
    errorName,
  };
}
