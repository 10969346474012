import React, { useEffect, useRef, useState } from "react";
import Form from "./Form";
import Base from "../Base/Base";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import styles from "./Form.module.scss";
import { CountrySelect, Icon, Toast } from "../../../Components/Atoms";
import { useStepFiveLogic } from "./Helpers/stepFive.helper";

const StepFive = ({ setNextStep }) => {
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const legalBusinessNameRef = useRef();
  const registrationNumberRef = useRef();
  const taxNumberRef = useRef();
  const postCodeRef = useRef();
  const aptStreetRef = useRef();

  const {
    data,
    isSuccess,
    isError,
    error,
    checked,
    setChecked,
    handleInput,
    checkEmpty,
    postalCode,
    citiesQuerySuccess,
    client,
    handleCitySelection,
    statesQuerySuccess,
    handleStateSelection,
    countriesFetchSuccess,
    handleCountrySelection,
    handleFormSubmit,
    isFetching,
    checkEmptyAddress,
    errorName,
  } = useStepFiveLogic(
    setNextStep,
    legalBusinessNameRef,
    registrationNumberRef,
    taxNumberRef,
    postCodeRef,
    aptStreetRef
  );

  useEffect(() => {
    if (isError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }
    return () => {};
  }, [isError, error?.data]);

  useEffect(() => {
    if (checkEmptyAddress) {
      setToastMessage({
        status: "error",
        heading: "Error",
        message: `${errorName} should not be empty`,
      });
    }
    return () => {};
  }, [errorName, checkEmptyAddress]);

  return (
    <>
      {isError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      {checkEmptyAddress ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <Base
        classes={`${styles["steps-form"]} ${styles["organization-form"]}`}
        onBackToLastStep={() => setNextStep(3)}
      >
        <div className={styles.step}>
          <span>
            <strong>Step 5</strong>
          </span>
          <span>/ 6</span>
        </div>
        <Form
          specialClass="organization"
          buttonLabel={isFetching ? "" : "Continue"}
          setNextStep={setNextStep}
          handleButtonClick={handleFormSubmit}
          icon={isFetching && "spinner-alt"}
          color={checked ? "completed" : "disabled"}
        >
          <div className={styles.inner}>
            <label className={styles.title}>Organization Information</label>
            <span className={styles.info}>
              This will be your trading name showed to customers that visit your
              shop
            </span>
            <TextInput
              ref={legalBusinessNameRef}
              hasLabel={true}
              label={<p className="required-label">Legal Business Name</p>}
              placeholder="Your business name"
              outline={
                checkEmpty &&
                legalBusinessNameRef.current.getValue().trim().length === 0
              }
              onInput={handleInput}
            />
            <TextInput
              ref={registrationNumberRef}
              hasLabel={true}
              label={"Registration Number (optional)"}
              placeholder="RC or BN Number"
              onInput={handleInput}
            />
            <TextInput
              ref={taxNumberRef}
              hasLabel={true}
              label={"Tax Number (optional)"}
              placeholder="TIN - 000000000"
              onInput={handleInput}
            />
            <label className={styles.title}>Address</label>
            <div className={styles.group}>
              <CountrySelect
                placeholder="Select Country"
                selectedOption={handleCountrySelection}
                label={<p className="required-label">Country</p>}
                countryList={client.countries}
                searchPlaceholder="Search Country"
                enabled={countriesFetchSuccess ? true : false}
              />

              <CountrySelect
                placeholder="Select State"
                selectedOption={handleStateSelection}
                label={<p className="required-label">State</p>}
                countryList={client.states}
                enabled={statesQuerySuccess ? true : false}
                hasCountryCode={false}
                searchPlaceholder="Search State"
                type={"state"}
              />
            </div>
            <div className={styles.group}>
              <CountrySelect
                placeholder="Select City"
                selectedOption={handleCitySelection}
                label={<p className="required-label">Country/City</p>}
                countryList={client.cities}
                enabled={citiesQuerySuccess ? true : false}
                hasCountryCode={false}
                searchPlaceholder="Search City"
                type={"city"}
              />

              {postalCode ? (
                <TextInput
                  ref={postCodeRef}
                  hasLabel={true}
                  label="Post Code (optional)"
                  placeholder="e.g. 000000"
                  onInput={handleInput}
                />
              ) : (
                ""
              )}
            </div>
            <TextInput
              ref={aptStreetRef}
              hasLabel={true}
              label={<p className="required-label">Apt. + Street</p>}
              placeholder="e.g No. 15, Ademola Adetokunbo Road"
              outline={
                checkEmpty &&
                aptStreetRef.current.getValue().trim().length === 0
              }
              onInput={handleInput}
            />
          </div>
          <div
            className={styles["checkbox-container"]}
            onClick={() => setChecked(!checked)}
          >
            <span
              onClick={() => setChecked(!checked)}
              className={`${styles.icon} ${checked ? styles.checked : ""}`}
            >
              {checked ? (
                <Icon icon="check" color="success" width={8} height={8} />
              ) : (
                ""
              )}
            </span>
            <p className={checked ? styles.active : ""}>
              I hereby confirm that the information I have provided so far is,
              to the best of my knowledge, complete & accurate.
            </p>
          </div>
        </Form>
      </Base>
    </>
  );
};

export default React.memo(StepFive);
