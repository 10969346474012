import React from "react";
import { Outlet } from "react-router-dom";
import { DashboardBase } from "../../Components/Components";
import PageMetaData from "../Buyers/PageMetaData";

const CompanyDashboard = () => {
  return (
    <DashboardBase>
      <PageMetaData />
      <Outlet />
    </DashboardBase>
  );
};

export default CompanyDashboard;
