import React, { useState, useImperativeHandle, useRef, useEffect } from "react";
import styles from "./Value.module.scss";

const Value = React.forwardRef(
  (
    {
      hasLabel,
      label,
      quantifier,
      outline,
      value,
      onChange,
      type,
      placeholder,
    },
    ref
  ) => {
    const [isActive, setisActive] = useState(false);
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return inputRef?.current?.value;
      },
      focus: () => {
        inputRef?.current?.focus();
      },
    }));

    const setActive = () => {
      setisActive(true);
    };

    const handleBlur = () => {
      setisActive(false);
      const formattedValue = inputRef?.current?.value.replace(/[^0-9.]/g, "");
      inputRef.current.value = formattedValue;
    };

    useEffect(() => {
      if (isActive) {
        inputRef?.current?.focus();
      }
    }, [isActive]);

    return (
      <div>
        {hasLabel ? <label htmlFor="text-input">{label}</label> : ""}
        <div
          className={`${styles["value-container"]} ${
            isActive ? styles.active : ""
          } ${outline ? styles.error : ""}`}
          style={{
            marginTop: hasLabel ? "10px" : "",
          }}
        >
          <div className={styles.left}>
            <span>{quantifier || "hello"}</span>
            <input
              className={styles["dynamic-text"]}
              onChange={onChange}
              ref={inputRef}
              value={value}
              onFocus={setActive}
              onBlur={handleBlur}
              type={type}
              placeholder={placeholder || "0.00"}
              inputMode={type === "number" && "numeric"}
              min={type === "number" && "0"}
              pattern={type === "number" && "[0-9]*"}
              title={type === "number" && "Non-negative integral number"}
            />
          </div>
        </div>
      </div>
    );
  }
);

Value.defaultProps = {
  hasLabel: true,
  outline: false,
  type: "number",
};

export default Value;
