import React from "react";
import Logo from "../../../Components/Atoms/Logo/Logo";
import styles from "./Base.module.scss";
import { Icon } from "../../../Components/Atoms";

const Base = ({
  children,
  classes,
  onBackToLastStep,
  stepsPage,
  width,
  displayLogo,
}) => {
  return (
    <div className={`${styles.main} ${classes}`} id="basePage">
      {stepsPage ? (
        <button
          type="button"
          className={styles.button}
          onClick={onBackToLastStep}
        >
          <Icon icon="arrow-left" color="shade-100" />
        </button>
      ) : (
        ""
      )}

      <div style={{ width: width || 411 }} className={styles.container}>
        {displayLogo ? <Logo /> : ""}
        {children}
      </div>
    </div>
  );
};

Base.defaultProps = {
  stepsPage: true,
  displayLogo: true,
};

export default Base;
