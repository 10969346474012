import React, { useRef } from "react";
import Form from "./Form";
import Base from "../Base/Base";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import styles from "./Form.module.scss";
import { Link } from "react-router-dom";
import { clientPages } from "../../../Routes/routes";
import { useSelector } from "react-redux";
import { useStepTwoLogic } from "./Helpers/stepTwo.helpers";

const StepTwo = ({ setNextStep }) => {
  const {
    isLoading,
    emailUsabilityLoading,
    handleEmailInput,
    handleFormSubmit,
    handleKeyPress,
  } = useStepTwoLogic(setNextStep);

  const client = useSelector((state) => state.client.value);

  const icon = client.alreadyExists
    ? "cross-small"
    : emailUsabilityLoading
    ? "spinner-alt"
    : client.emailIsUsable
    ? "check"
    : "";

  return (
    <Base
      classes={styles["steps-form"]}
      onBackToLastStep={() => setNextStep(0)}
    >
      <div className={styles.step}>
        <span>
          <strong>Step 2 </strong>
        </span>
        <span>/ 6</span>
      </div>
      <Form
        buttonLabel={isLoading && client.emailIsUsable ? "" : "Continue"}
        color={client.emailIsUsable || isLoading ? "completed" : "disabled"}
        handleButtonClick={handleFormSubmit}
        onKeyPress={handleKeyPress}
        icon={isLoading && client.emailIsUsable ? "spinner-alt" : ""}
      >
        <div className={styles.inner}>
          <label className={styles.title}>Corporate Email</label>
          <TextInput
            placeholder="e.g. ceo@company.com"
            onChange={handleEmailInput}
            name="email"
            hasIcon={
              client.emailIsUsable ||
              emailUsabilityLoading ||
              client.alreadyExists
            }
            icon={icon}
            iconColor={client.alreadyExists ? "error" : "success"}
            iconPosition="right"
            outline={client.alreadyExists}
            autofocus
          />
          {client.alreadyExists ? (
            <span className={styles["info"]}>
              Looks like you already have an account with us,{" "}
              <Link to={clientPages.login}>Login</Link> to get back into your
              account
            </span>
          ) : (
            ""
          )}
        </div>
      </Form>
    </Base>
  );
};

export default React.memo(StepTwo);
