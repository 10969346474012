import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const onboardingApi = createApi({
  reducerPath: "onboardingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      let token = localStorage.getItem("authToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    headers: {
      "Content-Type": "application/json",
    },
  }),
  endpoints: (builder) => ({
    start: builder.query({
      query: (client) => ({
        url: "clients/onboarding/start",
        method: "POST",
        body: { client: client },
      }),
    }),
    emailUsability: builder.query({
      query: (client) => ({
        url: "clients/onboarding/email/validate",
        method: "POST",
        body: { client: client },
      }),
    }),
    verifyEmail: builder.query({
      query: (client) => ({
        url: "clients/onboarding/email/verify",
        method: "POST",
        body: { client: client },
      }),
    }),
    setPassword: builder.query({
      query: (client) => ({
        url: "clients/onboarding/password/set",
        method: "POST",
        body: { client: client },
      }),
    }),
    submitCompanyData: builder.query({
      query: (business) => ({
        url: "clients/onboarding/business",
        method: "POST",
        body: { business: business },
      }),
    }),
    validateInvitation: builder.query({
      query: (inviteData) => ({
        url: `clients/auth/${inviteData.companySlug}/invitations/validate`,
        method: "POST",
        body: { invite: inviteData },
      }),
    }),
    getCountriesList: builder.query({
      query: () => ({
        url: "clients/locations/countries",
        method: "GET",
      }),
    }),
    getStatesList: builder.query({
      query: (countryAlpha) => ({
        url: `clients/locations/${countryAlpha.country_alpha2}/states`,
        method: "GET",
      }),
    }),
    getCitiesList: builder.query({
      query: (params) => ({
        url: `clients/locations/${params.country_alpha2}/${params.state_code}/cities`,
        method: "GET",
      }),
    }),
    requestPasswordReset: builder.query({
      query: (client) => ({
        url: "clients/auth/password/reset",
        method: "POST",
        body: { client: client },
      }),
    }),
    resetPassword: builder.query({
      query: (client) => ({
        url: `clients/auth/password/reset?${client.authToken}`,
        method: "PUT",
        body: { client: client },
      }),
    }),
  }),
});

export const {
  useLazyStartQuery,
  useStartQuery,
  useLazyEmailUsabilityQuery,
  useEmailUsabilityQuery,
  useVerifyEmailQuery,
  useSetPasswordQuery,
  useSubmitCompanyDataQuery,
  useLazySubmitCompanyDataQuery,
  useValidateInvitationQuery,
  useGetCountriesListQuery,
  useGetStatesListQuery,
  useGetCitiesListQuery,
  useLazyRequestPasswordResetQuery,
  useLazyResetPasswordQuery,
} = onboardingApi;
