import React, { useEffect, useState } from "react";
import Base from "../Base/Base";
import { Icon, ProgressBar } from "../../../Components/Atoms";
import styles from "./Form.module.scss";
import { useNavigate } from "react-router";
import { clientPages } from "../../../Routes/routes";

const LastStep = () => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  localStorage.removeItem("AwashopCompanyStatus");

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        if (newProgress >= 90) {
          clearInterval(interval);
          navigate(clientPages.dashboard);
        }
        return newProgress;
      });
    }, 600);
    return () => {
      clearInterval(interval);
    };
  }, [navigate]);

  return (
    <Base stepsPage={false} displayLogo={false}>
      <div className={styles["last-step"]}>
        <Icon icon="modal-icon" width={100} height={100} />
        <span className={styles["middle-span"]}>
          <h3 className={styles.title}>All done!</h3>
          <p className={styles.info}>
            Your account setup is completed, we are setting up your dashboard
            and you will be redirected shortly
          </p>
        </span>
        <div className={styles.actions}>
          <ProgressBar progress={`${progress}%`} />
          <p className={styles.progress}>{progress}% complete</p>
        </div>
      </div>
    </Base>
  );
};

export default LastStep;
