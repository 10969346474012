import React from "react";
import Text from "./InputTypes/Text/Text";
import Select from "./InputTypes/Select/Select";
import Phone from "./InputTypes/Phone/Phone";
import PersonalizationOption from "./InputTypes/PersonalizationOption/PersonalizationOption";
import Date from "./InputTypes/Date/Date";
import TagInput from "./InputTypes/TagInput/TagInput";
import Link from "./InputTypes/Link/Link";
import Value from "./InputTypes/Value/Value";

const TextInput = React.forwardRef(
  (
    {
      hasLabel,
      hasIcon,
      placeholder,
      icon,
      iconColor,
      iconPosition,
      label,
      type,
      name,
      borderRadius,
      value,
      onChange,
      height,
      outline,
      onIconClick,
      id,
      onInput,
      onPaste,
      autoFocus,
      background,
      className,
      disabled,
      maxLength,
      width,
    },
    ref
  ) => {
    return (
      <Text
        hasLabel={hasLabel}
        hasIcon={hasIcon}
        placeholder={placeholder}
        icon={icon}
        iconColor={iconColor}
        iconPosition={iconPosition}
        label={label}
        type={type}
        name={name}
        borderRadius={borderRadius}
        value={value}
        onChange={onChange}
        height={height}
        outline={outline}
        onIconClick={onIconClick}
        id={id}
        ref={ref}
        onInput={onInput}
        onPaste={onPaste}
        autoFocus={autoFocus}
        background={background}
        className={className}
        disabled={disabled}
        maxLength={maxLength}
        width={width}
      />
    );
  }
);

const SelectInput = ({
  hasLabel,
  hasIcon,
  selected,
  icon,
  label,
  enabled,
  iconColor,
  canSelectMultiple,
  option,
  selectedOption,
  classes,
  placeholder,
  searchPlaceholder,
  selectMenuType,
  selectedDynamicOption,
  maxWidth,
  dropdownTop,
  hasListIcon,
}) => {
  return (
    <Select
      hasLabel={hasLabel}
      hasIcon={hasIcon}
      selected={selected}
      icon={icon}
      label={label}
      enabled={enabled}
      iconColor={iconColor}
      canSelectMultiple={canSelectMultiple}
      options={option}
      selectedOption={selectedOption}
      classes={classes}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      selectMenuType={selectMenuType}
      selectedDynamicOption={selectedDynamicOption}
      maxWidth={maxWidth}
      dropdownTop={dropdownTop}
      hasListIcon={hasListIcon}
    />
  );
};

const PhoneInput = ({
  name,
  label,
  hasLabel,
  placeholder,
  onChange,
  value,
  hasIcon,
  icon,
  iconColor,
  disabled,
  maxLength,
}) => {
  return (
    <Phone
      name={name}
      label={label}
      hasLabel={hasLabel}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      hasIcon={hasIcon}
      icon={icon}
      iconColor={iconColor}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};

const PersonalizationInput = ({
  option,
  hasIcon,
  name,
  background,
  bill,
  disabled,
  onChange,
  value,
}) => {
  return (
    <PersonalizationOption
      option={option}
      hasIcon={hasIcon}
      name={name}
      background={background}
      bill={bill}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  );
};
const DateInput = React.forwardRef(
  (
    { value, onChange, hasLabel, label, placeholder, iconColor, textColor },
    ref
  ) => {
    return (
      <Date
        value={value}
        onChange={onChange}
        hasLabel={hasLabel}
        label={label}
        ref={ref}
        placeholder={placeholder}
        iconColor={iconColor}
        textColor={textColor}
      />
    );
  }
);

const LinkInput = React.forwardRef(
  (
    {
      value,
      hasLabel,
      label,
      iconColor,
      url,
      outline,
      icon,
      onChange,
      activeLinks,
      disabled,
      onInput,
    },
    ref
  ) => {
    return (
      <Link
        value={value}
        ref={ref}
        hasLabel={hasLabel}
        label={label}
        icon={icon}
        iconColor={iconColor}
        url={url}
        outline={outline}
        onChange={onChange}
        activeLinks={activeLinks}
        disabled={disabled}
        onInput={onInput}
      />
    );
  }
);

const ValueInput = React.forwardRef(
  (
    {
      value,
      hasLabel,
      label,
      outline,
      quantifier,
      onChange,
      type,
      placeholder,
    },
    ref
  ) => {
    return (
      <Value
        value={value}
        ref={ref}
        hasLabel={hasLabel}
        label={label}
        quantifier={quantifier}
        outline={outline}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
      />
    );
  }
);

export {
  TextInput,
  SelectInput,
  PhoneInput,
  PersonalizationInput,
  DateInput,
  TagInput,
  LinkInput,
  ValueInput,
};
