import React, { useEffect, useReducer, useRef, useState } from "react";
import styles from "../Onboarding/Steps/Form.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon, Toast } from "../../Components/Atoms";
import Form from "../Onboarding/Steps/Form";
import { TextInput } from "../../Components/Atoms/Input/Input";
import Base from "../Onboarding/Base/Base";
import { useLazyResetPasswordQuery } from "../../redux/services/client/onboarding";
import { clientPages } from "../../Routes/routes";

export const initialState = {
  requirements: {
    length: false,
    containsNumber: false,
    containsLowercase: false,
    containsUppercase: false,
    containsSymbol: false,
  },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_REQUIREMENT":
      return {
        ...state,
        requirements: {
          ...state.requirements,
          [action.requirement]: !state.requirements[action.requirement],
        },
      };
    case "SET_REQUIREMENTS":
      return {
        ...state,
        requirements: action.requirements,
      };
    default:
      return state;
  }
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const passwordRef = useRef();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState({
    first: false,
    confirmFirst: false,
  });

  const handlePasswordInput = (e) => {
    if (isError) {
      setIsError(false);
    }
    const { value } = e.target;
    const password = value.trim();
    const requirements = {
      length: password.length >= 8,
      containsNumber: /\d/.test(password),
      containsLowercase: /[a-z]/.test(password),
      containsUppercase: /[A-Z]/.test(password),
      containsSymbol: /[-!@#$%^&*()_+]/.test(password),
    };

    dispatch({ type: "SET_REQUIREMENTS", requirements });
  };

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!@#$%^&*()_+])[a-zA-Z\d-!@#$%^&*()_+]{8,}$/;

    return passwordPattern.test(password);
  };

  const handleConfirmPassword = (e) => {
    const targetInput = e.target;
    setConfirmPassword(targetInput?.value?.trim());
    if (
      targetInput?.value?.trim() !== passwordRef?.current?.getValue().trim()
    ) {
      setInvalidPassword(true);
    } else {
      setInvalidPassword(false);
    }
  };

  const searchParams = new URLSearchParams(location.search);
  let authToken;

  for (const [key, value] of searchParams.entries()) {
    authToken = `${key}=${value}`;
    break;
  }

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const [
    resetPassword,
    { data, isFetching, isSuccess, isError: isErrorr, error },
  ] = useLazyResetPasswordQuery();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (
        isError ||
        !validatePassword(passwordRef?.current?.getValue()) ||
        confirmPassword?.trim() !== passwordRef?.current?.getValue().trim() ||
        !confirmPassword ||
        passwordRef?.current?.getValue() === ""
      ) {
      } else {
        handleContinueToVerify();
      }
    }
  };

  const handleContinueToVerify = async () => {
    const data = {
      password: passwordRef?.current?.getValue().trim() || "",
      password_confirmation: confirmPassword || "",
      authToken: authToken,
    };

    await resetPassword(data);
  };

  useEffect(() => {
    const typedLength = confirmPassword.length >= 1;
    const addOutline = typedLength && invalidPassword;
    if (addOutline) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    return () => {};
  }, [confirmPassword, confirmPassword.length, invalidPassword]);

  useEffect(() => {
    if (isSuccess) {
      setToastMessage({
        message: "Welcome Back!",
        status: "success",
        heading: "Success",
      });
      localStorage.setItem("authToken", data.data.auth);
      navigate(clientPages.dashboard);
    }

    if (isErrorr) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isSuccess, isErrorr, error?.data]);

  return (
    <>
      {isSuccess ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {isErrorr ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      <Base classes={styles["steps-form"]} stepsPage={false}>
        <Form
          buttonLabel={isFetching ? "" : "Reset Password"}
          color={
            isError ||
            !validatePassword(passwordRef?.current?.getValue()) ||
            confirmPassword?.trim() !==
              passwordRef?.current?.getValue().trim() ||
            !confirmPassword ||
            passwordRef?.current?.getValue() === ""
              ? "disabled"
              : "completed"
          }
          handleButtonClick={handleContinueToVerify}
          onKeyPress={handleKeyPress}
          icon={isFetching ? "spinner-alt" : "."}
        >
          <div className={styles.inner}>
            <label className={styles.title}>Reset Password</label>

            <TextInput
              hasLabel
              label={"New Password"}
              icon={showPassword?.first ? "eye" : "eye-crossed"}
              type={showPassword?.first ? "text" : "password"}
              placeholder={"Enter Password"}
              ref={passwordRef}
              hasIcon
              iconPosition={"right"}
              iconColor={"shade-0"}
              onChange={handlePasswordInput}
              onIconClick={() =>
                setShowPassword((prevState) => ({
                  ...prevState,
                  first: !prevState.first,
                }))
              }
            />
            <TextInput
              hasLabel
              hasIcon
              iconPosition={"right"}
              icon={showPassword?.confirmFirst ? "eye" : "eye-crossed"}
              type={showPassword?.confirmFirst ? "text" : "password"}
              iconColor={"shade-0"}
              label={"Confirm Password"}
              placeholder={"Confirm Password"}
              onChange={handleConfirmPassword}
              outline={isError}
              onIconClick={() =>
                setShowPassword((prevState) => ({
                  ...prevState,
                  confirmFirst: !prevState.confirmFirst,
                }))
              }
            />
            <ul>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.length ? styles.checked : ""
                  }`}
                >
                  {state.requirements.length ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must be at least 8 characters
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsNumber ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsNumber ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain one number
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsLowercase ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsLowercase ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain at least one lowercase.
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsUppercase ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsUppercase ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain at least one uppercase.
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsSymbol ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsSymbol ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain at least one symbol ‒!@#$％＾&()＿+‒
              </li>
            </ul>
          </div>
        </Form>
      </Base>
    </>
  );
};

export default React.memo(ResetPassword);
