import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./DropdownMenu.module.scss";
import Card from "../../../../Card/Card";
import Icon from "../../../../Icons/Icon";

const DropdownMenu = ({
  setSelected,
  selected,
  canSelectMultiple,
  setDropdown,
  options,
  handleSelection,
  top,
  hasIcon,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleListClick = (item) => {
    const selectedValue = item.value;
    if (canSelectMultiple) {
      if (selectedItems.includes(selectedValue)) {
        setSelectedItems(
          selectedItems.filter((item) => item !== selectedValue)
        );
      } else {
        setSelectedItems([...selectedItems, selectedValue]);
      }
    } else {
      setSelected(selectedValue);
      handleSelection(selectedValue);
      setDropdown(false);
    }
  };

  return (
    <>
      <div className={styles.modal} onClick={() => setDropdown(false)}></div>
      <Card className={styles.dropdown} style={{ top: top }}>
        {options?.map((item, index) => (
          <li
            key={index}
            onClick={() => handleListClick(item)}
            className={selectedItems.includes(item) ? styles.selected : ""}
            style={{ paddingLeft: hasIcon && `calc(${item.iconWidth + 10}px)` }}
          >
            {hasIcon && (
              <span
                className={styles["dropdown-icon-container"]}
                style={{
                  width: item.iconWidth,
                  height: item.iconHeight,
                  minWidth: item.iconWidth,
                  minHeight: item.iconHeight,
                  maxWidth: item.iconWidth,
                  maxHeight: item.iconHeight,
                  background: !item.icon && item.value,
                  borderRadius: !item.icon && item.iconWidth,
                }}
              >
                {item.icon && (
                  <Icon
                    icon={item.icon}
                    width={item.iconWidth}
                    height={item.iconHeight}
                    color={item.iconColor}
                  />
                )}
              </span>
            )}
            {item.label}
            {selectedItems.includes(item) ? (
              <Icon icon="check" color="success" />
            ) : (
              ""
            )}
          </li>
        ))}
      </Card>
    </>
  );
};

DropdownMenu.propTypes = {
  setSelected: PropTypes.func.isRequired,
  canSelectMultiple: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

DropdownMenu.defaultProps = {
  canSelectMultiple: false,
  hasIcon: false,
};

export default DropdownMenu;
