import React, { useEffect, useState } from "react";
import styles from "./CartHeader.module.scss";
import ShoppingCart from "../../../Pages/CheckoutScreen/Subpages/ShoppingCart/Main";

const Slider = ({ activeTab }) => {
  if (activeTab === 0) {
    return <div>empty1</div>;
  } else if (activeTab === 1) {
    return <div>empty2</div>;
  } else if (activeTab === 2) {
    return <ShoppingCart />;
  } else {
    return null;
  }
};

const CartHeader = () => {
  const [sliderPos, setSliderPos] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const firstDiv = document.querySelector(".first-div");
    if (activeTab === 0) {
      setSliderWidth(firstDiv.offsetWidth + 7);
      setSliderPos(firstDiv.offsetLeft);
    }
  }, []);

  const handleTabClick = (el, index) => {
    const tabPos = el.offsetLeft;
    const width = el.clientWidth;
    setSliderWidth(width);
    setSliderPos(tabPos);
    setActiveTab(index);
  };

  return (
    <div className={styles["cart-header"]}>
      <div className={styles["cart-header__inner"]}>
        <span
          className={styles["cart-header__slider-span"]}
          style={{
            width: `${sliderWidth}px`,
            transform: `translateX(${sliderPos}px)`,
          }}
        ></span>
        <div className={styles["cart-header__slider"]}></div>
        <div
          className={`${styles["cart-header__tabs"]} ${
            activeTab === 0 ? styles.active : ""
          } first-div`}
          onClick={(el) => handleTabClick(el.currentTarget, 0)}
        >
          <p className={styles.titles}>Home Page</p>
        </div>
        <div
          className={`${styles["cart-header__tabs"]} ${
            activeTab ? styles.active : ""
          } first-div`}
          onClick={(el) => handleTabClick(el.currentTarget, 0)}
        >
          <p className={styles.titles}>/</p>
        </div>
        <div
          className={`${styles["cart-header__tabs"]} ${
            activeTab === 1 ? styles.active : ""
          }`}
          onClick={(el) => handleTabClick(el.currentTarget, 1)}
        >
          <p className={styles.titles}>Categories</p>
        </div>
        <div
          className={`${styles["cart-header__tabs"]} ${
            activeTab ? styles.active : ""
          } first-div`}
          onClick={(el) => handleTabClick(el.currentTarget, 0)}
        >
          <p className={styles.titles}>/</p>
        </div>
        <div
          className={`${styles["cart-header__tabs"]} ${
            activeTab === 2 ? styles.active : ""
          }`}
          onClick={(el) => handleTabClick(el.currentTarget, 2)}
        >
          <p className={styles.titles}>Shopping Cart</p>
        </div>
      </div>
      <Slider activeTab={activeTab} />
    </div>
  );
};

export default CartHeader;
