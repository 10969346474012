import React, { useEffect, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import {
  useCreateACategoryMutation,
  useDeleteACategoryMutation,
  useGetAllCategoriesQuery,
  useUpdateACategoryMutation,
} from "../../../redux/services/client/accounts";
import { Loader, Icon, Toast } from "../../../Components/Atoms";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import styles from "./_Main.module.scss";
import { useNavigate } from "react-router-dom";
import { clientPages } from "../../../Routes/routes";

const Categories = () => {
  const [categoriesCount, setCategoriesCount] = useState(0);
  const [originalValues, setOriginalValues] = useState([]);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const navigate = useNavigate();

  const [loadingIndex, setLoadingIndex] = useState(null);
  const { data: categoryDetails, isError, error } = useGetAllCategoriesQuery();

  const categoriesData = categoryDetails?.data || [];

  const categoriesLength = Array.from(
    { length: categoriesCount },
    (_, index) => index
  );

  const [categories, setCategories] = useState(
    categoriesLength?.map(() => ({
      title: "",
      description: "",
      image: {},
      id: "",
    }))
  );

  const [inputValues, setInputValues] = useState(
    categories?.map(() => ({
      title: "",
      description: "",
      image: {},
      id: "",
    }))
  );

  const [
    updateCategory,
    {
      isLoading: updateLoading,
      isSuccess: updateCategorySuccess,
      isError: updateCategoryError,
      error: updateError,
    },
  ] = useUpdateACategoryMutation();

  const [
    createCategory,
    {
      isLoading: createLoading,
      isSuccess: createCategorySuccess,
      isError: createCategoryError,
      error: createError,
    },
  ] = useCreateACategoryMutation();

  const [
    deleteCategory,
    {
      isLoading: deleteLoading,
      isSuccess: deleteCategorySuccess,
      isError: deleteCategoryError,
      error: deleteError,
    },
  ] = useDeleteACategoryMutation();

  const handleAddRateClick = () => {
    setCategoriesCount((prevCount) => prevCount + 1);
    setCategories((prevStates) => [
      ...prevStates,
      {
        title: "",
        description: "",
        image: {},
        id: "",
      },
    ]);
  };

  const handleInputChange = (containerIndex, field, value, id) => {
    setInputValues((prevInputValues) => {
      const updatedInputValues = [...prevInputValues];
      updatedInputValues[containerIndex] = {
        ...updatedInputValues[containerIndex],
        [field]: value,
        id,
      };
      return updatedInputValues;
    });
  };

  const handleButtonClick = async (index, action) => {
    setLoadingIndex(index);
    const { title, id } = inputValues[index] || {};
    const categoryData = { title, id };
    if (action === "check") {
      if (id) {
        await updateCategory(categoryData);
      } else {
        await createCategory({ title });
      }
    } else if (action === "trash") {
      if (id) {
        deleteCategory({ id });
      }
    }
  };

  useEffect(() => {
    if (isError && error?.status === 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    }

    if (isError && error?.status !== 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isError, error?.data, error?.status]);

  useEffect(() => {
    const categoriesData = categoryDetails?.data || [];
    setOriginalValues(categoriesData);
    setCategories(categoriesData);
    setInputValues(categoriesData);
    setCategoriesCount(categoriesData?.length);
  }, [categoryDetails]);

  useEffect(() => {
    if (updateCategorySuccess) {
      setToastMessage({
        message: "Category Updated",
        status: "success",
        heading: "Success",
      });
    }
    if (updateCategoryError && updateError?.status === 401) {
      setToastMessage({
        message: updateError?.data?.message || "Something went wrong",
        status: "error",
        heading: updateError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (updateCategoryError && updateError?.status !== 401) {
      setToastMessage({
        message: updateError?.data?.message || "Something went wrong",
        status: "error",
        heading: updateError?.data?.status || "Error",
      });
    }
  }, [
    updateCategorySuccess,
    updateCategoryError,
    updateError?.data,
    updateError?.data,
  ]);

  useEffect(() => {
    if (createCategorySuccess) {
      setToastMessage({
        message: "New Category Created",
        status: "success",
        heading: "Success",
      });
    }
    if (createCategoryError && createError?.status === 401) {
      setToastMessage({
        message: createError?.data?.message || "Something went wrong",
        status: "error",
        heading: createError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (createCategoryError && createError?.status !== 401) {
      setToastMessage({
        message: createError?.data?.message || "Something went wrong",
        status: "error",
        heading: createError?.data?.status || "Error",
      });
    }
  }, [
    createCategorySuccess,
    createCategoryError,
    createError?.data,
    createError?.data,
  ]);

  useEffect(() => {
    if (deleteCategorySuccess) {
      setToastMessage({
        message: "Category Deleted",
        status: "success",
        heading: "Success",
      });
    }
    if (deleteCategoryError && deleteError?.status === 401) {
      setToastMessage({
        message: deleteError?.data?.message || "Something went wrong",
        status: "error",
        heading: deleteError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (deleteCategoryError && deleteError?.status !== 401) {
      setToastMessage({
        message: deleteError?.data?.message || "Something went wrong",
        status: "error",
        heading: deleteError?.data?.status || "Error",
      });
    }
  }, [
    deleteCategorySuccess,
    deleteCategoryError,

    deleteError?.data,
    deleteError?.data,
  ]);

  // useEffect(() => {
  //   console.log(categories);
  // }, [categoryDetails, categoriesCount]);

  return (
    <>
      {createCategorySuccess || createCategoryError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      {updateCategorySuccess || updateCategoryError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      {deleteCategorySuccess || deleteCategoryError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <section className={styles.categories}>
        <Form headerTitle={"Categories"} hasTitle={false} size={549}>
          {!categoryDetails ? (
            <Loader size={70} />
          ) : (
            <>
              {categories.length > 0 && (
                <div className={styles.top}>
                  <label>Categories</label>
                  <label>Actions</label>
                </div>
              )}
              {categories?.map((category, containerIndex) => (
                <div
                  className={styles["categories-container"]}
                  key={containerIndex}
                >
                  <div className={styles["categories-container_left"]}>
                    <TextInput
                      hasLabel={false}
                      placeholder={"Category Title"}
                      value={inputValues[containerIndex]?.title}
                      onChange={(e) =>
                        handleInputChange(
                          containerIndex,
                          "title",
                          e.target.value,
                          category.id
                        )
                      }
                    />
                  </div>
                  <div className={styles["categories-container_right"]}>
                    <span>
                      <button
                        className={styles["actions-btn"]}
                        onClick={() =>
                          handleButtonClick(containerIndex, "check")
                        }
                        disabled={
                          inputValues[containerIndex]?.title ===
                          originalValues[containerIndex]?.title
                        }
                      >
                        <Icon
                          icon={
                            containerIndex === loadingIndex &&
                            (updateLoading || createLoading)
                              ? "spinner-alt"
                              : "check"
                          }
                          width={17}
                          height={17}
                          color={"white"}
                        />
                      </button>
                      <button
                        className={styles["actions-btn"]}
                        onClick={() =>
                          handleButtonClick(containerIndex, "trash")
                        }
                      >
                        <Icon
                          icon={
                            containerIndex === loadingIndex && deleteLoading
                              ? "spinner-alt"
                              : "trash"
                          }
                          width={17}
                          height={17}
                          color={"error"}
                        />
                      </button>
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}
          <button
            className={styles["product-btn"]}
            onClick={handleAddRateClick}
          >
            <span className={styles["product-btn__plus"]}>
              <Icon icon={"plus-small"} color={"white"} />
            </span>
            {categories.length < 1 ? "Add a Category" : "Add another Category"}
          </button>{" "}
        </Form>
      </section>
    </>
  );
};

export default Categories;
