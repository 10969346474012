import React, { useEffect, useState } from "react";
import Base from "../Base/Base";
import styles from "./../Base/Base.module.scss";
import { Button, Icon } from "../../../Components/Atoms";
import { useNavigate } from "react-router-dom";
import { clientPages } from "../../../Routes/routes";

const Start = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const handleGetStartedClick = () => {
    if (checked) {
      navigate(clientPages.login);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleGetStartedClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div style={{ width: "100vw" }}>
      <Base stepsPage={false} width={500} classes={"start-page"}>
        <div className={styles.welcome}>
          <span>Hey 👋🏽</span>
          <span>
            Welcome to <strong>awa</strong>shop
          </span>
        </div>
        <div className={styles.introduction}>
          <span>
            We need some important information about you and your organization
            to set up your organization’s space
          </span>
        </div>
        <div className={styles["checkbox-container"]}>
          <span
            onClick={() => setChecked(!checked)}
            className={`${styles.icon} ${checked ? styles.checked : ""}`}
          >
            {checked ? (
              <Icon icon="check" color="success" width={8} height={8} />
            ) : (
              ""
            )}
          </span>
          <p
            className={checked ? styles.active : ""}
            onClick={() => setChecked(!checked)}
          >
            I agree to the Terms, Conditions and Privacy Policy
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            label="Get Started"
            color={checked ? "completed" : "disabled"}
            hasIcon={true}
            iconPosition="right"
            icon="arrow-small-right"
            size={200}
            height={36}
            handleClick={handleGetStartedClick}
          />
          <span>press enter</span>
        </div>
      </Base>
    </div>
  );
};

export default Start;
