import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import styles from "./Product.module.scss";
import img from "../../../assets/images/product.png";
import { Icon, Toast } from "../../Atoms";
import { useNavigate } from "react-router-dom";
import {
  useAddToCartMutation,
  useAddToExistingCartMutation,
  useRemoveItemFromCartMutation,
  useUpdateItemOnCartMutation,
} from "../../../redux/services/client/buyer";
import { localStorageCart } from "../../../Pages/Buyers/helpers";

const ProductCard = ({
  image,
  noOfItems,
  productName,
  productDescription,
  price,
  productId,
  itemID,
  quantity,
  shopSlug,
}) => {
  const [addedToCart, setAddedToCart] = useState(false);
  const [number, setNumber] = useState(quantity || null);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const existingAwashopData = JSON.parse(
    localStorage.getItem(localStorageCart)
  );

  const matchingCartItem = existingAwashopData?.find(
    (item) => item.slug === shopSlug
  );

  const { cartId } = matchingCartItem || {};

  const [
    addToCart,
    {
      data: cartDetails,
      isSuccess: createCartSuccess,
      isError: createCartError,
      error: createError,
    },
  ] = useAddToCartMutation();

  const [
    updateCartItem,
    {
      isSuccess: updateCartItemSuccess,
      isError: updateCartItemError,
      error: updateCartError,
    },
  ] = useUpdateItemOnCartMutation();

  const [
    addToExistingCart,
    {
      isSuccess: addToExistingCartSuccess,
      isError: addToExistingCartError,
      error: existingCartError,
    },
  ] = useAddToExistingCartMutation();

  const [
    removeCartItem,
    {
      isSuccess: removeCartItemSuccess,
      isError: removeCartItemError,
      error: removeCartError,
    },
  ] = useRemoveItemFromCartMutation();

  const handleAddedToCart = async () => {
    setNumber(1);
    setAddedToCart(true);
    if (cartId !== undefined && cartId !== null) {
      await addToExistingCart({
        shop_slug: shopSlug,
        cart_id: cartId,
        product_id: productId,
        quantity: 1,
      });
    } else
      await addToCart({
        shop_slug: shopSlug,
        product_id: productId,
        quantity: 1,
      });
  };

  const handleIncrement = async () => {
    setNumber((prevNumber) => prevNumber + 1);

    if (cartId !== undefined && cartId !== null) {
      await updateCartItem({
        shop_slug: shopSlug,
        cart_id: cartId,
        item_id: itemID,
        quantity: number + 1,
      });
    }
  };

  const handleDecrement = async () => {
    setNumber((prevNumber) => prevNumber - 1);
    if (cartId !== undefined && cartId !== null) {
      await updateCartItem({
        shop_slug: shopSlug,
        cart_id: cartId,
        item_id: itemID,
        quantity: number - 1,
      });
    }
  };

  const handleRemoveFromCart = async () => {
    await removeCartItem({
      shop_slug: shopSlug,
      cart_id: cartId,
      item_id: itemID,
    });
  };

  useEffect(() => {
    if (quantity !== null && quantity !== undefined) {
      setAddedToCart(true);
      setNumber(quantity);
    }

    return () => {};
  }, [quantity]);

  useEffect(() => {
    if (number === 0) {
      handleRemoveFromCart();
      setAddedToCart(false);
    }
    return () => {};
  }, [number]);

  useEffect(() => {
    if (createCartSuccess) {
      let updatedAwashopData;

      setToastMessage({
        message: "Item has been added to cart",
        status: "success",
        heading: "Success",
      });

      if (
        Array.isArray(existingAwashopData) &&
        existingAwashopData.length > 0
      ) {
        const matchingCartItem = existingAwashopData.find(
          (item) => item.slug === shopSlug
        );
        if (matchingCartItem) {
          matchingCartItem.cartId = cartDetails?.data?.id;
          matchingCartItem.slug = shopSlug;
          updatedAwashopData = [...existingAwashopData];
        } else {
          updatedAwashopData = [
            ...existingAwashopData,
            { cartId: cartDetails?.data?.id, slug: shopSlug },
          ];
        }
      } else {
        updatedAwashopData = [
          { cartId: cartDetails?.data?.id, slug: shopSlug },
        ];
      }

      localStorage.setItem(
        localStorageCart,
        JSON.stringify(updatedAwashopData)
      );
    }

    if (createCartError) {
      setToastMessage({
        message: createError?.data?.message || "Something went wrong",
        status: "error",
        heading: createError?.data?.status || "Error",
      });
      if (addedToCart) {
        setAddedToCart(false);
        setNumber(null);
      }
    }

    return () => {};
  }, [createCartError, createCartSuccess, createError?.data, cartDetails]);

  useEffect(() => {
    if (updateCartItemSuccess) {
      setToastMessage({
        message: "Cart updated successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (updateCartItemError) {
      setToastMessage({
        message: updateCartError?.data?.message || "Something went wrong",
        status: "error",
        heading: updateCartError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [updateCartItemSuccess, updateCartItemError, updateCartError?.data]);

  useEffect(() => {
    if (addToExistingCartSuccess) {
      setToastMessage({
        message: "Item has been added to cart",
        status: "success",
        heading: "Success",
      });
    }

    if (addToExistingCartError) {
      setToastMessage({
        message: existingCartError?.data?.message || "Something went wrong",
        status: "error",
        heading: existingCartError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [
    addToExistingCartSuccess,
    addToExistingCartError,
    existingCartError?.data,
  ]);

  useEffect(() => {
    if (removeCartItemSuccess) {
      setToastMessage({
        message: "Item removed successfully from cart",
        status: "success",
        heading: "Success",
      });
    }

    if (removeCartItemError) {
      setToastMessage({
        message: removeCartError?.data?.message || "Something went wrong",
        status: "error",
        heading: removeCartError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [removeCartItemSuccess, removeCartItemError, removeCartError?.data]);

  return (
    <>
      {createCartSuccess || createCartError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerPage"}
        />
      ) : (
        ""
      )}

      {updateCartItemSuccess || updateCartItemError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerPage"}
        />
      ) : (
        ""
      )}

      {addToExistingCartSuccess || addToExistingCartError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerPage"}
        />
      ) : (
        ""
      )}

      {removeCartItemSuccess || removeCartItemError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerPage"}
        />
      ) : (
        ""
      )}
      <div className={styles["product-card"]}>
        <img src={image || img} alt="product-image" className={styles.image} />
        <div className={styles.data}>
          <span className={styles["data-top"]}>
            <span className={styles.ellipse}></span>
            {`${noOfItems || 20} items available`}
          </span>
          <div className={styles["data-center"]}>
            <p className={styles.title}>{productName}</p>
            <label className={styles.label}>{productDescription}</label>
          </div>
          <div className={styles["data-bottom"]}>
            <div className={styles.price}>
              <CurrencyFormat
                value={price}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>
            {addedToCart ? (
              <div className={styles.increment}>
                <button
                  className={styles["increment-left"]}
                  onClick={handleDecrement}
                  disabled={number === 0 ? true : false}
                >
                  <Icon
                    icon={"minus-small"}
                    color={"white"}
                    width={24}
                    height={24}
                  />
                </button>
                <span className={styles["increment-center"]}>{number}</span>
                <button
                  className={styles["increment-right"]}
                  onClick={handleIncrement}
                  disabled={number === noOfItems}
                >
                  <Icon
                    icon={"plus-small"}
                    color={"white"}
                    width={24}
                    height={24}
                  />
                </button>
              </div>
            ) : (
              <button className={styles.add} onClick={handleAddedToCart}>
                <Icon
                  icon={"plus-small"}
                  color={"white"}
                  width={24}
                  height={24}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
