import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import useLocalStorage from "use-local-storage";
import ClientRoutes from "./Routes/routes";
import { useEffect } from "react";
import { Icon } from "./Components/Atoms";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";

function App() {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  ReactGA.initialize("G-R2N4GT2DHW");

  const switchTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  // useEffect(() => {
  //   let isDomain = window.location.pathname === "/";
  //   if (isDomain) {
  //     window.location.href += "clients/auth/start";
  //   }
  //   return () => {
  //     isDomain = window.location.pathname === "/";
  //   };
  // }, []);

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <div className="App" data-theme={theme}>
        <div id="portal"></div>
        <Router>
          <ClientRoutes />
        </Router>

        <button className="theme-toggle" onClick={switchTheme}>
          {theme === "light" ? (
            <Icon icon="moon" color="primary" width={24} height={24} />
          ) : (
            <Icon icon="sun" color="completed" width={24} height={24} />
          )}
        </button>
      </div>
    </HelmetProvider>
  );
}

export default App;
