import React, { useEffect, useRef, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import styles from "./_Main.module.scss";
import { PhoneInput, TextInput } from "../../../Components/Atoms/Input/Input";
import { Button, CountrySelect, Icon, Toast } from "../../../Components/Atoms";
import { useDispatch, useSelector } from "react-redux";
import { updateClientData } from "../../../redux/features/newClient";
import {
  useLazyInitiatePaymentQuery,
  useLazyUpdateRecipientDetailsQuery,
  useGetCitiesListQuery,
  useGetCountriesListQuery,
  useGetStatesListQuery,
} from "../../../redux/services/client/buyer";
import MonitorPayment from "../../../Components/Modals/Checkout/MonitorPayment";
import { useNavigate } from "react-router-dom";
import { localStorageCart, localStorageUserData } from "../helpers";

const Checkout = () => {
  const [activeButton, setActiveButton] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [reRender, setReRender] = useState(false);

  const currentPath = window.location.pathname;

  const pathnameParts = currentPath.split("/");

  const shopSlug = pathnameParts[1];

  const existingAwashopData =
    JSON.parse(localStorage.getItem(localStorageUserData)) || {};

  const { recipientInfo, recipientAddress } = existingAwashopData || {};

  const { first_name, last_name, phone, email } = recipientInfo || {};

  const { country, state, city, post_code, street, house_number } =
    recipientAddress || {};

  const navigate = useNavigate();

  const providerList = ["paystack", "flutterwave"];

  const buttons = [
    {
      icon: "paystack-icon",
      width: 112.143,
    },
    {
      icon: "flutterwave-icon",
      width: 118.889,
    },
    // {
    //   icon: "monnify-icon",
    //   width: 116.19,
    // },
  ];

  const provider = providerList[activeButton];

  const [checkEmpty, setCheckEmpty] = useState(false);
  const [checkEmptyAddress, setCheckEmptyAddress] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    zipCode: "",
    streetAddress: "",
  });

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const streetRef = useRef(null);

  useEffect(() => {
    if (recipientAddress || recipientInfo) {
      setFormData({
        firstName: first_name,
        lastName: last_name,
        emailAddress: email,
        phoneNumber: phone.slice(4).replace(/\s/g, ""),
        zipCode: post_code,
        streetAddress: street,
      });
      setSelectedCity({
        cityName: city,
      });
      setSelectedState({
        stateName: state,
      });
    }

    const handleMessage = (event) => {
      // // Check the origin of the message for security reasons
      // if (event.origin !== 'http://example.com') { // Replace with the actual origin of the iframe
      //   return;
      // }

      if (event.data.action === "closeIframe") {
        handleModalClose();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const cartInfo = JSON.parse(localStorage.getItem(localStorageCart));

  const matchingCartItem = cartInfo?.find((item) => item.slug === shopSlug);

  const { cartId, slug } = matchingCartItem || {};

  const [selectedCountry, setSelectedCountry] = useState({
    countryName: "Nigeria",
    postalCode: null,
    alpha2: "NG",
  });

  const [selectedState, setSelectedState] = useState({
    stateName: null,
    stateCode: null,
  });

  const [selectedCity, setSelectedCity] = useState({
    cityName: null,
  });

  const [
    updateDetails,
    {
      data: recipientDetailsData,
      isFetching: detailsUpdating,
      isSuccess: updateDetailsSuccess,
      isError: updateDetailsError,
      error,
    },
  ] = useLazyUpdateRecipientDetailsQuery();

  const [
    initiatePayment,
    {
      data: initiatePaymentResponse,
      isFetching: paymentInitiating,
      isSuccess: paymentSuccess,
      isError: paymentError,
      error: payError,
    },
  ] = useLazyInitiatePaymentQuery();

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const handleIntiatePayment = async () => {
    await initiatePayment({
      shop_slug: slug,
      cart_id: cartId,
      provider: provider,
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate(`/${slug}/verify-payment`);
  };

  const handleUpdateRecipientData = async () => {
    setReRender(!reRender);
    const changedRecipientDetails = {
      recipient: {},
      address: {},
    };

    if (formData.firstName !== first_name) {
      changedRecipientDetails.recipient.first_name = formData.firstName.trim();
    }

    if (formData.lastName !== last_name) {
      changedRecipientDetails.recipient.last_name = formData.lastName.trim();
    }

    if (formData.phoneNumber !== phone) {
      changedRecipientDetails.recipient.phone = formData.phoneNumber;
    }

    if (formData.emailAddress !== email) {
      changedRecipientDetails.recipient.email = formData.emailAddress.trim();
    }

    if (selectedCountry.countryName !== country) {
      changedRecipientDetails.address.country = selectedCountry.countryName;
    }

    if (selectedState.stateName !== state) {
      changedRecipientDetails.address.state = selectedState.stateName;
    }

    if (selectedCity.cityName !== city) {
      changedRecipientDetails.address.city = selectedCity.cityName;
    }

    if (formData.zipCode !== post_code) {
      changedRecipientDetails.address.post_code = formData.zipCode;
    }

    if (formData.streetAddress !== street) {
      changedRecipientDetails.address.street = formData.streetAddress.trim();
    }

    if (
      Object.keys(changedRecipientDetails.recipient).length > 0 ||
      Object.keys(changedRecipientDetails.address).length > 0
    ) {
      const inputRefs = [firstNameRef, lastNameRef, emailRef, streetRef];
      const otherComponents = [
        countryName,
        cityName,
        stateName,
        formData?.phoneNumber,
      ];
      const componentNames = [
        "Country Name",
        "City Name",
        "State Name",
        "Phone Number",
      ];

      let name;

      const firstEmptyInputRef = inputRefs.find(
        (inputRef) => inputRef.current?.getValue().trim() === ""
      );

      const firstEmptyComponent = otherComponents?.find(
        (location) => location === "" || location === null
      );

      const index = otherComponents?.indexOf(firstEmptyComponent);

      name = componentNames[index];

      if (firstEmptyInputRef) {
        setCheckEmpty(true);
        firstEmptyInputRef.current?.focus();
      } else if (firstEmptyComponent !== undefined) {
        setCheckEmptyAddress(true);
        setToastMessage({
          status: "error",
          heading: "Error",
          message: `${name} should not be empty`,
        });
      } else {
        await updateDetails({
          cart_id: cartId,
          shop_slug: slug,
          ...changedRecipientDetails,
        });
      }
    }
  };

  const client = useSelector((state) => state.client.value);
  const dispatch = useDispatch();

  const { countryName, alpha2, postalCode } = selectedCountry;
  const { stateName, stateCode } = selectedState;
  const { cityName } = selectedCity;

  const stateDropdownFormatter = (arr) => {
    return arr.map((item) => ({
      name: item?.name,
      alpha2: item?.code,
    }));
  };

  const {
    data: countriesData,
    isSuccess: countriesFetchSuccess,
    isError: countriesFetchError,
    error: countriesError,
    refetch,
  } = useGetCountriesListQuery(slug);

  const handleInputChange = async (field, value) => {
    if (checkEmpty) {
      setCheckEmpty(false);
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  useEffect(() => {
    if (checkEmptyAddress) {
      setCheckEmptyAddress(false);
    }

    return () => {};
  }, [countryName, cityName, stateName]);

  useEffect(() => {
    if (countriesFetchSuccess) {
      dispatch(
        updateClientData({
          countries: countriesData.data.countries,
        })
      );
    }
    return () => {};
  }, [countriesFetchSuccess, countriesFetchError]);

  const {
    data: statesData,
    isSuccess: statesQuerySuccess,
    isError: statesQueryError,
    error: statesError,
    refetch: refetchStates,
  } = useGetStatesListQuery(
    {
      shop_slug: slug,
      country_alpha2: alpha2,
    },
    { skip: !alpha2 }
  );

  useEffect(() => {
    if (statesQuerySuccess) {
      dispatch(
        updateClientData({
          states: stateDropdownFormatter(statesData.data.states),
        })
      );
    }
    return () => {};
  }, [statesQuerySuccess, statesData, statesQueryError]);

  const {
    data: citiesData,
    isSuccess: citiesQuerySuccess,
    isError: citiesQueryError,
    refetch: refetchCities,
  } = useGetCitiesListQuery(
    {
      country_alpha2: alpha2,
      state_code: stateCode,
      shop_slug: slug,
    },
    { skip: !stateCode }
  );

  useEffect(() => {
    if (citiesQuerySuccess) {
      dispatch(
        updateClientData({
          cities: citiesData.data.cities,
        })
      );
    }
    return () => {};
  }, [citiesQuerySuccess, citiesData, citiesQueryError]);

  useEffect(() => {
    if (countriesFetchError || statesQueryError) {
      if (countriesError?.status === 404 || statesError?.status === 400) {
        navigate("*");
      }
    }

    return () => {};
  }, [
    countriesFetchError,
    statesQueryError,
    countriesError?.status,
    statesError?.status,
  ]);

  const handleCountrySelection = ({ alpha2, postal_code, name }) => {
    setSelectedCountry({
      countryName: name,
      alpha2: alpha2,
      postalCode: postal_code,
    });
  };

  const handleStateSelection = ({ alpha2, name }) => {
    setSelectedState({
      stateName: name,
      stateCode: alpha2,
    });
  };

  const handleCitySelection = ({ name }) => {
    setSelectedCity({
      cityName: name,
    });
  };

  let errorMessage = "Something went wrong";
  let errorStatus = "error";
  let errorHeading = "Error";

  useEffect(() => {
    if (updateDetailsError) {
      if (error?.status === 422) {
        const errorData = error?.data?.data[0];

        if (error?.data?.status) {
          errorHeading = `: ${error?.data?.status}`;
        }

        if (errorData) {
          const firstError = errorData;

          if (firstError) {
            if (firstError.post_code) {
              errorMessage = `Post/Zip Code: ${firstError.post_code[0]}`;
            }
            if (firstError.street) {
              errorMessage = `\nStreet: ${firstError.street[0]}`;
            }

            if (firstError.phone) {
              errorMessage = `\nPhone: ${firstError.phone[0]}`;
            }
            if (firstError.first_name) {
              errorMessage = `\nFirst Name: ${firstError.first_name[0]}`;
            }
            if (firstError.last_name) {
              errorMessage = `\nLast Name: ${firstError.last_name[0]}`;
            }
            if (firstError.email) {
              errorMessage = `\nEmail: ${firstError.email[0]}`;
            }
          }
        }
        setToastMessage({
          message: errorMessage,
          status: errorStatus,
          heading: errorHeading,
        });
      } else {
        setToastMessage({
          message: error?.data?.message || "Something went wrong",
          status: "error",
          heading: error?.data?.status || "Error",
        });
      }
    }
    return () => {};
  }, [reRender, updateDetailsError, error?.data?.data]);

  useEffect(() => {
    if (updateDetailsSuccess) {
      const updatedAwashopData = {
        recipientInfo: recipientDetailsData?.data?.recipient,
        recipientAddress: recipientDetailsData?.data?.address,
      };
      localStorage.setItem(
        localStorageUserData,
        JSON.stringify(updatedAwashopData)
      );
      handleIntiatePayment();
    }
    return () => {};
  }, [updateDetailsSuccess]);

  useEffect(() => {
    if (paymentSuccess) {
      setPaymentUrl(initiatePaymentResponse?.data?.payment_url);
      setModalOpen(true);
    }
    return () => {};
  }, [paymentSuccess, reRender]);

  return (
    <>
      {updateDetailsError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerCheckout"}
        />
      ) : (
        ""
      )}
      {error?.status === 422 ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerCheckout"}
        />
      ) : (
        ""
      )}
      {checkEmptyAddress ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerCheckout"}
        />
      ) : (
        ""
      )}
      <section className={styles.checkout} id="buyerCheckout">
        <MonitorPayment
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          isForCheckout
          url={paymentUrl}
          onClose={handleModalClose}
        />
        <Form headerTitle={"Checkout"} hasTitle title={"Personal Information"}>
          {" "}
          <div className="half-width-form">
            <TextInput
              hasLabel
              label={<p className="required-label">First Name</p>}
              placeholder={"Elon"}
              value={formData?.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
              ref={firstNameRef}
              outline={
                checkEmpty &&
                firstNameRef?.current?.getValue().trim().length === 0
              }
            />
            <TextInput
              hasLabel
              label={<p className="required-label">Last Name</p>}
              placeholder={"Musk"}
              value={formData.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
              ref={lastNameRef}
              outline={
                checkEmpty &&
                lastNameRef?.current?.getValue().trim().length === 0
              }
            />
          </div>
          <div className="half-width-form">
            <PhoneInput
              hasLabel
              label={<p className="required-label">Phone Number</p>}
              placeholder="810 423 4654"
              value={formData.phoneNumber}
              onChange={(e) =>
                handleInputChange("phoneNumber", e.target.value.trim())
              }
            />
            <TextInput
              hasLabel
              label={<p className="required-label">Email Address</p>}
              placeholder="xyz@gmail.com"
              value={formData.emailAddress}
              onChange={(e) =>
                handleInputChange("emailAddress", e.target.value)
              }
              ref={emailRef}
              outline={
                checkEmpty && emailRef?.current?.getValue().trim().length === 0
              }
            />
          </div>
          <h1 className={styles["title-main"]}>Delivery Address</h1>
          <TextInput
            hasLabel
            label={<p className="required-label">Street</p>}
            placeholder="e.g. 425 White Lane Street"
            value={formData.streetAddress}
            onChange={(e) => handleInputChange("streetAddress", e.target.value)}
            ref={streetRef}
            outline={
              checkEmpty && streetRef?.current?.getValue().trim().length === 0
            }
          />
          <div className="half-width-form">
            <CountrySelect
              placeholder={countryName}
              selectedOption={handleCountrySelection}
              label={<p className="required-label">Country</p>}
              countryList={client.countries}
              searchPlaceholder="Search Country"
              enabled={false}
            />

            <CountrySelect
              placeholder={state || "Select State"}
              selectedOption={handleStateSelection}
              label={<p className="required-label">State</p>}
              countryList={client.states}
              enabled
              hasCountryCode={false}
              searchPlaceholder="Search State"
              type={"state"}
            />
          </div>
          <div className="half-width-form">
            <CountrySelect
              placeholder={city || "Select City"}
              selectedOption={handleCitySelection}
              label={<p className="required-label">City</p>}
              countryList={client.cities}
              enabled={citiesQuerySuccess ? true : false}
              hasCountryCode={false}
              searchPlaceholder="Search City"
              type={"city"}
            />
            <TextInput
              hasLabel
              label={"Post/Zip Code (optional)"}
              placeholder=""
              value={formData.zipCode}
              onChange={(e) =>
                handleInputChange("zipCode", e.target.value.trim())
              }
            />
          </div>
          <h1 className={styles["title-main"]}>Payment Method</h1>
          <div className={styles["payment-btn-container"]}>
            {buttons.map((button, index) => (
              <button
                key={index}
                className={`${styles["payment-btn"]} ${
                  activeButton === index ? styles["active"] : ""
                }`}
                onClick={() => handleButtonClick(index)}
              >
                <span className={styles["check-circle"]}>
                  {activeButton === index && <i></i>}
                </span>
                <span className={styles["payment-btn__right"]}>
                  <p className={styles["payment-mode"]}>
                    Pay with Debit/Credit Card
                  </p>
                  <span className={styles["powered-by"]}>
                    <p>powered by</p>
                    <Icon icon={button.icon} width={button.width} height={20} />
                  </span>
                </span>
              </button>
            ))}
          </div>
          <Button
            color={"completed"}
            label={
              detailsUpdating
                ? ""
                : `Continue with ${buttons[activeButton].icon
                    .split("-")[0]
                    .charAt(0)
                    .toUpperCase()}${buttons[activeButton].icon
                    .split("-")[0]
                    .slice(1)}`
            }
            // label={`Continue with ${buttons[activeButton].icon.split("-")[0]}`}
            height={36}
            handleClick={handleUpdateRecipientData}
            hasIcon
            iconPosition="right"
            icon={detailsUpdating ? "spinner-alt" : ""}
          />
        </Form>
      </section>
    </>
  );
};

export default Checkout;
