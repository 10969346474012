import React, { useEffect, useState } from "react";
import styles from "./CartItem.module.scss";
import { Icon, Toast } from "../../../Atoms";
import {
  useRemoveItemFromCartMutation,
  useUpdateItemOnCartMutation,
} from "../../../../redux/services/client/buyer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";

const CartItem = ({
  productImage,
  itemsAvailable,
  productName,
  productDescription,
  price,
  quantity,
  itemID,
  subtotal,
  cartID,
  shopSlug,
}) => {
  const [number, setNumber] = useState(quantity || null);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  // const buyer = useSelector((state) => state.buyer.value);

  const [
    updateCartItem,
    {
      isSuccess: updateCartItemSuccess,
      isError: updateCartItemError,
      error: updateCartError,
    },
  ] = useUpdateItemOnCartMutation();

  const [
    removeCartItem,
    {
      isSuccess: removeCartItemSuccess,
      isError: removeCartItemError,
      error: removeCartError,
    },
  ] = useRemoveItemFromCartMutation();

  const handleIncrement = async () => {
    setNumber((prevNumber) => prevNumber + 1);
    await updateCartItem({
      shop_slug: shopSlug,
      cart_id: cartID,
      item_id: itemID,
      quantity: number + 1,
    });
  };

  const handleDecrement = async () => {
    setNumber((prevNumber) => prevNumber - 1);
    await updateCartItem({
      shop_slug: shopSlug,
      cart_id: cartID,
      item_id: itemID,
      quantity: number - 1,
    });
  };

  const handleRemoveFromCart = async () => {
    await removeCartItem({
      shop_slug: shopSlug,
      cart_id: cartID,
      item_id: itemID,
    });
  };

  useEffect(() => {
    if (number === 0) {
      handleRemoveFromCart();
    }

    return () => {};
  }, [number]);

  useEffect(() => {
    if (updateCartItemSuccess) {
      setToastMessage({
        message: "Cart updated successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (updateCartItemError) {
      setToastMessage({
        message: updateCartError?.data?.message || "Something went wrong",
        status: "error",
        heading: updateCartError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [updateCartItemSuccess, updateCartItemError, updateCartError?.data]);

  useEffect(() => {
    if (removeCartItemSuccess) {
      setToastMessage({
        message: "Item removed successfully from cart",
        status: "success",
        heading: "Success",
      });
    }

    if (removeCartItemError) {
      setToastMessage({
        message: removeCartError?.data?.message || "Something went wrong",
        status: "error",
        heading: removeCartError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [removeCartItemSuccess, removeCartItemError, removeCartError?.data]);

  return (
    <>
      {updateCartItemSuccess || updateCartItemError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"myCart"}
        />
      ) : (
        ""
      )}

      {removeCartItemSuccess || removeCartItemError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"myCart"}
        />
      ) : (
        ""
      )}
      <div className={styles["cart-item"]}>
        <figure className={styles["product-image"]}>
          <img src={productImage} alt="product image" />
        </figure>
        <div className={styles.data}>
          <span className={styles["data-top"]}>
            <span className={styles.ellipse}></span>
            {`${itemsAvailable} items available`}
          </span>
          <div className={styles["data-center"]}>
            <p className={styles.title}>{productName}</p>
            <label className={styles.label}>{productDescription}</label>
          </div>
          <div className={styles["data-bottom"]}>
            <div className={styles["data-bottom__left"]}>
              <div className={styles.price}>
                <span className={styles.currency}>NGN </span>{" "}
                <p>
                  {" "}
                  <CurrencyFormat
                    value={subtotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </p>
              </div>
              <p className={styles["price-per-item"]}>
                {" "}
                <CurrencyFormat
                  value={price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />{" "}
                per item
              </p>
            </div>
            <div className={styles.increment}>
              <button
                className={styles["increment-left"]}
                onClick={handleDecrement}
                disabled={number === 0 ? true : false}
              >
                <Icon
                  icon={"minus-small"}
                  color={"white"}
                  width={24}
                  height={24}
                />
              </button>
              <span className={styles["increment-center"]}>{number}</span>
              <button
                className={styles["increment-right"]}
                onClick={handleIncrement}
                disabled={number === itemsAvailable}
              >
                <Icon
                  icon={"plus-small"}
                  color={"white"}
                  width={24}
                  height={24}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItem;
