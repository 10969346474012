import React, { useEffect, useRef } from "react";
import Icon from "../Icons/Icon";
import { clientPages } from "../../../Routes/routes";
import styles from "../../Bases/DashboardBase/DashboardBase.module.scss";
import { useNavigate } from "react-router-dom";

const VerificationPrompt = ({ phoneVerified, idVerified, liftHeight }) => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const handleCompleteVerification = () => {
    if (!phoneVerified) {
      navigate(clientPages.verifyPhone);
    } else if (!idVerified) {
      navigate(clientPages.editProfile);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.offsetHeight;
      liftHeight(height);
    }
  }, [phoneVerified, idVerified, containerRef?.current, liftHeight]);

  // const containerRef = useCallback((node) => {
  //   if (node !== null) {
  //     liftHeight(node.offsetHeight);
  //   }
  // }, []);

  return (
    <>
      {!phoneVerified || !idVerified ? (
        <div className={styles.warning} ref={containerRef}>
          <div className={styles["warning__top"]}>
            <p className={styles["warning-text"]}>
              Verify your Account to Go Live
            </p>
            <Icon icon={"bell-ring"} color={"orange"} width={24} height={24} />
          </div>
          <ul className={styles["warning__bottom"]}>
            <li>
              <span
                className={`${styles.icon} ${
                  phoneVerified ? styles.checked : ""
                }`}
              >
                {phoneVerified ? (
                  <Icon icon="check" color="white" width={8} height={8} />
                ) : (
                  ""
                )}
              </span>{" "}
              Add & Verify a phone number
            </li>
            <li>
              <span
                className={`${styles.icon} ${idVerified ? styles.checked : ""}`}
              >
                {idVerified ? (
                  <Icon icon="check" color="white" width={8} height={8} />
                ) : (
                  ""
                )}
              </span>{" "}
              Sumbit any one of 4 Identification Documents
            </li>
          </ul>
          <button
            className={styles["verify-phone"]}
            onClick={handleCompleteVerification}
          >
            Complete Verification
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default VerificationPrompt;
