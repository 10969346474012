import React, { useState } from "react";
import styles from "./ProductCardMini.module.scss";
import { BlurLoader, Dropdown, Icon, ProgressBar } from "../../../Atoms";
import { PRODUCT_CARD_MINI_MENU } from "./ProductCardMini.helpers";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { clientPages } from "../../../../Routes/routes";

const ProductCardMini = ({
  productImage,
  productTitle,
  price,
  inventoryAvailable,
  productId,
  handleDelete,
  top,
  showButton,
}) => {
  const [dropdown, setDropdown] = useState(false);

  const navigate = useNavigate();

  const handleMenuClick = (item) => {
    if (item.url === "product/edit/") {
      navigate(clientPages.updateProduct + "/" + productId);
    } else if (item.url === "archive") {
      handleDelete({
        productImage,
        productTitle,
        price,
        inventoryAvailable,
        productId,
      });
    } else if (item.url === "product/duplicate/") {
      navigate(clientPages.duplicateProduct + "/" + productId);
    }
  };

  return (
    <div className={styles["product-card"]}>
      <div className={styles.left}>
        <figure className={styles.figure}>
          <img
            src={productImage}
            alt="product"
            className={styles["left-image"]}
          />
        </figure>
        <span className={styles["left-details"]}>
          <label className={styles.label}>{productTitle}</label>
          <CurrencyFormat
            value={`NGN ${price}`}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </span>
      </div>
      <div className={styles.right}>
        <div className={styles["right-progress-container"]}>
          <ProgressBar progress={`${inventoryAvailable}%`} />
          {inventoryAvailable}% inventory available
        </div>
        {showButton ? (
          <button
            className={styles["right-menu-btn"]}
            onClick={() => setDropdown(!dropdown)}
          >
            <Icon icon={"menu-dots-vertical"} color={"shade-0"} />
            {dropdown ? (
              <Dropdown
                setDropdown={setDropdown}
                handleSelection={handleMenuClick}
                options={PRODUCT_CARD_MINI_MENU}
                size={177}
                right={0}
                left={"unset"}
                top={top || "-110px"}
              />
            ) : (
              ""
            )}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

ProductCardMini.defaultProps = {
  showButton: true,
};

export default ProductCardMini;
