import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import ProductCardMini from "../../Cards/Product/ProductCardMini/ProductCardMini";
import { Button, Toast } from "../../Atoms";
import { useRemoveAProductMutation } from "../../../redux/services/client/accounts";
import { useNavigate } from "react-router-dom";
import { clientPages } from "../../../Routes/routes";

const DeleteProduct = ({
  modalOpen,
  setModalOpen,
  inventoryAvailable,
  price,
  productId,
  productImage,
  productTitle,
}) => {
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const phoneScreenWidth = 364;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const { message, status, heading } = toastMessage;

  const navigate = useNavigate();

  const [
    deleteProduct,
    {
      isLoading,
      isSuccess: productDeleteSuccess,
      isError: productDeleteError,
      error,
    },
  ] = useRemoveAProductMutation();

  const handleDeleteProduct = async () => {
    await deleteProduct({
      id: productId,
    });
  };

  useEffect(() => {
    if (productDeleteError && error?.status === 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    }
    return () => {};
  }, [productDeleteError, error?.data, error?.status]);

  useEffect(() => {
    if (productDeleteSuccess) {
      setToastMessage({
        message: "Product deleted successfully",
        status: "success",
        heading: "Success",
      });
      setModalOpen(!modalOpen);
      if (window.location.pathname !== "/clients/dashboard") {
        navigate(clientPages.dashboard);
      }
    }

    if (productDeleteError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [productDeleteSuccess, productDeleteError, error?.data]);

  return (
    <>
      {productDeleteSuccess || productDeleteError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <Modal
        headerTitle={"Archive Product"}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ProductCardMini
          inventoryAvailable={inventoryAvailable}
          price={price}
          productId={productId}
          productImage={productImage}
          productTitle={productTitle}
          showButton={false}
        />
        <p className="modal-center-text">
          Are you sure you want to delete this product? This action cannot be
          undone. Please confirm your decision to proceed with deleting the
          product.
        </p>
        <div className="delete-product-btns">
          <Button
            label={"Cancel"}
            color={"grey"}
            size={!isPhoneScreen ? 101 : "36%"}
            height={36}
            handleClick={() => setModalOpen(!modalOpen)}
          />
          <Button
            label={isLoading ? "" : "Archive Product"}
            color={"error-opacity"}
            hasIcon
            iconPosition="right"
            icon={isLoading ? "spinner-alt" : ""}
            size={!isPhoneScreen ? 164 : "64%"}
            height={36}
            handleClick={handleDeleteProduct}
          />
        </div>
      </Modal>
    </>
  );
};

export default DeleteProduct;
