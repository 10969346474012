import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Select.module.scss";
import Card from "../../../Card/Card";
import Icon from "../../../Icons/Icon";
import DropdownMenu from "./DropdownMenu/DropdownMenu";
import DynamicSearch from "../../../DynamicSearch/DynamicSearch";

const Select = ({
  hasLabel,
  hasIcon,
  icon,
  label,
  enabled,
  iconColor,
  canSelectMultiple,
  options,
  selectedOption,
  classes,
  placeholder,
  searchPlaceholder,
  selectMenuType,
  selectedDynamicOption,
  maxWidth,
  dropdownTop,
  hasListIcon,
}) => {
  const [selected, setSelected] = useState(placeholder);
  const [dropdown, setDropdown] = useState(false);
  const handleSelection = (option) => {
    const newOption = options.find((o) => o.value === option);
    setSelected(newOption);
    selectedOption(newOption);
  };

  const handleDynamicSelection = (option) => {
    setSelected(option);
    selectedDynamicOption(option);
  };

  const usesIconComponentForIcon = hasIcon && icon;
  return (
    <div
      className={`${classes} space-ui-select-input`}
      style={{ maxWidth: maxWidth, cursor: !enabled ? "not-allowed" : "" }}
    >
      {hasLabel ? <label htmlFor="select-input">{label}</label> : ""}
      <Card
        className={styles["input-container"]}
        id="select-input"
        style={{
          marginTop: hasLabel ? "10px" : "",
          cursor: !enabled ? "not-allowed" : "",
        }}
      >
        <div
          className={styles["input-container__inner"]}
          onClick={() => enabled && setDropdown(!dropdown)}
          style={{
            cursor: !enabled ? "not-allowed" : "",
          }}
        >
          <p
            className={styles[enabled ? "enabled" : "placeholder"]}
            style={{
              display: hasIcon && "flex",
              gap: hasIcon && 6,
              alignItems: hasIcon && "center",
            }}
          >
            {usesIconComponentForIcon ? (
              <Icon icon={icon} color={iconColor} />
            ) : (
              ""
            )}
            {!usesIconComponentForIcon ? (
              <span
                className={styles["dropdown-icon-container"]}
                style={{
                  width: selected?.iconWidth,
                  height: selected?.iconHeight,
                  maxWidth: selected?.iconWidth,
                  maxHeight: selected?.iconHeight,
                  background: selected?.value,
                  borderRadius: selected?.iconWidth,
                }}
              ></span>
            ) : (
              ""
            )}
            {selected?.label || placeholder}
          </p>
          <Icon
            icon="chevronDown"
            color={enabled ? "shade-0" : "shade-100"}
            cursor={!enabled ? "not-allowed" : ""}
          />
        </div>
        {dropdown && selectMenuType === "regular" && (
          <DropdownMenu
            selected={selected}
            handleSelection={handleSelection}
            setSelected={setSelected}
            setDropdown={setDropdown}
            canSelectMultiple={canSelectMultiple}
            options={options}
            top={dropdownTop}
            hasIcon={hasListIcon}
          />
        )}

        {dropdown && selectMenuType !== "regular" && (
          <DynamicSearch
            menuOptions={options}
            setDropdown={setDropdown}
            dropdown={dropdown}
            placeholder={searchPlaceholder}
            setSelectedItem={setSelected}
            handleDynamicSelection={handleDynamicSelection}
          />
        )}
      </Card>
    </div>
  );
};

Select.defaultProps = {
  selectMenuType: "regular",
};

Select.propTypes = {
  hasLabel: PropTypes.bool,
  hasIcon: PropTypes.bool,
  selected: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  enabled: PropTypes.bool,
  Select: PropTypes.func,
};

export default Select;
