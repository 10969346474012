import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Icon } from "../../Atoms";
import styles from "./Toast.module.scss";

const Toast = ({ status, heading, message, position, portal }) => {
  const [showToast, setShowToast] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const icons = {
    success: "👍🏼",
    warning: "🤞🏼",
    error: "👎",
  };

  useEffect(() => {
    const activateTimer = setTimeout(() => {
      setShowToast(true);
    }, 1000);
    const activeTimer = setTimeout(() => {
      setIsActive(false);
    }, 5000);
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 7000);
    return () => {
      clearTimeout(activateTimer);
      clearTimeout(activeTimer);
      clearTimeout(timer);
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      {showToast ? (
        <div
          className={`${styles.toast} ${styles[status]} ${styles[position]} ${
            isActive ? styles.active : ""
          }`}
        >
          <div className={styles.icon}>{icons[status]}</div>
          <div className={styles.info}>
            <div className={styles.heading}>{heading}:</div>
            <div className={styles.message}>{message}</div>
          </div>
          {/* <Icon icon="cross-small" color="shade-100" /> */}
        </div>
      ) : (
        <></>
      )}
    </>,
    document.getElementById(portal) ||
      document.getElementById("dashboardBase") ||
      document.getElementById("portal")
  );
};

Toast.defaultProps = {
  status: "success",
  position: "top-right",
  heading: "Success",
  message: "Your task was created successfully",
};

export default Toast;
