import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import styles from "./_Main.module.scss";
import { BlurLoader, Icon, Toast } from "../../Components/Atoms";
import avatar from "../../assets/junk/user.jpg";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import {
  useGetAllProductsQuery,
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../redux/services/client/accounts";
import ProductCardMini from "../../Components/Cards/Product/ProductCardMini/ProductCardMini";
import DeleteProduct from "../../Components/Modals/DeleteProduct/DeleteProduct";
import { useSelector } from "react-redux";
import { clientPages } from "../../Routes/routes";
import placeholder from "../../assets/images/profileImage.png";
import html2canvas from "html2canvas";
import ShareImage from "../../Components/ShareImage/ShareImage";
// import BlurLoader from "../../Components/LazyLoader/BlurLoader";

const Dashboard = ({ todaySales, pendingBalance }) => {
  const [copiedLink, setCopiedLink] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageDataUrl, setImageDataUrl] = useState("");
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const handleDownloadShareImage = async () => {
    const element = document.getElementById("componentToExport");
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/jpeg", 1.0);
    const link = document.createElement("a");
    link.href = image;
    link.download = `${slug}-share.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const localTheme = useLocalStorage("theme")[0];
  const theme =
    localTheme !== null && localTheme !== undefined && localTheme !== ""
      ? localTheme
      : defaultDark
      ? "dark"
      : "light";
  const darkTheme = theme === "dark" ? "" : "difference";

  const { message, status, heading } = toastMessage;

  const client = useSelector((state) => state.client.value);

  const [
    updateProfile,
    {
      data,
      isSuccess: profileUpdateSuccess,
      isError: profileUpdateError,
      error,
    },
  ] = useUpdateProfileMutation();

  const [productToBeDeleted, setProductToBeDeleted] = useState({
    theproductImage: "",
    theproductTitle: "",
    theprice: "",
    theinventoryAvailable: "",
    theproductId: "",
  });

  const linkRef = useRef(null);

  const navigate = useNavigate();

  const { data: profileDetails } = useGetProfileQuery();

  const { data: productDetails } = useGetAllProductsQuery();

  const {
    legal_name: businessName,
    slug,
    logo,
    description,
    today_sales,
  } = profileDetails?.data?.business || {};

  const { available_balance, pending_balance } =
    profileDetails?.data?.business?.wallet || {};

  const { tiktok, instagram, facebook, whatsapp } =
    profileDetails?.data?.business?.social_link || {};

  const handleCopyLink = () => {
    if (client?.profileDetails?.business?.active !== false) {
      const text = linkRef?.current?.textContent;
      navigator.clipboard.writeText(text);
      setCopiedLink(true);

      setTimeout(() => {
        setCopiedLink(false);
      }, 3000);
    }
  };

  const handleArchiveProduct = ({
    productImage,
    productTitle,
    price,
    inventoryAvailable,
    productId,
  }) => {
    setModalOpen(!modalOpen);
    setProductToBeDeleted({
      theproductImage: productImage,
      theproductTitle: productTitle,
      theprice: price,
      theinventoryAvailable: inventoryAvailable,
      theproductId: productId,
    });
  };

  const handleWithdraw = () => {
    if (client?.profileDetails?.phone_verified === false) {
      navigate(clientPages.verifyPhone);
    } else if (
      client?.profileDetails?.identity_verification?.status !== "verified"
    ) {
      navigate(clientPages.editProfile);
    } else {
      navigate(clientPages.withdraw);
    }
  };

  const convertBase64 = (file, callback) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const base64 = fileReader.result.split(",")[1];
      callback(base64);
    };

    fileReader.readAsDataURL(file);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      convertBase64(file, async (base64) => {
        const newImageDataUrl = `data:${file.type};base64,${base64}`;
        setImageDataUrl(newImageDataUrl);
        await updateProfile({
          logo: newImageDataUrl,
        });
      });
    }
  };

  useEffect(() => {
    if (profileUpdateSuccess) {
      setImageDataUrl("");
      setToastMessage({
        message: "Profile updated successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (profileUpdateError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }
    return () => {};
  }, [profileUpdateSuccess, profileUpdateError, error?.data]);

  return (
    <>
      {profileUpdateSuccess || profileUpdateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <DeleteProduct
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        productImage={productToBeDeleted.theproductImage}
        inventoryAvailable={productToBeDeleted.theinventoryAvailable}
        price={productToBeDeleted.theprice}
        productTitle={productToBeDeleted.theproductTitle}
        productId={productToBeDeleted.theproductId}
      />
      <section className={styles.dashboard}>
        <div className={styles["dashboard-card"]}>
          {client?.profileDetails?.business?.live === true ? (
            <button className={styles["go-live-btn"]}>
              You're Live{" "}
              <Icon
                icon={"green-check-circle-opacity"}
                width={14}
                height={14}
                color={"white"}
              />
            </button>
          ) : (
            ""
          )}
          <div className={styles.topButtons}>
            <button
              className={styles["edit-btn"]}
              onClick={() => navigate(clientPages.editBusiness)}
            >
              <Icon icon={"edit-2"} width={16} height={16} />
            </button>
            <button
              className={styles["share-btn"]}
              onClick={() => handleDownloadShareImage()}
            >
              Share{" "}
              <Icon color={"white"} icon={"share"} width={16} height={16} />
            </button>
          </div>

          {logo?.url ? (
            <figure className={styles["avatar-container"]}>
              <img src={logo?.url} alt="business-logo" />
            </figure>
          ) : (
            <label
              htmlFor="logoAvatar"
              className={styles["avatar-container"]}
              style={{
                backgroundSize: imageDataUrl ? "cover" : "contain",
                backgroundImage: "url(" + (imageDataUrl || placeholder) + ")",
                cursor: !imageDataUrl ? "pointer" : "",
                mixBlendMode: darkTheme,
              }}
              s
            >
              <input
                id="logoAvatar"
                type="file"
                accept=".jpg, .jpeg, .png, .webm"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </label>
          )}
          <h1 className={styles.title} style={{ cursor: "pointer" }}>
            {businessName || "Your Business Name"}
          </h1>
          <div
            className={styles["awashop-link-container"]}
            style={{
              border:
                client?.profileDetails?.business?.active === false
                  ? "1px solid var(--warning)"
                  : "",
              cursor:
                client?.profileDetails?.business?.active === false
                  ? "not-allowed"
                  : "",
            }}
          >
            <p className={styles["awashop-link"]} ref={linkRef}>
              https://awashop.ng/<span className={styles.slug}>{slug}</span>
            </p>

            <button
              style={{
                border: "none",
                background: "transparent",
                position: "relative",
              }}
              onClick={() => {
                handleCopyLink();
              }}
            >
              {copiedLink && <span className="copy_float">Copied!</span>}
              <Icon
                icon={"copy-alt"}
                color={
                  client?.profileDetails?.business?.active === false
                    ? "shade-100"
                    : "shade-0"
                }
              />
            </button>
          </div>

          {client?.profileDetails?.business?.active === false ? (
            <span className={styles["unverified-prompt"]}>
              <Icon
                icon={"shield-exclamation"}
                width={8}
                height={8}
                color={"orange"}
              />
              your shop link is inactive until verification is complete
            </span>
          ) : (
            <span
              className={styles["unverified-prompt"]}
              style={{ color: "var(--shade-0)" }}
            >
              <Icon
                icon={"shield-exclamation"}
                width={8}
                height={8}
                color={"awa-green"}
              />
              your shop link is active
            </span>
          )}

          {description ? (
            <h6 className={styles.subtitle}>{description}</h6>
          ) : (
            <button
              className={styles["add-descriprion-btn"]}
              onClick={() => navigate(clientPages.editBusiness)}
            >
              <Icon icon={"plus"} color={"shade-100"} width={14} height={14} />
              Add a Description
            </button>
          )}
          <div className={styles.sales}>
            <div className={styles["sales__tab"]}>
              <span className={styles.left}>
                {/* {`₦${today_sales}` || "Today's Sales"} */}
                <CurrencyFormat
                  value={today_sales}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                <label className={styles.label}>Today’s Sales</label>
              </span>
              <button className={styles["sales__tab-btn"]}>
                <Icon icon={"arrow-up"} color={"success"} />
              </button>
            </div>
            <div className={styles["sales__tab"]}>
              <span className={styles.left}>
                {/* {`₦${available_balance}` || "Pending Balance"} */}
                <CurrencyFormat
                  value={available_balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                <label className={styles.label}>
                  <CurrencyFormat
                    value={pending_balance}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />{" "}
                  pending balance
                </label>
              </span>
              <button
                className={styles["sales__tab-btn-right"]}
                onClick={handleWithdraw}
              >
                withdraw
              </button>
            </div>
          </div>
          <figure className={styles.icons}>
            <Link
              to={tiktok ? `https://${tiktok}` : "#"}
              className={styles["icons__btns"]}
            >
              <Icon
                icon={"tiktok"}
                width={24}
                height={24}
                activeLinks={tiktok ? true : false}
              />
            </Link>

            <Link
              to={instagram ? `https://${instagram}` : "#"}
              className={styles["icons__btns"]}
              target="_blank"
            >
              <Icon
                icon={"instagram"}
                width={24}
                height={24}
                activeLinks={instagram ? true : false}
              />
            </Link>

            <Link
              to={facebook ? `https://${facebook}` : "#"}
              className={styles["icons__btns"]}
              target="_blank"
            >
              <Icon
                icon={"facebook"}
                width={24}
                height={24}
                activeLinks={facebook ? true : false}
              />
            </Link>

            <Link
              to={whatsapp ? `https://${whatsapp}` : "#"}
              className={styles["icons__btns"]}
              target="_blank"
            >
              <Icon
                icon={"whatsapp"}
                width={24}
                height={24}
                activeLinks={whatsapp ? true : false}
              />
            </Link>
            <button
              className={styles["edit-btn"]}
              onClick={() => navigate(clientPages.links)}
            >
              <Icon icon={"edit-2"} width={16} height={16} />
            </button>
          </figure>
          <div className={styles["add-product"]}>
            <h6 className={styles["products-header"]}>Products</h6>
            <button
              className={styles["product-btn__plus"]}
              onClick={() => navigate(clientPages.addProduct)}
            >
              <Icon icon={"plus-small"} color={"white"} />
            </button>
          </div>
          <div
            className={styles["mini-product-container"]}
            style={{
              overflow: productDetails?.data?.length < 2 ? "visible" : "",
            }}
          >
            {productDetails?.data?.map((product, index) => (
              <ProductCardMini
                key={product?.id}
                productImage={product?.image.url}
                inventoryAvailable={product?.percentage_available}
                price={product?.price || "0"}
                productTitle={product?.title}
                productId={product?.id}
                handleDelete={handleArchiveProduct}
                top={
                  productDetails?.data?.length > 1 && index === 0 ? "10px" : ""
                }
              />
            ))}
          </div>

          <button
            className={styles["product-btn"]}
            onClick={() => navigate(clientPages.addProduct)}
          >
            <span className={styles["product-btn__plus"]}>
              <Icon icon={"plus-small"} color={"white"} />
            </span>
            {productDetails?.data?.length < 1
              ? "Add your first product"
              : "Add a new product"}
          </button>
        </div>
      </section>
      <div
        style={{ position: "absolute", top: -9999, left: -9999 }}
        id="componentToExport"
      >
        <ShareImage logo={logo?.url} slug={slug} businessName={businessName} />
      </div>
    </>
  );
};

export default Dashboard;
