import React from "react";
import styles from "./_Main.module.scss";
import CartHeader from "../../Components/Headers/Cart/CartHeader";

const CheckoutScreen = () => {
  return (
    <section className={styles["checkout-screen"]}>
      <div className={styles["checkout-screen__inner"]}>
        <header className={styles["header"]}>
          Shopping Cart
          <CartHeader />
        </header>
      </div>
    </section>
  );
};

export default CheckoutScreen;
