import React, { useEffect, useRef, useState } from "react";
import {
  useIdentityVerificationMutation,
  useUpdateProfileMutation,
} from "../../../redux/services/client/accounts";
import { useSelector } from "react-redux";
import {
  Button,
  Icon,
  Loader,
  ProgressBar,
  Toast,
} from "../../../Components/Atoms";
import Form from "../../../Components/Bases/Form/Form";
import {
  PhoneInput,
  SelectInput,
  TextInput,
} from "../../../Components/Atoms/Input/Input";
import styles from "../Edit/_Main.module.scss";
import { GENDER, VERIFICATION_METHODS } from "../Edit/EditProfile.helpers";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router";
import AnimatedSVG from "../../../Components/Atoms/ProgressBar/CircularProgress";

const EditProfile = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const [isIdNumberValid, setIsIdNumberValid] = useState(true);
  const [isIdNumberNigerian, setIsIdNumberNigerian] = useState(true);
  const [isProfileDataChanged, setIsProfileDataChanged] = useState(false);
  const [idError, setIdError] = useState(false);

  const numbers = [
    "0701",
    "07020",
    "07025",
    "07026",
    "07027",
    "07028",
    "07029",
    "0703",
    "0704",
    "0705",
    "0706",
    "0707",
    "0708",
    "0709",
    "0802",
    "0803",
    "0804",
    "0805",
    "0806",
    "0807",
    "0808",
    "0809",
    "0810",
    "0811",
    "0812",
    "0813",
    "0814",
    "0815",
    "0816",
    "0817",
    "0818",
    "0819",
    "0909",
    "0908",
    "0901",
    "0902",
    "0903",
    "0904",
    "0905",
    "0906",
    "0907",
    "0915",
    "0913",
    "0912",
    "0916",
    "0911",
  ];

  const backdropRef = useRef(null);

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const navigate = useNavigate();

  const [
    updateProfile,
    {
      isLoading,
      isSuccess: profileUpdateSuccess,
      isError: profileUpdateError,
      error,
    },
  ] = useUpdateProfileMutation();

  const [
    verifyIdentity,
    {
      isLoading: identityVerificationFetching,
      isSuccess: identityVerificationSuccess,
      isError: identityVerificationError,
      error: verifyError,
    },
  ] = useIdentityVerificationMutation();

  const client = useSelector((state) => state.client.value);

  const { message, status, heading } = toastMessage;

  const {
    first_name,
    last_name,
    phone_number,
    email,
    identity_verification: {
      status: verificationStatus,
      verification_type,
      id_number,
      first_name: userFirstName,
      last_name: userLastName,
    } = {},
  } = client?.profileDetails || {};

  const [formData, setFormData] = useState({
    firstName: first_name,
    lastName: last_name,
    emailAddress: email,
    phoneNumber: phone_number,
    verificationMethod: verification_type || "",
    idNumber: id_number || "",
  });

  useEffect(() => {
    setFormData({
      firstName: first_name,
      lastName: last_name,
      emailAddress: email,
      phoneNumber: phone_number,
      verificationMethod: verification_type || "",
      idNumber: id_number || "",
    });

    return () => {};
  }, [client?.profileDetails]);

  useEffect(() => {
    let intervalId;
    if (modalOpen) {
      intervalId = setInterval(() => {
        const newProgress = (progress + 5) % 100;
        setProgress(newProgress);

        if (newProgress >= 95) {
          setModalOpen(false);
          setProgress(0);
          clearInterval(intervalId);
        }
      }, 100);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [modalOpen, progress]);

  const handleInputChange = (field, value) => {
    const firstFourDigits = value.slice(0, 4);
    if (idError) {
      setIdError(false);
    }
    setFormData({
      ...formData,
      [field]: value,
    });
    if (field === "idNumber") {
      if (formData?.verificationMethod === "nin_with_phone") {
        if (!numbers.includes(firstFourDigits)) {
          setIsIdNumberNigerian(false);
        } else {
          if (!isIdNumberNigerian) {
            setIsIdNumberNigerian(true);
          }
          validateIdNumber(value);
        }
      }
    }

    setIsProfileDataChanged(true);
  };

  // console.log(isIdNumberNigerian);

  const getMaxLengthForVerificationMethod = (verificationMethod) => {
    const verificationMethodsMaxLength = {
      nin_with_phone: 11,
      nin: 11,
      passport: 9,
      drivers_license: 12,
    };

    return verificationMethodsMaxLength[verificationMethod] || undefined;
  };

  const validateIdNumber = (value) => {
    const method = formData?.verificationMethod;
    const length = value.trim().length;

    const verificationMethods = {
      nin_with_phone: 11,
      nin: 11,
      passport: 9,
      drivers_license: 12,
    };

    if (method && verificationMethods[method] !== length) {
      setIsIdNumberValid(false);
    } else {
      setIsIdNumberValid(true);
    }
  };

  const validateDriversLicense = (license) => {
    const driversLicense = /^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$/i;
    return driversLicense.test(license);
  };

  const getVerificationLabel = (verificationType) => {
    const verificationMethod = VERIFICATION_METHODS.find(
      (method) => method.value === verificationType
    );
    return verificationMethod ? verificationMethod.label : "";
  };

  const handleUpdateProfile = async () => {
    const dynamicProfileData = {};

    if (formData.firstName !== first_name) {
      dynamicProfileData.first_name = formData.firstName;
    }

    if (formData.lastName !== last_name) {
      dynamicProfileData.last_name = formData.lastName;
    }

    if (formData.phoneNumber !== phone_number) {
      dynamicProfileData.phone_number = formData.phoneNumber;
    }

    if (formData.emailAddress !== email) {
      dynamicProfileData.email = formData.emailAddress;
    }

    if (Object.keys(dynamicProfileData).length > 0) {
      await updateProfile(dynamicProfileData);
    }
  };

  const handleVerify = async () => {
    if (isIdNumberValid) {
      const verificationMethods = {
        nin_with_phone: 11,
        nin: 11,
        passport: 9,
        drivers_license: 12,
      };

      const method = formData?.verificationMethod;
      const length = formData?.idNumber.trim().length;

      if (method && verificationMethods[method] === length) {
        if (
          method === "drivers_license" &&
          validateDriversLicense(formData?.idNumber)
        ) {
          await verifyIdentity({
            first_name: formData?.firstName,
            last_name: formData?.lastName,
            verification_type: formData?.verificationMethod,
            id_number: formData?.idNumber,
          });
        } else if (method !== "drivers_license") {
          await verifyIdentity({
            first_name: formData?.firstName,
            last_name: formData?.lastName,
            verification_type: formData?.verificationMethod,
            id_number: formData?.idNumber,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (identityVerificationSuccess) {
      setModalOpen(true);
    }

    if (verificationStatus === "verified" && !isVerified) {
      setIsVerified(true);
    }

    return () => {};
  }, [identityVerificationSuccess, isVerified, client?.profileDetails]);

  useEffect(() => {
    if (identityVerificationSuccess && !isVerified) {
      setIsVerified(true);
    }

    if (identityVerificationError) {
      setModalOpen(true);
      setIdError(true);
    }
    return () => {};
  }, [identityVerificationSuccess, isVerified, identityVerificationError]);

  useEffect(() => {
    if (profileUpdateSuccess) {
      setToastMessage({
        message: "Profile updated successfully",
        status: "success",
        heading: "Success",
      });
      if (isProfileDataChanged) {
        setIsProfileDataChanged(false);
      }
    }

    if (profileUpdateError && error?.status === 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (profileUpdateError && error?.status !== 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [profileUpdateSuccess, profileUpdateError, error?.data, error?.status]);

  useEffect(() => {
    if (client?.profileDetails !== (null || undefined)) {
      if (
        formData.firstName !== first_name ||
        formData.lastName !== last_name ||
        formData.phoneNumber !== phone_number ||
        formData.emailAddress !== email
      ) {
        if (!isProfileDataChanged) {
          setIsProfileDataChanged(true);
        }
      }
    }

    return () => {};
  }, [formData]);

  // console.log(
  //   "phone number is",
  //   formData?.phoneNumber?.slice(4).replace(/\s/g, "")
  // );
  return (
    <>
      {profileUpdateSuccess || profileUpdateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="bottom-right"
        />
      ) : (
        ""
      )}
      {identityVerificationSuccess && modalOpen ? (
        <div className="verify-backdrop" ref={backdropRef}>
          <div className={styles["verifying-id-container"]}>
            <div className="circle-wrap">
              <div
                className="circle"
                // style={{
                //   transform: `rotate(${rotation}deg)`,
                // }}
              >
                <div
                  className={`mask full-1 ${
                    progress === 100 ? "completed" : ""
                  }`}
                  style={{
                    backgroundColor: progress >= 50 ? "var(--awa-green)" : "",
                  }}
                >
                  <div
                    className="fill-1"
                    style={{
                      backgroundColor: progress >= 50 ? "var(--awa-green)" : "",
                    }}
                  ></div>
                </div>
                <div
                  className="mask half"
                  style={{
                    backgroundColor: progress >= 50 ? "var(--awa-green)" : "",
                  }}
                >
                  <div
                    className="fill-1"
                    style={{
                      backgroundColor: progress >= 50 ? "var(--awa-green)" : "",
                    }}
                  ></div>
                </div>
                <div className="inside-circle" style={{ position: "relative" }}>
                  <Icon
                    icon={"comment-user"}
                    color={progress >= 50 ? "awa-green" : "shade-100"}
                    width={54}
                    height={54}
                  />
                </div>
                {/* <div className="inside-circle">{`${progress}%`}</div> */}
              </div>
            </div>

            <h6 className={styles["verifying-id-container__header"]}>
              {progress >= 50 ? "Verified" : "Verifying ID..."}
            </h6>
            <ProgressBar progress={`${progress}%`} />
            <p className={styles.progress}>{progress}% complete</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {identityVerificationError && modalOpen ? (
        <div className="verify-backdrop" ref={backdropRef}>
          <div className={styles["verifying-id-container"]}>
            <div className="circle-wrap">
              <div
                className="circle"
                // style={{
                //   transform: `rotate(${rotation}deg)`,
                // }}
              >
                <div
                  className={`mask full-1 ${
                    progress === 100 ? "completed" : ""
                  }`}
                  style={{
                    backgroundColor: progress >= 50 ? "var(--error)" : "",
                  }}
                >
                  <div
                    className="fill-1"
                    style={{
                      backgroundColor: progress >= 50 ? "var(--error)" : "",
                    }}
                  ></div>
                </div>
                <div
                  className="mask half"
                  style={{
                    backgroundColor: progress >= 50 ? "var(--error)" : "",
                  }}
                >
                  <div
                    className="fill-1"
                    style={{
                      backgroundColor: progress >= 50 ? "var(--error)" : "",
                    }}
                  ></div>
                </div>
                <div className="inside-circle" style={{ position: "relative" }}>
                  <Icon
                    icon={"comment-user"}
                    color={progress >= 50 ? "error" : "shade-100"}
                    width={54}
                    height={54}
                  />
                </div>
                {/* <div className="inside-circle">{`${progress}%`}</div> */}
              </div>
            </div>

            <h6 className={styles["verifying-id-container__header"]}>
              {progress >= 50 ? "Verification Error" : "Verifying ID..."}
            </h6>
            <ProgressBar progress={`${progress}%`} />
            <p className={styles.progress}>{progress}% complete</p>
          </div>
        </div>
      ) : (
        ""
      )}

      <section className={styles["edit-business"]}>
        <Form headerTitle={"Personal Information"} hasTitle={false} size={549}>
          <div
            className={styles["verification-container"]}
            style={{
              background: !isVerified ? "rgba(247, 158, 27, 0.05)" : "",
            }}
          >
            <div className={styles["verification-container__top"]}>
              <span className={styles.identity}>
                <Icon
                  icon={"comment-user"}
                  color={isVerified ? "awa-green" : "orange"}
                  width={24}
                  height={24}
                />
                Identity Verification
              </span>
              {/* {!isVerified && !identityVerificationFetching && noError ? (
                <button className={styles["verify_btn"]} onClick={handleVerify}>
                  Verify
                </button>
              ) : (
                ""
              )}{" "} */}
              {/* {identityVerificationFetching && (
                <Loader size={20} absolute={false} />
              )} */}
              {idError && !identityVerificationFetching ? (
                <p className={styles["error-msg"]}>Error verifying identity</p>
              ) : (
                ""
              )}
              {isVerified && (
                <span className={styles.id}>
                  <Icon icon={"green-check-circle"} />
                  ID verified
                </span>
              )}
            </div>
            <div className="half-width-form">
              <TextInput
                hasLabel
                label={"First Name"}
                value={formData?.firstName}
                onChange={(e) =>
                  handleInputChange("firstName", e.target.value.trim())
                }
                disabled={isVerified}
                hasIcon={isVerified}
                iconPosition="right"
                iconColor="white"
                icon="lock"
                outline={idError}
              />
              <TextInput
                hasLabel
                label={"Last Name"}
                value={formData.lastName}
                onChange={(e) =>
                  handleInputChange("lastName", e.target.value.trim())
                }
                disabled={isVerified}
                hasIcon={isVerified}
                iconPosition="right"
                iconColor="white"
                icon="lock"
                outline={idError}
              />
            </div>
            <div className="half-width-form">
              <SelectInput
                hasLabel
                label={"Verification Method"}
                placeholder={
                  verification_type
                    ? getVerificationLabel(verification_type)
                    : "Select"
                }
                option={VERIFICATION_METHODS}
                enabled={
                  verificationStatus !== "verified" && !isVerified
                    ? true
                    : false
                }
                selectedOption={(item) =>
                  handleInputChange("verificationMethod", item.value)
                }
                dropdownTop={"-210px"}
              />
              <TextInput
                hasLabel
                label="ID Number"
                placeholder="12354879611"
                hasIcon={verificationStatus === "verified" && isVerified}
                iconPosition="right"
                icon={
                  verificationStatus === "verified" && isVerified ? "lock" : ""
                }
                iconColor={"shade-0"}
                value={formData?.idNumber}
                onChange={(e) =>
                  handleInputChange("idNumber", e.target.value.trim())
                }
                outline={
                  !isIdNumberValid ||
                  (formData?.verificationMethod === "drivers_license" &&
                    formData?.idNumber.trim().length > 3 &&
                    !validateDriversLicense(formData?.idNumber)) ||
                  idError
                }
                disabled={verificationStatus === "verified" && isVerified}
                maxLength={
                  !isIdNumberNigerian && !numbers.includes(formData?.idNumber)
                    ? 4
                    : getMaxLengthForVerificationMethod(
                        formData?.verificationMethod
                      ) || undefined
                }
              />
            </div>
            {!isVerified ? (
              <Button
                color={!isIdNumberValid ? "disabled" : "orange"}
                height={36}
                handleClick={handleVerify}
                label={identityVerificationFetching ? "" : "Verify Details"}
                hasIcon
                iconPosition="right"
                icon={identityVerificationFetching ? "spinner-alt" : ""}
              />
            ) : (
              ""
            )}
          </div>
          <div className="half-width-form">
            <PhoneInput
              hasLabel
              label={"Phone Number"}
              placeholder=""
              value={formData?.phoneNumber?.slice(4).replace(/\s/g, "")}
              hasIcon
              icon={"lock"}
              disabled
            />
            <TextInput
              hasLabel
              label={"Email Address"}
              value={formData.emailAddress}
              onChange={(e) =>
                handleInputChange("emailAddress", e.target.value.trim())
              }
              hasIcon
              iconPosition="right"
              icon={"lock"}
              iconColor={"shade-0"}
              disabled
            />
          </div>
          {/* <Button
            color={isProfileDataChanged ? "completed" : "disabled"}
            label={isLoading ? "" : "Update Profile"}
            hasIcon
            iconPosition="right"
            icon={isLoading ? "spinner-alt" : ""}
            handleClick={handleUpdateProfile}
            height={36}
          /> */}
        </Form>
      </section>
    </>
  );
};

export default EditProfile;
