import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./PersonalizationOption.module.scss";
import Card from "../../../Card/Card";
import Icon from "../../../Icons/Icon";

const PersonalizationOption = ({
  option,
  hasIcon,
  name,
  background,
  bill,
  disabled,
  value,
  onChange,
}) => {
  const [deleted, setDeleted] = useState(false);

  const handleDelete = () => {
    setDeleted(true);
  };

  return (
    <Card
      className={styles.personalizationOption}
      style={{ display: deleted ? "none" : "", background: background }}
    >
      <div>
        <button className={styles.button}>
          <input
            type="number"
            name={name}
            value={value || "01"}
            onChange={onChange}
            disabled={disabled}
          />
        </button>
        <p>{option}</p>
      </div>
      {hasIcon ? (
        <Icon icon="trash" color="error" onClick={handleDelete} />
      ) : (
        <p className={styles.bill}>{bill}</p>
      )}
    </Card>
  );
};

PersonalizationOption.defaultProps = {
  hasIcon: true,
};

PersonalizationOption.propTypes = {
  option: PropTypes.string.isRequired,
  hasIcon: PropTypes.bool,
};

export default PersonalizationOption;
