import { useState, useEffect } from "react";
import { useStartQuery } from "../redux/services/client/onboarding";

export function useClientStartLogic(client, setNextStep) {
  const [isReadyForStart, setIsReadyForStart] = useState(false);

  const {
    data: clientData,
    isSuccess: clientCreationStatus,
    isError: clientCreationError,
    isLoading,
  } = useStartQuery(
    {
      email: client.email_address,
      first_name: client.first_name,
      last_name: client.last_name,
      reference: client.reference,
    },
    { skip: !isReadyForStart }
  );

  if (clientCreationError) {
    setIsReadyForStart(false);
  }

  if (clientCreationStatus) {
    localStorage.setItem("authToken", clientData.data.auth);
    setNextStep(2);
  }

  return {
    isReadyForStart,
    setIsReadyForStart,
    isLoading,
    clientCreationError,
    clientCreationStatus,
  };
}
