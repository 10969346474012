import React, { useState, useReducer, useRef, useEffect } from "react";
import Form from "./Form";
import Base from "../Base/Base";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import styles from "./Form.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { updateClientData } from "../../../redux/features/newClient";
import { useSetPasswordQuery } from "../../../redux/services/client/onboarding";
import { Icon, Toast } from "../../../Components/Atoms";

export const initialState = {
  requirements: {
    length: false,
    containsNumber: false,
    containsLowercase: false,
    containsUppercase: false,
    containsSymbol: false,
  },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_REQUIREMENT":
      return {
        ...state,
        requirements: {
          ...state.requirements,
          [action.requirement]: !state.requirements[action.requirement],
        },
      };
    case "SET_REQUIREMENTS":
      return {
        ...state,
        requirements: action.requirements,
      };
    default:
      return state;
  }
};

const StepFour = ({ setNextStep }) => {
  const [visible, setVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const [state, dispatch] = useReducer(reducer, initialState);
  const passwordRef = useRef();

  const [isReadyForNextStep, setIsReadyForNextStep] = useState(false);
  const client = useSelector((state) => state.client.value);
  const clientDispatch = useDispatch();

  if (client.isInvited) {
    setNextStep(5);
  }

  const {
    data: verifiedPassword,
    isSuccess,
    isError,
    isFetching,
    error,
  } = useSetPasswordQuery(
    { password: client.password.trim() },
    { skip: !client.hasValidPassword }
  );

  useEffect(() => {
    if (isSuccess) {
      if (verifiedPassword.status === "success") {
        localStorage.setItem("authToken", verifiedPassword.data.auth);
        setNextStep(4);
      } else {
        clientDispatch(
          updateClientData({
            hasValidPassword: false,
          })
        );
      }
    }
    if (isError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isSuccess, isError, error?.data]);

  const handlePasswordInput = (e) => {
    setIsReadyForNextStep(false);
    const { value } = e.target;
    const password = value.trim();
    const requirements = {
      length: password.length >= 8,
      containsNumber: /\d/.test(password),
      containsLowercase: /[a-z]/.test(password),
      containsUppercase: /[A-Z]/.test(password),
      containsSymbol: /[-!@#$%^&*()_+]/.test(password),
    };

    dispatch({ type: "SET_REQUIREMENTS", requirements });
  };

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!@#$%^&*()_+])[a-zA-Z\d-!@#$%^&*()_+]{8,}$/;

    return passwordPattern.test(password);
  };

  const handleIconClick = () => {
    const icon = document.getElementById("onboardingPassword");
    icon.focus();
    if (icon.type === "text") {
      icon.type = "password";
      setVisible(!visible);
    } else if (icon.type === "password") {
      icon.type = "text";
      setVisible(!visible);
    }
    const valueLength = icon.value.length;
    icon.setSelectionRange(valueLength, valueLength);
  };

  const handleButtonClick = () => {
    setIsReadyForNextStep(true);
    if (validatePassword(passwordRef?.current?.getValue())) {
      clientDispatch(
        updateClientData({
          password: passwordRef?.current?.getValue(),
          hasValidPassword: validatePassword(passwordRef?.current?.getValue()),
        })
      );
    } else {
      passwordRef?.current?.focus();
      clientDispatch(
        updateClientData({
          hasValidPassword: false,
        })
      );
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleButtonClick();
    }
  };

  const icon =
    isReadyForNextStep && !client.hasValidPassword
      ? "cross-small"
      : visible
      ? "eye"
      : "eye-crossed";

  return (
    <>
      {isError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <Base
        classes={styles["steps-form"]}
        onBackToLastStep={() => setNextStep(2)}
      >
        <div className={styles.step}>
          <span>
            <strong>Step 4 </strong>
          </span>
          <span>/ 6</span>
        </div>
        <Form
          buttonLabel={isFetching ? "" : "Continue"}
          setNextStep={setNextStep}
          handleButtonClick={handleButtonClick}
          onKeyPress={handleKeyPress}
          icon={isFetching ? "spinner-alt" : ""}
        >
          <div className={styles.inner}>
            <label className={styles.title}>Account Security</label>
            <span className={styles["info"]}>create a secure password</span>
            <TextInput
              ref={passwordRef}
              hasIcon={true}
              icon={icon}
              iconPosition="right"
              iconColor={
                isReadyForNextStep && !client.hasValidPassword
                  ? "error"
                  : "shade-100"
              }
              type="password"
              id="onboardingPassword"
              placeholder="create a secure password"
              onInput={handlePasswordInput}
              onIconClick={handleIconClick}
              outline={
                isReadyForNextStep && !client.hasValidPassword ? true : false
              }
            />
            <ul>
              <li>
                <span
                  // onClick={() =>
                  //   dispatch({
                  //     type: "TOGGLE_REQUIREMENT",
                  //     requirement: "length",
                  //   })
                  // }
                  className={`${styles.icon} ${
                    state.requirements.length ? styles.checked : ""
                  }`}
                >
                  {state.requirements.length ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must be at least 8 characters
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsNumber ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsNumber ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain one number
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsLowercase ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsLowercase ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain at least one lowercase.
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsUppercase ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsUppercase ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain at least one uppercase.
              </li>
              <li>
                <span
                  className={`${styles.icon} ${
                    state.requirements.containsSymbol ? styles.checked : ""
                  }`}
                >
                  {state.requirements.containsSymbol ? (
                    <Icon icon="check" color="success" width={8} height={8} />
                  ) : (
                    ""
                  )}
                </span>{" "}
                must contain at least one symbol ‒!@#$％＾&()＿+‒
              </li>
            </ul>
          </div>
        </Form>
      </Base>
    </>
  );
};

export default StepFour;
