import React, { useState } from "react";
import styles from "./VariantCard.module.scss";
import { Dropdown, Icon } from "../../Atoms";
import CurrencyFormat from "react-currency-format";
import thumbnail from "../../../assets/images/Group 28.png";

const VariantCard = ({
  productsAvailable,
  productColor,
  productPrice,
  productSize,
  productImage,
  onClick,
  top,
  variantInfo,
  productVolume,
  productDescription,
  index,
  color,
  colorName,
  id,
  display,
  handleDelete,
  isDuplicatePageandEmpty,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const VARIANT_CARD_MENU = [
    {
      label: "Edit",
      value: "edit",
      icon: "edit",
      iconColor: "shade-100",
    },
    {
      label: "Remove this variant",
      value: "archive",
      icon: "trash",
      iconColor: "shade-100",
    },
  ];

  const VARIANT_CARD_MENU_TEMP = [
    {
      label: "Edit",
      value: "edit",
      icon: "edit",
      iconColor: "shade-100",
    },
  ];

  const handleMenuClick = (item) => {
    if (item.value === "edit") {
      variantInfo({
        productsAvailable,
        color,
        productPrice,
        productSize,
        productImage,
        productDescription,
        productVolume,
        index,
        colorName,
        id,
      });
    } else if (item.value === "archive") {
      handleDelete({ index });
    }
  };

  const noImage = isDuplicatePageandEmpty === "valid" || !productImage;

  // console.log("tt", productImage);

  return (
    <div
      className={styles["variant-card"]}
      onClick={onClick}
      style={{ display: display }}
    >
      <div className={styles.left}>
        <figure
          className={styles.figure}
          style={{
            background: noImage && "var(--shade-100)",
            borderRadius: noImage && 9,
          }}
        >
          {productImage ||
          (isDuplicatePageandEmpty && isDuplicatePageandEmpty !== "valid") ? (
            <img
              src={productImage}
              alt="product"
              className={styles["left-image"]}
            />
          ) : (
            <img
              src={thumbnail}
              alt="product"
              className={styles["left-image"]}
            />
          )}
        </figure>
        <span className={styles["left-details"]}>
          <h6 className={styles["products-available"]}>
            {productsAvailable} items
          </h6>
          <span
            className={styles["product-color"]}
            style={{
              background: productColor?.value,
            }}
          ></span>
        </span>
      </div>
      <div className={styles.right}>
        <div className={styles["right-price-container"]}>
          <p className={styles.label}>
            {"  "}
            <CurrencyFormat
              value={productPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦ "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </p>
          <div className={styles["size-container"]}>
            {productVolume && (
              <>
                <span>{productVolume}</span>
              </>
            )}
            {productSize && (
              <>
                {/* size: */}
                <span>{productSize}</span>
              </>
            )}
          </div>
        </div>
        <button
          className={styles["right-menu-btn"]}
          onClick={() => setDropdown(!dropdown)}
        >
          <Icon icon={"menu-dots-vertical"} color={"shade-0"} />
          {dropdown ? (
            <Dropdown
              setDropdown={setDropdown}
              handleSelection={handleMenuClick}
              options={
                handleDelete ? VARIANT_CARD_MENU : VARIANT_CARD_MENU_TEMP
              }
              size={177}
              right={0}
              left={"unset"}
              top={top || "-250%"}
            />
          ) : (
            ""
          )}
        </button>
      </div>
    </div>
  );
};

export default VariantCard;
