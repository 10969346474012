import React from "react";
import styles from "./EmptyState.module.scss";
import Icon from "../Icons/Icon";
import Button from "../Button/Button";

const EmptyState = ({
  banner,
  title,
  subtitle,
  width,
  height,
  hasImage,
  hasButton,
  hasIcon,
  buttonLabel,
  subtitleWidth,
  onButtonClick,
}) => {
  return (
    <div className={styles["empty-state"]}>
      {hasImage ? <Icon icon={"error-icon"} width={100} height={100} /> : ""}
      <div className={styles["empty-state__lower"]}>
        <h6 className={styles.title}>{title}</h6>
        <p className={styles.subtitle} style={{ width: subtitleWidth }}>
          {subtitle}
        </p>
      </div>
      {hasButton ? (
        <div className={styles["empty-state-btn"]}>
          <Button
            icon="add"
            hasIcon={hasIcon}
            iconPosition="left"
            color="completed"
            label={buttonLabel}
            handleClick={onButtonClick}
            size={"fit-content"}
            height={45}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

EmptyState.defaultProps = {
  hasImage: true,
  hasButton: false,
};

export default EmptyState;
