import React, { useEffect, useRef } from "react";
import Form from "./Form";
import Base from "../Base/Base";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import styles from "./Form.module.scss";
import { Link } from "react-router-dom";
import { clientPages } from "../../../Routes/routes";
import { useStepOneLogic } from "./Helpers/stepOne.helper";

const StepOne = ({ setNextStep }) => {
  const nameRef = useRef();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const companyStatus = localStorage.getItem("AwashopCompanyStatus");

    if (authToken !== null && authToken !== undefined) {
      switch (companyStatus) {
        case "awaiting_email_verification":
          setNextStep(2);
          break;
        case "awaiting_password":
          setNextStep(3);
          break;
        case "awaiting_organization_data":
          setNextStep(4);
          break;
        default:
          break;
      }
    }

    return () => {};
  }, []);

  const {
    handleTextInput,
    handleKeyPress,
    handleButtonClick,
    isLoading,
    isTyping,
  } = useStepOneLogic(setNextStep, nameRef);

  return (
    <Base classes={styles["steps-form"]} stepsPage={false}>
      <div className={styles.step}>
        <span>
          <strong>Step 1 </strong>
        </span>
        <span>/ 6</span>
      </div>
      <Form
        buttonLabel={isLoading ? "" : "Continue"}
        setNextStep={setNextStep}
        handleButtonClick={handleButtonClick}
        onKeyPress={handleKeyPress}
        icon={isLoading ? "spinner-alt" : ""}
      >
        <div className={styles.inner}>
          <label className={styles.title}>Full Name</label>
          <TextInput
            placeholder="e.g. Elon Musk"
            onInput={handleTextInput}
            ref={nameRef}
            outline={isTyping}
          />
          {isTyping ? (
            <p className="error-message-prompt">Two names required</p>
          ) : (
            ""
          )}
        </div>
      </Form>
      <div className={styles.login}>
        <span>
          Already have an account?{" "}
          <Link to={clientPages.login} className={styles["login-btn"]}>
            Login
          </Link>{" "}
          Instead
        </span>
      </div>
    </Base>
  );
};

export default React.memo(StepOne);
