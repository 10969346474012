import React from "react";
import styles from "./_Main.module.scss";

const ShoppingCart = () => {
  return (
    <div className={styles["shopping-cart"]}>
      <main className={styles.main}>
        <div className={styles["main-top"]}>
          <h6 className={styles.title}>Enter Delivery Details</h6>
        </div>
      </main>
    </div>
  );
};

export default ShoppingCart;
