import React, { useState } from "react";
import styles from "./OrdersCardMini.module.scss";
import { AvatarChain, Dropdown, Icon } from "../../../Atoms";
import { PENDING_TASKS } from "../../Product/helpers";
import { ORDERS_CARD_MINI_MENU } from "./helpers";
import ViewOrders from "../../../Modals/ViewOrders/ViewOrders";
import { useDispatch } from "react-redux";
import { updateClientData } from "../../../../redux/features/newClient";

const OrdersCardMini = ({
  orderID,
  buyerName,
  totalPrice,
  avatar,
  handleOrderExpand,
  orders,
  subTotal,
  total,
  charge,
  tax,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleMenuClick = () => {
    dispatch(
      updateClientData({
        selectedCart: orders,
      })
    );
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <ViewOrders
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        subTotal={subTotal}
        orderID={orderID}
        charge={charge}
        tax={tax}
        total={total}
      />
      <div className={styles.card} onClick={handleMenuClick}>
        <div className={styles.left}>
          <AvatarChain avatars={avatar} />
          <span className={styles["left-span"]}>
            <label className={styles.label}>{orderID}</label>
            <p className={styles["left-span"]} style={{ display: "block" }}>
              {buyerName}
            </p>
          </span>
        </div>
        <div className={styles.right}>
          <p className={styles.price}>
            NGN <span className={styles.bold}>{totalPrice}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default OrdersCardMini;
