export const PRODUCT_CARD_MINI_MENU = [
  {
    label: "Edit Product",
    url: "product/edit/",
    icon: "edit",
    iconColor: "shade-100",
  },
  {
    label: "Archive Product",
    url: "archive",
    icon: "trash",
    iconColor: "shade-100",
  },
  {
    label: "Duplicate Product",
    url: "product/duplicate/",
    icon: "tasks",
    iconColor: "shade-100",
  },
];
