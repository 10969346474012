import React, { useState } from "react";
import ProductCardMini from "../../../Components/Cards/Product/ProductCardMini/ProductCardMini";
import { useGetAllProductsQuery } from "../../../redux/services/client/accounts";
import styles from "../_Main.module.scss";
import DeleteProduct from "../../../Components/Modals/DeleteProduct/DeleteProduct";
import Form from "../../../Components/Bases/Form/Form";

const AllProducts = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [productToBeDeleted, setProductToBeDeleted] = useState({
    theproductImage: "",
    theproductTitle: "",
    theprice: "",
    theinventoryAvailable: "",
    theproductId: "",
  });
  const { data: productDetails } = useGetAllProductsQuery();

  const phoneScreenWidth = 1000;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const handleArchiveProduct = ({
    productImage,
    productTitle,
    price,
    inventoryAvailable,
    productId,
  }) => {
    setModalOpen(!modalOpen);
    setProductToBeDeleted({
      theproductImage: productImage,
      theproductTitle: productTitle,
      theprice: price,
      theinventoryAvailable: inventoryAvailable,
      theproductId: productId,
    });
  };
  return (
    <>
      <DeleteProduct
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        productImage={productToBeDeleted.theproductImage}
        inventoryAvailable={productToBeDeleted.theinventoryAvailable}
        price={productToBeDeleted.theprice}
        productTitle={productToBeDeleted.theproductTitle}
        productId={productToBeDeleted.theproductId}
      />
      <section className="min-height" style={{ width: "100%" }}>
        <Form headerTitle={"All Products"} hasTitle={false}>
          <div
            className={styles["mini-product-container"]}
            style={{
              overflow: productDetails?.data?.length < 2 ? "visible" : "",
              maxHeight: !isPhoneScreen && "80vh",
            }}
          >
            {productDetails?.data?.map((product, index) => (
              <ProductCardMini
                key={product?.id}
                productImage={product?.image.url}
                inventoryAvailable={product?.percentage_available}
                price={product?.price || "0"}
                productTitle={product?.title}
                productId={product?.id}
                handleDelete={handleArchiveProduct}
                top={
                  productDetails?.data?.length > 1 && index === 0 ? "10px" : ""
                }
              />
            ))}
          </div>
        </Form>
      </section>
    </>
  );
};

export default AllProducts;
