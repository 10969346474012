import React, { useEffect, useState } from "react";
import Form from "./Form";
import Base from "../Base/Base";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import styles from "./Form.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Toast } from "../../../Components/Atoms";
import { updateClientData } from "../../../redux/features/newClient";
import {
  useLazyResendPhoneOtpQuery,
  useVerifyPhoneMutation,
} from "../../../redux/services/client/accounts";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router-dom";

const VerifyPhoneOtp = ({ setNextStep }) => {
  const [deleted, setDeleted] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const navigate = useNavigate();

  const client = useSelector((state) => state.client.value);
  const dispatch = useDispatch();

  const [verifyOtp, { isSuccess, isError, error, isLoading }] =
    useVerifyPhoneMutation();

  const [
    otpResend,
    { isFetching, isSuccess: otpResendSuccess, isError: otpResendError },
  ] = useLazyResendPhoneOtpQuery();

  const getInputIndexes = (target) => {
    const inputs = [
      document.getElementById("input1"),
      document.getElementById("input2"),
      document.getElementById("input3"),
      document.getElementById("input4"),
      document.getElementById("input5"),
      document.getElementById("input6"),
    ];

    const inputIndex = inputs.findIndex((input) => input === target);
    const nextInputIndex = inputIndex + 1;
    const prevInputIndex = inputIndex - 1;

    return {
      inputs,
      nextInputIndex,
      prevInputIndex,
    };
  };

  const handleOtpInput = async (e) => {
    const { inputs, nextInputIndex } = getInputIndexes(e.target);
    const maxLength = 1;

    if (e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }

    if (nextInputIndex < inputs.length) {
      inputs[nextInputIndex].focus();
    }

    handleDeleteItem(e);

    const totalValues = inputs.reduce(
      (acc, input) => acc + input.value.trim(),
      ""
    );
    if (totalValues.length === 6) {
      await verifyOtp({
        phone_verification_code: totalValues,
      });
      dispatch(updateClientData({ otpValue: totalValues }));
    }
  };

  const handleDeleteItem = (e) => {
    const { inputs, prevInputIndex } = getInputIndexes(e.target);
    if (deleted && e.target.value.toString().length === 0) {
      if (prevInputIndex >= 0) {
        dispatch(updateClientData({ validOtp: false }));
        inputs[prevInputIndex].focus();
      } else {
        inputs[0].focus();
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Backspace") {
      setDeleted(true);
      handleDeleteItem(e);
    }
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain").trim();
    const firstSixCharacters = pastedText.slice(0, 6);

    if (firstSixCharacters.length > 0) {
      const { inputs } = getInputIndexes(event.target);

      inputs.forEach((input, index) => {
        if (index < firstSixCharacters.length) {
          input.value = firstSixCharacters[index];
          if (index + 1 < inputs.length) {
            inputs[index + 1].focus();
          }
        }
      });
    }
  };

  const handleResetOtp = async () => {
    await otpResend();
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(clientPages.verificationComplete);
    }
    if (otpResendSuccess) {
      setToastMessage({
        message: "Otp resent successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (isError || otpResendError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isSuccess, isError, error?.data, otpResendError, otpResendSuccess]);

  return (
    <>
      {isError || otpResendError || otpResendSuccess ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <Base
        classes={styles["steps-form"]}
        onBackToLastStep={() => navigate(-1)}
      >
        <div className={styles.step}>
          <span>
            <strong>Step 3 </strong>
          </span>
          <span>/ 6</span>
        </div>
        <Form
          buttonLabel={
            isLoading && client.otpValue.length === 6 ? "" : "Continue"
          }
          onKeyDown={handleKeyPress}
          onPaste={handlePaste}
          color={
            isLoading && client.otpValue.length === 6 ? "completed" : "disabled"
          }
          icon={isLoading && client.otpValue.length === 6 ? "spinner-alt" : ""}
        >
          <div className={styles.inner}>
            <label className={styles.title}>Verify Phone Number</label>
            <span className={styles["info"]}>
              Please enter the code sent to{" "}
              <strong>{client?.phoneNumber}</strong>
            </span>
            <div className={styles.pins}>
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <TextInput
                  key={index}
                  placeholder=""
                  type="number"
                  id={`input${index}`}
                  onInput={handleOtpInput}
                  outline={isError && client.otpValue.length === 6}
                  className={"otp-inputs"}
                />
              ))}
            </div>
            <span className={styles["info"]}>
              Didn't receive an SMS? {""}
              {isFetching ? (
                <Icon icon="spinner-alt" color="shade-0" />
              ) : (
                <button type="button" onClick={handleResetOtp}>
                  resend otp
                </button>
              )}
            </span>
          </div>
        </Form>
      </Base>
    </>
  );
};

export default VerifyPhoneOtp;
