import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { BlurLoader, Dropdown, Icon, Logo } from "../Atoms";
import userImg from "../../assets/junk/user.jpg";
import { HEADER_MENU, HEADER_MENU_MOBILE } from "./Headers.helpers";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clientPages } from "../../Routes/routes";
import { emptyClient, updateClientData } from "../../redux/features/newClient";

const Header = ({ userName, ownership }) => {
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();

  const phoneScreenWidth = 605;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const client = useSelector((state) => state.client.value);

  const navigate = useNavigate();

  const { business: businessObject } = client?.profileDetails || {};
  const { active } = businessObject || {};

  const specialPages = [
    "/clients/dashboard/seller-profile",
    "/clients/dashboard/cart/checkout",
  ];

  const currentPath = window.location.pathname;

  const smallWidth = specialPages.includes(currentPath);

  const handleMenuClick = (item) => {
    if (item.icon === "sign-out") {
      localStorage.clear();
      dispatch(updateClientData(emptyClient?.value));
      navigate(item.value);
    } else {
      if (item.value.length > 1) {
        if (isPhoneScreen) {
          setDropdown(!dropdown);
        }
        navigate(item.value);
      }
    }
  };

  useEffect(() => {
    if (dropdown && isPhoneScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdown, isPhoneScreen]);

  return (
    <>
      <div
        className={styles.main}
        style={{
          maxWidth: smallWidth ? "1220px" : "",
          left: smallWidth ? "50%" : "",
          transform: smallWidth ? "translateX(-50%)" : "",
          paddingBottom: 20,
        }}
      >
        <Logo />
        {isPhoneScreen ? (
          <button
            className={styles.hamburger}
            onClick={() => setDropdown(!dropdown)}
          >
            <Icon
              icon={dropdown ? "cross-small" : "menu-burger"}
              width={20}
              height={20}
              color={"shade-0"}
            />
          </button>
        ) : (
          <div className={styles.actions}>
            <Icon icon="bell" width="24" height="24" color="shade-100" />
            <Icon
              icon="shopping-cart-checked"
              width="24"
              height="24"
              color="shade-100"
              onClick={() => navigate(clientPages.ownerSales)}
            />

            <div className={styles.user} onClick={() => setDropdown(!dropdown)}>
              <i className={styles["green-circle"]}>
                <Icon icon={"green-check-circle"} />
              </i>
              <div className={styles.avatar}>
                <img
                  src={client?.profileDetails?.avatar?.url}
                  alt="user avatar"
                />
              </div>

              <div className={styles.data}>
                <span className={styles.name}>{userName}</span>
                <span className={styles.ownership}>{ownership}</span>
              </div>
              <Icon icon="chevronDown" color="shade-0" position={"relative"} />
              {!isPhoneScreen && dropdown ? (
                <Dropdown
                  setDropdown={setDropdown}
                  handleSelection={handleMenuClick}
                  options={HEADER_MENU}
                  size={177}
                  right={!isPhoneScreen ? "40px" : "24px"}
                  left={"unset"}
                  top={"80px"}
                  maxHeight={"fit-content"}
                  portal
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {isPhoneScreen && dropdown && (
          <ul className={styles["mobile-dropdown"]}>
            {HEADER_MENU_MOBILE.map((item, index) => (
              <li key={index} onClick={() => handleMenuClick(item)}>
                <span>
                  {item.icon ? (
                    <Icon icon={item.icon} color={item.iconColor} />
                  ) : (
                    ""
                  )}
                  {item.avatar ? (
                    <img
                      src={item.avatar}
                      alt="avatar"
                      className={styles.avatar}
                    />
                  ) : (
                    ""
                  )}
                  {item.label}
                </span>
                {item.counter ? <p>{`${item.counter}+`}</p> : ""}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Header;
