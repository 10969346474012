import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import sharerLogo from "../../assets/svgs/sharelogo.svg";
import ctaCart from "../../assets/images/cta-cart.png";

const ShareImage = ({ logo, slug, businessName }) => {
  const [logo64, setLogo64] = useState("");

  const convertImageToBase64 = async (url) => {
    if (url == null) return null;
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        setLogo64(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  useEffect(() => {
    convertImageToBase64(logo);
  });

  return (
    <div className={styles.main}>
      <div className={styles.inner}>
        <div className={styles.top}>
          <div className={styles.nowOn}>
            <p className={styles.nowOnText}>Now on</p>
            <div className={styles.divider}></div>
            <img src={sharerLogo} alt="Awashop" />
          </div>
          <p className={styles.nowOnBusiness}>{businessName}</p>
        </div>
        <div
          className={`${styles.businessLogo} ${styles.variant}`}
          style={{ backgroundImage: `url(${logo64})` }}
        ></div>
        <div className={styles.cta}>
          <img src={ctaCart} alt="CTA Cart" />
          <button>Visit Shop</button>
          <p>
            awashop.ng/<span>{slug}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareImage;
