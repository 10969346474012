import React from "react";
import styles from "./Card.module.scss";

const Card = ({
  children,
  className,
  id,
  style,
  onClick,
  ref,
  onMouseEnter,
  onMouseLeave,
  ...props
}) => {
  return (
    <div
      className={`${styles.card} ${className}`}
      ref={ref}
      style={style}
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
