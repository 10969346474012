import React, { useState, useRef } from "react";
import styles from "./TagInput.module.scss";
import { Icon } from "../../../../Atoms";

const TagInput = ({
  placeholder,
  suggestion,
  hasLabel,
  label,
  type,
  allTags,
}) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const phoneScreenWidth = 768;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const validateEmail = (emailAddress) => {
    const emailPattern =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/;
    return emailPattern.test(emailAddress);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value.trim());
    const filteredSuggestions = getSuggestions(value);
    setSuggestions(filteredSuggestions);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      addTag(inputValue.trim());
      setInputValue("");
      if (type === "productVariant") {
        inputRef.current.style.display = "none";
      } else {
        inputRef.current.focus();
      }
    } else if (e.key === "Backspace" && inputValue === "") {
      e.preventDefault();
      const lastTagIndex = tags.length - 1;
      if (lastTagIndex >= 0) {
        handleTagDelete(lastTagIndex);
        inputRef.current.focus();
      }
    }
  };

  const handleBlur = () => {
    if (inputValue.length > 0) {
      addTag(inputValue.trim());
      setInputValue("");
      if (type === "productVariant") {
        inputRef.current.style.display = "none";
        if (showDropdown) {
          setShowDropdown(false);
        }
      } else {
        inputRef.current.focus();
      }
    }
  };

  const handleTagDelete = (tagIndex) => {
    const updatedTags = tags.filter((_, index) => index !== tagIndex);
    setTags(updatedTags);
    allTags(updatedTags);
    if (type === "productVariant") {
      inputRef.current.style.display = "block";
      setShowDropdown(true);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    addTag(suggestion.label);
    if (type === "members") {
      const updatedTags = [...selectedSuggestions, suggestion];
      setSelectedSuggestions(updatedTags);
    }
    setInputValue("");
    setSuggestions([]);
    inputRef.current.focus();
    if (type === "productVariant") {
      setShowDropdown(false);
      inputRef.current.style.display = "none";
    }
  };

  const addTag = (tag) => {
    const updatedTags = [...tags, tag];
    setTags(updatedTags);

    if (type === "productVariant") {
      allTags(updatedTags.toString());
    } else {
      allTags(updatedTags);
    }
  };
  const getSuggestions = (value) => {
    const suggestions = suggestion || [
      {
        value: "Jollof Rice",
        label: "Jollof Rice",
      },
      { value: "Fufu", label: "Fufu" },
      { value: "Banku", label: "Banku" },
      { value: "Pounded Yam", label: "Pounded Yam" },
      { value: "Egusi Soup", label: "Egusi Soup" },
      { value: "Doro Wat", label: "Doro Wat" },
    ];
    return suggestions.filter((suggestion) =>
      suggestion.label.toLowerCase().includes(value.toLowerCase())
    );
  };

  const handleElementClick = () => {
    inputRef?.current?.focus();
    if (type === "productVariant" && tags.length < 1 && showDropdown) {
      //   if ((dropdownRef?.current?.style.display = "none")) {
      //     dropdownRef?.current?.style.display = "flex";
      // }
      setSuggestions(getSuggestions(inputValue));
    }
  };

  return (
    <div className={styles["tag-input-main"]}>
      {hasLabel && <label>{label}</label>}
      <div
        className={styles["tag-input"]}
        onClick={handleElementClick}
        style={{ maxHeight: type === "productVariant" && 45 }}
      >
        <div className={styles["tag-input-top"]}>
          <ul className={styles["tag-list"]}>
            {tags.map((tag, index) => {
              const suggestion = selectedSuggestions.find(
                (suggestion) => suggestion.label === tag
              );

              return (
                <li
                  key={index}
                  className={styles["tag-item"]}
                  style={{
                    color:
                      type === "email" && !validateEmail(tag)
                        ? "var(--error)"
                        : "",
                    background:
                      type === "email" && !validateEmail(tag)
                        ? "rgba(251, 81, 88, 0.1)"
                        : "",
                  }}
                >
                  {type === "members" && suggestion && suggestion.avatar ? (
                    <img
                      src={suggestion.avatar}
                      alt="avatar"
                      className={styles["suggestion-avatar"]}
                    />
                  ) : null}
                  {tag}
                  {type === "members" ? (
                    <button className={styles.cancel}>
                      <Icon
                        icon="cross-small"
                        onClick={() => handleTagDelete(index)}
                        color={"error"}
                      />
                    </button>
                  ) : (
                    <Icon
                      icon="cross-small"
                      onClick={() => handleTagDelete(index)}
                      color={
                        type === "email" && !validateEmail(tag)
                          ? "error"
                          : "shade-0"
                      }
                    />
                  )}
                </li>
              );
            })}
            <li
              className={styles["tag-item-input"]}
              style={{
                width:
                  (type === "email" || type === "members") && tags.length === 0
                    ? "100%"
                    : "",
              }}
            >
              <input
                type="text"
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder={
                  tags.length < 1
                    ? placeholder || "Enter a Value"
                    : "Enter another Value"
                }
                onBlur={handleBlur}
              />
            </li>
          </ul>
        </div>
        {suggestions.length > 0 && showDropdown ? (
          <>
            <div
              className={styles.modal}
              onClick={() => setShowDropdown(false)}
            ></div>
            <ul className={styles.suggestions} ref={dropdownRef}>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className={styles["suggestion-options"]}
                >
                  {suggestion.avatar ? (
                    <img
                      src={suggestion.avatar}
                      alt="avatar"
                      className={styles["suggestion-avatar"]}
                    />
                  ) : (
                    ""
                  )}
                  {suggestion.label}
                </li>
              ))}
            </ul>
          </>
        ) : (
          ""
        )}
        {type === "productVariant" && tags.length < 1 && (
          <i className={styles["chevron-down"]}>
            <Icon icon="chevronDown" color={"shade-0"} />
          </i>
        )}
      </div>
    </div>
  );
};

TagInput.defaultProps = {
  hasLabel: false,
  type: "values",
};

export default TagInput;
