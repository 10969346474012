import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateClientData } from "../../../../redux/features/newClient";

import { useEmailUsabilityQuery } from "../../../../redux/services/client/onboarding";
import { useClientStartLogic } from "../../../../Hooks/onboardingHooks";

export function useStepTwoLogic(setNextStep, stepTwoRef) {
  const client = useSelector((state) => state.client.value);
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  const {
    data: emailUsabilityQueryResponse,
    isSuccess: emailValidityStatus,
    isFetching: emailUsabilityLoading,
  } = useEmailUsabilityQuery(
    { email: client.email_address },
    { skip: !isValid }
  );

  if (emailValidityStatus) {
    if (emailUsabilityQueryResponse.data.usable === true) {
      dispatch(
        updateClientData({
          emailIsUsable: emailUsabilityQueryResponse.data.usable,
          hasValidEmail: true,
          alreadyExists: false,
        })
      );
    } else {
      dispatch(
        updateClientData({
          alreadyExists: true,
        })
      );
    }
  } else {
    dispatch(
      updateClientData({
        emailIsUsable: false,
        hasValidEmail: false,
        alreadyExists: false,
      })
    );
  }

  const { setIsReadyForStart, isLoading } = useClientStartLogic(
    client,
    setNextStep
  );

  const validateEmail = (emailAddress) => {
    const emailPattern =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/;
    return emailPattern.test(emailAddress);
  };

  const handleEmailInput = (e) => {
    const { value } = e.target;
    const emailAddress = value.trim();

    dispatch(updateClientData({ email_address: emailAddress }));
    setIsValid(validateEmail(emailAddress));
  };

  const handleFormSubmit = () => {
    if (client.emailIsUsable) {
      setIsReadyForStart(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  return {
    isValid,
    setIsValid,
    emailUsabilityQueryResponse,
    emailValidityStatus,
    emailUsabilityLoading,
    handleEmailInput,
    handleFormSubmit,
    handleKeyPress,
    isLoading,
  };
}
