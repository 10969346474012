import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/clients`,
    prepareHeaders: (headers) => {
      let token = localStorage.getItem("authToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    headers: {
      "Content-Type": "application/json",
    },
  }),
  tagTypes: ["Profile", "Product", "Rates", "Categories", "Entities"],
  endpoints: (builder) => ({
    login: builder.query({
      query: (client) => ({
        url: "onboarding/login",
        method: "POST",
        body: { client: client },
      }),
    }),
    inviteByEmail: builder.query({
      query: (invite) => ({
        url: ":company_slug/invitations/emails",
        method: "POST",
        body: { invite: invite },
      }),
      providesTags: false,
    }),
    getCategories: builder.query({
      query: () => ({
        url: "project-categories",
        method: "GET",
      }),
    }),
    getCompanies: builder.query({
      query: () => "companies",
    }),
    getCompany: builder.query({
      query: (slug) => `${slug}`,
    }),
    getClient: builder.query({
      query: () => "auth/profile",
    }),
    getProfile: builder.query({
      query: () => ({
        url: "profile",
        method: "GET",
      }),
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (business) => ({
        url: "business/shop",
        method: "PUT",
        body: { business: business },
      }),
      invalidatesTags: ["Profile"],
    }),
    updateLinks: builder.mutation({
      query: (links) => ({
        url: "business/shop/links",
        method: "PUT",
        body: { links: links },
      }),
      invalidatesTags: ["Profile"],
    }),
    getAllProducts: builder.query({
      query: () => ({
        url: "products",
        method: "GET",
      }),
      providesTags: ["Product"],
    }),
    getAProduct: builder.query({
      query: (product) => ({
        url: `products/${product.id}`,
        method: "GET",
      }),
      providesTags: ["Product"],
    }),
    createAProduct: builder.mutation({
      query: (product) => ({
        url: "products/new",
        method: "POST",
        body: { product: product },
      }),
      invalidatesTags: ["Product"],
    }),
    updateAProduct: builder.mutation({
      query: (product) => ({
        url: `products/${product.id}`,
        method: "PUT",
        body: { product: product },
      }),
      invalidatesTags: ["Product"],
    }),
    removeAProduct: builder.mutation({
      query: (product) => ({
        url: `products/${product.id}`,
        method: "DELETE",
        body: { product: product },
      }),
      invalidatesTags: ["Product"],
    }),
    getBanksList: builder.query({
      query: () => ({
        url: `business/banks/list`,
        method: "GET",
      }),
    }),
    validateAccountNumber: builder.query({
      query: (bank) => ({
        url: "business/banks/resolve",
        method: "POST",
        body: { bank: bank },
      }),
    }),
    updateAccountDetails: builder.query({
      query: (bank) => ({
        url: "business/banks/update",
        method: "POST",
        body: { bank: bank },
      }),
    }),
    updatePhone: builder.mutation({
      query: (client) => ({
        url: "profile/phone",
        method: "PUT",
        body: { client: client },
      }),
      invalidatesTags: ["Profile"],
    }),
    verifyPhone: builder.mutation({
      query: (client) => ({
        url: "profile/phone/verify",
        method: "POST",
        body: { client: client },
      }),
      invalidatesTags: ["Profile"],
    }),
    resendPhoneOtp: builder.query({
      query: (client) => ({
        url: "profile/phone/verify/resend",
        method: "POST",
        body: { client: client },
      }),
    }),
    identityVerification: builder.mutation({
      query: (id_verification) => ({
        url: "profile/identity/verify",
        method: "POST",
        body: { id_verification: id_verification },
      }),
      invalidatesTags: ["Profile"],
    }),
    withdraw: builder.mutation({
      query: () => ({
        url: "business/banks/withdraw",
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Profile"],
    }),
    getAllOrders: builder.query({
      query: () => ({
        url: "profile/orders",
        method: "GET",
      }),
    }),
    requestToGoLive: builder.mutation({
      query: () => ({
        url: "business/shop/go-live",
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Profile"],
    }),
    createAVariant: builder.mutation({
      query: (product_variant) => ({
        url: `products/${product_variant.id}/variants`,
        method: "POST",
        body: { product_variant: product_variant },
      }),
      invalidatesTags: ["Product"],
    }),
    updateAVariant: builder.mutation({
      query: (product_variant) => ({
        url: `products/${product_variant.id}/variants/${product_variant.variant_id}`,
        method: "PUT",
        body: { product_variant: product_variant },
      }),
      invalidatesTags: ["Product"],
    }),
    getRates: builder.query({
      query: () => ({
        url: "delivery/rates",
        method: "GET",
      }),
      providesTags: ["Rates"],
    }),
    updateARate: builder.mutation({
      query: (delivery_rate_entities) => ({
        url: `delivery/rates/${delivery_rate_entities.id}`,
        method: "PUT",
        body: { delivery_rate_entities: delivery_rate_entities },
      }),
      invalidatesTags: ["Rates"],
    }),
    deleteARate: builder.mutation({
      query: (delivery_rate_entities) => ({
        url: `delivery/rates/${delivery_rate_entities.id}`,
        method: "DELETE",
        body: { delivery_rate_entities: delivery_rate_entities },
      }),
      invalidatesTags: ["Rates"],
    }),
    createACustomRate: builder.mutation({
      query: (delivery_rate_entities) => ({
        url: `delivery/rates/new`,
        method: "POST",
        body: { delivery_rate_entities: delivery_rate_entities },
      }),
      invalidatesTags: ["Rates"],
    }),
    getAllCategories: builder.query({
      query: () => ({
        url: "categories",
        method: "GET",
      }),
      providesTags: ["Categories"],
    }),
    getACategory: builder.query({
      query: (category) => ({
        url: `categories/${category.id}`,
        method: "GET",
      }),
      providesTags: ["Categories"],
    }),
    createACategory: builder.mutation({
      query: (business_category) => ({
        url: `categories/new`,
        method: "POST",
        body: { business_category: business_category },
      }),
      invalidatesTags: ["Categories"],
    }),
    updateACategory: builder.mutation({
      query: (business_category) => ({
        url: `categories/${business_category.id}`,
        method: "PUT",
        body: { business_category: business_category },
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteACategory: builder.mutation({
      query: (business_category) => ({
        url: `categories/${business_category.id}`,
        method: "DELETE",
        body: { business_category: business_category },
      }),
      invalidatesTags: ["Categories"],
    }),
    getAllEntities: builder.query({
      query: () => ({
        url: `delivery/entities`,
        method: "GET",
      }),
      providesTags: ["Entities"],
    }),
    addEntity: builder.mutation({
      query: (delivery_entities) => ({
        url: `delivery/entities/new`,
        method: "POST",
        body: { delivery_entities: delivery_entities },
      }),
      invalidatesTags: ["Entities"],
    }),
    updateEntity: builder.mutation({
      query: (delivery_entities) => ({
        url: `delivery/entities/${delivery_entities.id}`,
        method: "PUT",
        body: { delivery_entities: delivery_entities },
      }),
      invalidatesTags: ["Entities"],
    }),
    deleteEntity: builder.mutation({
      query: (delivery_entities) => ({
        url: `delivery/entities/${delivery_entities.id}`,
        method: "DELETE",
        body: { delivery_entities: delivery_entities },
      }),
      invalidatesTags: ["Entities"],
    }),
    toggleInHouse: builder.mutation({
      query: (delivery_entities) => ({
        url: `delivery/rates/toggle-in-house`,
        method: "PUT",
        body: { delivery_entities: delivery_entities },
      }),
      invalidatesTags: ["Rates"],
    }),
    toggleInterStateDelivery: builder.mutation({
      query: (delivery_entities) => ({
        url: `delivery/rates/toggle-states-delivery`,
        method: "PUT",
        body: { delivery_entities: delivery_entities },
      }),
      invalidatesTags: ["Rates"],
    }),
  }),
});

export const {
  useLazyLoginQuery,
  useInviteByEmailQuery,
  useGetClientQuery,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetCategoriesQuery,
  useLazyGetCategoriesQuery,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdateLinksMutation,
  useGetAllProductsQuery,
  useGetAProductQuery,
  useCreateAProductMutation,
  useUpdateAProductMutation,
  useRemoveAProductMutation,
  useGetBanksListQuery,
  useLazyValidateAccountNumberQuery,
  useLazyUpdateAccountDetailsQuery,
  useUpdatePhoneMutation,
  useVerifyPhoneMutation,
  useLazyResendPhoneOtpQuery,
  useIdentityVerificationMutation,
  useWithdrawMutation,
  useGetAllOrdersQuery,
  useRequestToGoLiveMutation,
  useCreateAVariantMutation,
  useUpdateAVariantMutation,
  useGetRatesQuery,
  useUpdateARateMutation,
  useDeleteARateMutation,
  useCreateACustomRateMutation,
  useGetAllCategoriesQuery,
  useGetACategoryQuery,
  useCreateACategoryMutation,
  useUpdateACategoryMutation,
  useDeleteACategoryMutation,
  useGetAllEntitiesQuery,
  useAddEntityMutation,
  useUpdateEntityMutation,
  useDeleteEntityMutation,
  useToggleInHouseMutation,
  useToggleInterStateDeliveryMutation,
} = accountsApi;

// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// export const accountsApi = createApi({
//   reducerPath: "accountsApi",
//   baseQuery: fetchBaseQuery({
//     baseUrl:
//       "https://space-backend-xkivl.ondigitalocean.app/api/v1/fe/clients/auth",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   }),
//   endpoints: (builder) => ({
//     start: builder.query({
//       query: (client) => ({
//         url: "new/start",
//         method: "POST",
//         body: { client: client },
//       }),
//     }),
//     emailUsability: builder.query({
//       query: (client) => ({
//         url: "email/validate",
//         method: "POST",
//         body: { client: client },
//       }),
//     }),
//     verifyEmail: builder.query({
//       query: (client) => ({
//         url: "new/email/verify",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//         },
//         method: "POST",
//         body: { client: client },
//       }),
//     }),
//     setPassword: builder.query({
//       query: (client) => ({
//         url: "new/password/set",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//         },
//         method: "PUT",
//         body: { client: client },
//       }),
//     }),
//   }),
// });

// export const {
//   useLazyStartQuery,
//   useStartQuery,
//   useLazyEmailUsabilityQuery,
//   useEmailUsabilityQuery,
//   useVerifyEmailQuery,
//   useSetPasswordQuery,
// } = accountsApi;
