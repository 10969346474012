import { clientPages } from "../../Routes/routes";

export const HEADER_MENU = [
  {
    label: "Dashboard",
    value: "/clients/dashboard",
    icon: "app",
    iconColor: "shade-100",
  },
  {
    label: "Profile Settings",
    value: "profile/edit",
    icon: "users",
    iconColor: "shade-100",
  },
  {
    label: "Payout Settings",
    value: "payout",
    icon: "money",
    iconColor: "shade-100",
  },
  {
    label: "Business Settings",
    value: "edit",
    icon: "building",
    iconColor: "shade-100",
  },
  {
    label: "Delivery Rates",
    value: "delivery_rates",
    icon: "bike",
    iconColor: "shade-100",
  },
  {
    label: "Categories",
    value: "categories",
    icon: "list",
    iconColor: "shade-100",
  },
  {
    label: "Log Out",
    value: "/clients/auth/login",
    icon: "sign-out",
    iconColor: "shade-100",
  },
];

export const HEADER_MENU_MOBILE = [
  {
    label: "Dashboard",
    value: "/clients/dashboard",
    icon: "app",
    iconColor: "shade-100",
  },
  {
    label: "Profile Settings",
    value: "profile/edit",
    icon: "users",
    iconColor: "shade-100",
  },
  {
    label: "Categories",
    value: "categories",
    icon: "list",
    iconColor: "shade-100",
  },
  {
    label: "All Products",
    value: "/clients/dashboard/all-products",
    icon: "shopping-bag",
    iconColor: "shade-100",
  },
  {
    label: "My Orders",
    value: "/clients/dashboard/sales",
    icon: "shopping-cart",
    iconColor: "shade-100",
  },
  {
    label: "Payout Settings",
    value: "payout",
    icon: "money",
    iconColor: "shade-100",
  },
  {
    label: "Business Settings",
    value: "edit",
    icon: "building",
    iconColor: "shade-100",
  },
  {
    label: "Delivery Rates",
    value: "delivery_rates",
    icon: "bike",
    iconColor: "shade-100",
  },
  {
    label: "",
    value: "",
    icon: "caret-down",
    iconColor: "shade-100",
  },
  {
    label: "Log Out",
    value: "/clients/auth/login",
    icon: "sign-out",
    iconColor: "shade-100",
  },
];
