import React, { useEffect, useState } from "react";
import styles from "../../Onboarding/Steps/Form.module.scss";
import { useNavigate } from "react-router";
import Base from "../../Onboarding/Base/Base";
import { Icon, Loader, ProgressBar, Toast } from "../../../Components/Atoms";
import { buyerPages } from "../../../Routes/routes";
import { useVerifyPaymentQuery } from "../../../redux/services/client/buyer";
import { localStorageCart } from "../helpers";

const VerifyPayment = () => {
  const currentPath = window.location.pathname;

  const pathnameParts = currentPath.split("/");

  const shopSlug = pathnameParts[1];

  const cartInfo = JSON.parse(localStorage.getItem(localStorageCart));

  const matchingCartItem = cartInfo?.find((item) => item.slug === shopSlug);

  const { cartId, slug } = matchingCartItem || {};

  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const {
    data: verifyPaymentResponse,
    isFetching: paymentVerifying,
    isSuccess: verifySuccess,
    isError: verificationError,
    error: verifyError,
  } = useVerifyPaymentQuery({
    shop_slug: slug,
    cart_id: cartId,
  });

  useEffect(() => {
    if (verificationError) {
      setToastMessage({
        message: verifyError?.data?.message || "Something went wrong",
        status: "error",
        heading: verifyError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [verificationError, verifyError?.data]);

  useEffect(() => {
    let interval;
    if (verifySuccess) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 10;
          if (newProgress >= 90) {
            clearInterval(interval);

            const itemIndex = cartInfo?.findIndex(
              (item) => item.cartId === cartId && item.slug === slug
            );

            if (itemIndex !== -1) {
              const updatedCartInfo = [
                ...cartInfo.slice(0, itemIndex),
                ...cartInfo.slice(itemIndex + 1),
              ];

              localStorage.setItem(
                localStorageCart,
                JSON.stringify(updatedCartInfo)
              );
            }
            navigate(`/${slug}`);
          }
          return newProgress;
        });
      }, 600);
    }
    return () => {
      clearInterval(interval);
    };
  }, [verifySuccess, navigate]);

  return (
    <Base stepsPage={false} displayLogo={false}>
      {verificationError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"basePage"}
        />
      ) : (
        ""
      )}
      <div className={styles["last-step"]}>
        {verifySuccess ? (
          <Icon icon="modal-icon" width={100} height={100} />
        ) : (
          <div
            style={{
              position: "relative",
              width: 100,
              height: 100,
              marginBottom: "-24px",
            }}
          >
            <Loader size={50} />
          </div>
        )}
        <span className={styles["middle-span"]}>
          <h3 className={styles.title}>All done!</h3>
          <p className={styles.info}>
            {verifySuccess
              ? "Payment Verification Successful!!!. Redirecting you back to home now"
              : `We are verifying your payment. Give us a moment and you will be
            redirected shortly.`}
          </p>
        </span>
        <div className={styles.actions}>
          <ProgressBar progress={`${progress}%`} />
          <p className={styles.progress}>{progress}% complete</p>
        </div>
      </div>
    </Base>
  );
};

export default VerifyPayment;
