import React, { useState, useEffect } from "react";
import Icon from "../Icons/Icon";

const Loader = ({ size, showFor, absolute }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (showFor) {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, showFor);
      return () => clearTimeout(timeout);
    }
  }, [showFor]);

  if (!visible) {
    return null;
  }

  return (
    <>
      {showFor && <div className="backdrop"></div>}
      <div
        style={
          absolute
            ? {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "fit-content",
              }
            : {}
        }
      >
        {" "}
        <Icon
          icon="spinner-alt"
          color="completed"
          width={size}
          height={size}
        />{" "}
      </div>
    </>
  );
};

Loader.defaultProps = {
  absolute: true,
};

export default Loader;
