import React, { createRef, useEffect, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import styles from "./_Main.module.scss";
import { TextInput, ValueInput } from "../../../Components/Atoms/Input/Input";
import { CountrySelect, Icon, Loader, Toast } from "../../../Components/Atoms";
import {
  useAddEntityMutation,
  useCreateACustomRateMutation,
  useDeleteEntityMutation,
  useGetAllEntitiesQuery,
  useGetRatesQuery,
  useToggleInHouseMutation,
  useToggleInterStateDeliveryMutation,
  useUpdateARateMutation,
  useUpdateEntityMutation,
} from "../../../redux/services/client/accounts";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetStatesListQuery } from "../../../redux/services/client/onboarding";
import { tab } from "@testing-library/user-event/dist/tab";

const DeliveryRates = () => {
  const [ratesCount, setRatesCount] = useState(0);
  const [defaultRatesCount, setDefaultRatesCount] = useState(0);
  const [originalValues, setOriginalValues] = useState([]);
  const [originalCustomValues, setOriginalCustomValues] = useState([]);
  const [tablesDataCount, setTablesDataCount] = useState(0);
  const [openContainers, setOpenContainers] = useState({
    table: true,
    defaultRates: true,
    customRates: true,
  });

  const client = useSelector((state) => state.client.value);

  const { business } = client?.profileDetails || {};
  const { address } = business || {};

  const tableLength = Array.from(
    { length: tablesDataCount },
    (_, index) => index
  );

  const [tablesData, setTablesData] = useState(
    tableLength?.map(() => ({
      id: "",
      title: "",
      status: "",
    }))
  );

  const [tableInputValue, setTableInputValue] = useState(
    tablesData?.map(() => ({
      id: "",
      title: "",
      status: "",
    }))
  );

  const phoneScreenWidth = 620;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const {
    data: rateDetails,
    isLoading: rateDetailsLoading,
    isError,
    error,
  } = useGetRatesQuery();

  const {
    data: tableDetails,
    isLoading: tableDetailsLoading,
    fetchEntitiesError,
    entitiesError,
  } = useGetAllEntitiesQuery({}, { skip: !rateDetails?.data?.in_house });

  const [isInHouse, setIsInHouse] = useState(
    rateDetails?.data?.in_house || null
  );

  const [toOtherStates, setToOtherStates] = useState(
    rateDetails?.data?.inter_state_delivery || null
  );

  const deliveryRates = rateDetails?.data?.entities || [];
  const filteredDeliveryRate =
    deliveryRates?.find((location) => location.state === address?.state)
      ?.cities || [];

  const [
    updateRate,
    {
      isLoading: updateLoading,
      isSuccess: updateRateSuccess,
      isError: updateRateError,
      error: rateUpdateError,
    },
  ] = useUpdateARateMutation();

  const [
    updateEntity,
    {
      isLoading: updateEntityLoading,
      isSuccess: updateEntitySuccess,
      isError: updateEntityError,
      error: entityUpdateError,
    },
  ] = useUpdateEntityMutation();

  const [
    createCustomRate,
    {
      isLoading: createCustomRateLoading,
      isSuccess: createCustomRateSuccess,
      isError: createCustomRateError,
      error: createCustomError,
    },
  ] = useCreateACustomRateMutation();

  const [
    createEntity,
    {
      isLoading: createEntityLoading,
      isSuccess: createEntitySuccess,
      isError: createEntityError,
      error: entityCreationError,
    },
  ] = useAddEntityMutation();

  const [
    deleteEntity,
    {
      isLoading: deleteEntityLoading,
      isSuccess: deleteEntitySuccess,
      isError: deleteEntityError,
      error: entityDeletionError,
    },
  ] = useDeleteEntityMutation();

  const [
    toggleInHouse,
    {
      isLoading: toggleLoading,
      isSuccess: toggleSuccess,
      isError: toggleError,
      error: toggleInHouseError,
    },
  ] = useToggleInHouseMutation();

  const [
    toggleInterState,
    {
      isLoading: toggleInterStateLoading,
      isSuccess: toggleInterStateSuccess,
      isError: toggleInterStateError,
      error: interStateToggleError,
    },
  ] = useToggleInterStateDeliveryMutation();

  const {
    data: statesData,
    isSuccess: statesQuerySuccess,
    isError: statesQueryError,
    error: statesError,
    refetch: refetchStates,
  } = useGetStatesListQuery(
    {
      shop_slug: business?.slug,
      country_alpha2: "NG",
    },
    { skip: !toOtherStates }
  );

  const [loadingIndexCustom, setLoadingIndexCustom] = useState(null);

  const [entityLoadingIndex, setEntityLoadingIndex] = useState(null);

  const defaultRatesLength = Array.from(
    { length: defaultRatesCount },
    (_, index) => index
  );

  const [defaultRatesValues, setDefaultRatesValues] = useState(
    defaultRatesLength?.map(() => ({
      city: "",
      price: "",
      business_delivery_rate_id: "",
    }))
  );

  const [inputValues, setInputValues] = useState(
    defaultRatesValues?.map(() => ({
      city: "",
      price: "",
      business_delivery_rate_id: "",
    }))
  );

  const [loadingIndex, setLoadingIndex] = useState(null);

  const ratesLength = Array.from({ length: ratesCount }, (_, index) => index);

  const [customRates, setCustomRates] = useState(
    ratesLength.map(() => ({
      city: "",
      price: "",
      business_delivery_rate_id: "",
    }))
  );

  const [customInputValues, setCustomInputValues] = useState(
    customRates?.map(() => ({
      city: "",
      price: "",
      business_delivery_rate_id: "",
    }))
  );
  const [selectedState, setSelectedState] = useState({
    stateName: null,
    stateCode: null,
  });

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const navigate = useNavigate();

  let lastDefaultRatesObject;

  let lastCustomRatesObject;

  let lastTableEntityObject;

  if (defaultRatesValues?.length > 0) {
    lastDefaultRatesObject = defaultRatesValues[defaultRatesValues?.length - 1];
  }

  if (customRates?.length > 0) {
    lastCustomRatesObject = customRates[customRates?.length - 1];
  }

  if (tablesData?.length > 0) {
    lastTableEntityObject = tablesData[tablesData?.length - 1];
  }

  // const lastDefaultRatesObject =
  //   defaultRatesValues[defaultRatesValues?.length - 1];

  // const lastCustomRatesObject = customRates[customRates?.length - 1];

  // const handleStateSelection = ({ alpha2, name }) => {
  //   setSelectedState({
  //     stateName: name,
  //     stateCode: alpha2,
  //   });
  // };

  const handleAddRateClick = () => {
    setRatesCount((prevCount) => prevCount + 1);
    setCustomRates((prevStates) => [
      ...prevStates,
      {
        city: "",
        price: "",
        business_delivery_rate_id: "",
      },
    ]);
  };

  const handleAddDefaultRateClick = async () => {
    setDefaultRatesCount((prevCount) => prevCount + 1);
    setDefaultRatesValues((prevStates) => [
      ...prevStates,
      {
        city: "",
        price: "",
        business_delivery_rate_id: "",
      },
    ]);
  };

  // console.log("default is", defaultRatesValues);
  // console.log("input is", inputValues);
  // console.log(emptyfieldsError);

  const handleAddTableClick = () => {
    setTablesDataCount((prevCount) => prevCount + 1);
    setTablesData((prevStates) => [
      ...prevStates,
      {
        id: "",
        title: "",
        status: "",
      },
    ]);
  };

  const handleDeleteRateClick = () => {
    setRatesCount((prevCount) => prevCount - 1);
  };

  // const handleInputChange = (containerIndex, field, value, id, rateType) => {
  //   const updatedInputValues = [...inputValues];
  //   updatedInputValues[containerIndex][field] = value;
  //   updatedInputValues[containerIndex].id = id;
  //   updatedInputValues[containerIndex].rate_type = rateType;
  //   setInputValues(updatedInputValues);
  // };

  const handleTableInputChange = (containerIndex, field, value, id) => {
    setTableInputValue((prevInputValues) => {
      const updatedInputValues = [...prevInputValues];
      updatedInputValues[containerIndex] = {
        ...updatedInputValues[containerIndex],
        [field]: value,
        id,
      };
      return updatedInputValues;
    });
  };

  const handleTableButtonClick = async (index, action) => {
    setEntityLoadingIndex(index);
    const { title, id } = tableInputValue[index] || {};
    const entityData = { title, id };
    if (action === "check") {
      if (id) {
        await updateEntity(entityData);
      } else {
        await createEntity({ title });
      }
    } else if (action === "trash") {
      if (id) {
        deleteEntity({ id });
      } else {
        setTablesDataCount((prevCount) => prevCount - 1);
        setTablesData((prevValues) => {
          const newValues = [...prevValues];
          newValues.splice(index, 1);
          return newValues;
        });
      }
    }
  };

  const handleInputChange = (containerIndex, field, value, id) => {
    setInputValues((prevInputValues) => {
      const updatedInputValues = [...prevInputValues];
      updatedInputValues[containerIndex] = {
        ...updatedInputValues[containerIndex],
        [field]: value,
        business_delivery_rate_id: id,
      };
      return updatedInputValues;
    });
  };

  const handleInputChangeCustom = (containerIndex, field, id, value) => {
    setCustomInputValues((prevInputValues) => {
      const updatedInputValues = [...prevInputValues];
      updatedInputValues[containerIndex] = {
        ...updatedInputValues[containerIndex],
        [field]: value,
        business_delivery_rate_id: id,
      };
      return updatedInputValues;
    });
  };

  const refs = defaultRatesValues?.map(() => ({
    cityAreaRef: createRef(),
    priceRef: createRef(),
  }));

  const handleButtonClick = async (containerIndex, action) => {
    if (action === "check") {
      setLoadingIndex(containerIndex);
      const { price, city, business_delivery_rate_id } =
        inputValues[containerIndex];
      const ratesData = { price, id: business_delivery_rate_id };
      if (business_delivery_rate_id) {
        await updateRate(ratesData);
      } else {
        const newCityData = { price, city, rate_type: "intra" };
        await createCustomRate(newCityData);
      }
    } else if (action === "trash") {
      if (
        defaultRatesValues.length - 1 === containerIndex &&
        !lastDefaultRatesObject?.business_delivery_rate_id
      ) {
        setDefaultRatesCount((prevCount) => prevCount - 1);
        setDefaultRatesValues((prevValues) => {
          const newValues = [...prevValues];
          newValues.splice(containerIndex, 1);
          return newValues;
        });
      }
    }
  };

  const handleCustomButtonClick = async (index, action) => {
    if (action === "check") {
      setLoadingIndexCustom(index);
      const { price, city, business_delivery_rate_id } =
        customInputValues[index] || {};

      if (business_delivery_rate_id) {
        const ratesData = {
          price,
          id: business_delivery_rate_id,
          rate_type: "inter",
        };
        await updateRate(ratesData);
      } else {
        const ratesData = { price, city, rate_type: "inter" };
        await createCustomRate(ratesData);
      }
    } else if (action === "trash") {
      if (
        customRates.length - 1 === index &&
        !lastCustomRatesObject.business_delivery_rate_id
      ) {
        setRatesCount((prevCount) => prevCount - 1);
        setCustomRates((prevValues) => {
          const newValues = [...prevValues];
          newValues.splice(index, 1);
          return newValues;
        });
      }
    }
  };

  const handleInHouseToggle = async () => {
    setIsInHouse(!isInHouse);
    await toggleInHouse();
  };

  const handleToOtherStatesToggle = async () => {
    setToOtherStates(!toOtherStates);
    await toggleInterState();
  };

  const logRefValues = () => {
    refs.forEach((ref, index) => {
      console.log(`Container ${index + 1}:`);
      console.log("City/Area Value:", ref.cityAreaRef.current.getValue());
      console.log("Price Value:", ref.priceRef.current.getValue());
    });
  };

  useEffect(() => {
    const tableData = tableDetails?.data?.entities || [];
    setOriginalValues(tableData);
    setTableInputValue(tableData);
    setTablesData(tableData);
    setTablesDataCount(tableData?.length);
  }, [tableDetails]);

  // useEffect(() => {
  //   console.log(defaultRatesValues);
  // }, [defaultRatesCount]);

  // useEffect(() => {
  //   setOriginalValues(tableInputValue);
  // }, [tablesDataCount]);

  useEffect(() => {
    const formatOtherStates = (otherStates) => {
      return otherStates?.map((newState) => ({
        city: newState?.state || "",
        price: newState?.cities[0]?.price || "",
        business_delivery_rate_id:
          newState?.cities[0]?.business_delivery_rate_id || undefined,
      }));
    };
    setIsInHouse(rateDetails?.data?.in_house);
    setToOtherStates(rateDetails?.data?.inter_state_delivery);
    const deliveryRates = rateDetails?.data?.entities || [];
    const filteredDeliveryRate = deliveryRates?.find(
      (location) => location.state === address?.state
    )?.cities;

    setDefaultRatesCount(filteredDeliveryRate?.length);
    setOriginalValues(filteredDeliveryRate);
    setDefaultRatesValues(filteredDeliveryRate);
    setInputValues(filteredDeliveryRate);

    const differentStateDelivery =
      deliveryRates?.filter((location) => location.state !== address?.state) ||
      {};

    const customRatesData = differentStateDelivery;

    if (customRatesData[0]?.cities) {
      setRatesCount(customRatesData?.length);
      setOriginalCustomValues(formatOtherStates(customRatesData));
      setCustomRates(formatOtherStates(customRatesData));
      setCustomInputValues(formatOtherStates(customRatesData));
    }
  }, [rateDetails]);

  // console.log(customRates);

  useEffect(() => {
    if (toggleInterStateError && interStateToggleError?.status === 401) {
      setToastMessage({
        message: interStateToggleError?.data?.message || "Something went wrong",
        status: "error",
        heading: interStateToggleError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (toggleInterStateError && interStateToggleError?.status !== 401) {
      setToOtherStates(!toOtherStates);
      setToastMessage({
        message: interStateToggleError?.data?.message || "Something went wrong",
        status: "error",
        heading: interStateToggleError?.data?.status || "Error",
      });
    }
  }, [
    toggleInterStateError,
    interStateToggleError?.data,
    interStateToggleError?.status,
  ]);

  useEffect(() => {
    if (toggleError && toggleInHouseError?.status === 401) {
      setToastMessage({
        message: toggleInHouseError?.data?.message || "Something went wrong",
        status: "error",
        heading: toggleInHouseError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (toggleError && toggleInHouseError?.status !== 401) {
      setIsInHouse(!isInHouse);
      setToastMessage({
        message: toggleInHouseError?.data?.message || "Something went wrong",
        status: "error",
        heading: toggleInHouseError?.data?.status || "Error",
      });
    }
  }, [toggleError, toggleInHouseError?.data, toggleInHouseError?.status]);

  // useEffect(() => {

  //   const addRateOnNewRateCreate
  //    const lastObject = defaultRatesValues[defaultRatesValues?.length - 1];

  //    if (lastObject?.city !== "" && lastObject?.price !== "") {
  //      await createCustomRate({
  //       city: lastObject?.city,
  //      price: lastObject?.price
  //      })
  //    } else {
  //    }
  // }, [defaultRatesValues?.length]);

  useEffect(() => {
    if (updateRateSuccess) {
      setToastMessage({
        message: "Rate Updated",
        status: "success",
        heading: "Success",
      });
    }
    if (updateRateError && rateUpdateError?.status === 401) {
      setToastMessage({
        message: rateUpdateError?.data?.message || "Something went wrong",
        status: "error",
        heading: rateUpdateError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (updateRateError && rateUpdateError?.status !== 401) {
      setToastMessage({
        message: rateUpdateError?.data?.message || "Something went wrong",
        status: "error",
        heading: rateUpdateError?.data?.status || "Error",
      });
    }
  }, [
    updateRateSuccess,
    updateRateError,
    rateUpdateError?.data,
    rateUpdateError?.status,
  ]);

  useEffect(() => {
    if (updateEntitySuccess) {
      setToastMessage({
        message: "Entity Updated",
        status: "success",
        heading: "Success",
      });
    }
    if (updateEntityError && entityUpdateError?.status === 401) {
      setToastMessage({
        message: entityUpdateError?.data?.message || "Something went wrong",
        status: "error",
        heading: entityUpdateError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (updateEntityError && entityUpdateError?.status !== 401) {
      setToastMessage({
        message: entityUpdateError?.data?.message || "Something went wrong",
        status: "error",
        heading: entityUpdateError?.data?.status || "Error",
      });
    }
  }, [
    updateEntitySuccess,
    updateEntityError,
    entityUpdateError?.data,
    entityUpdateError?.status,
  ]);

  useEffect(() => {
    if (createCustomRateSuccess) {
      setToastMessage({
        message: "Custom Rate Created",
        status: "success",
        heading: "Success",
      });
    }
    if (createCustomRateError && createCustomError?.status === 401) {
      setToastMessage({
        message: createCustomError?.data?.message || "Something went wrong",
        status: "error",
        heading: createCustomError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (createCustomRateError && createCustomError?.status !== 401) {
      setToastMessage({
        message: createCustomError?.data?.message || "Something went wrong",
        status: "error",
        heading: createCustomError?.data?.status || "Error",
      });
    }
  }, [
    createCustomRateSuccess,
    createCustomRateError,
    createCustomError?.data,
    createCustomError?.status,
  ]);

  useEffect(() => {
    if (createEntitySuccess) {
      setToastMessage({
        message: "New Entity Created",
        status: "success",
        heading: "Success",
      });
    }
    if (createEntityError && entityCreationError?.status === 401) {
      setToastMessage({
        message: entityCreationError?.data?.message || "Something went wrong",
        status: "error",
        heading: entityCreationError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (createEntityError && entityCreationError?.status !== 401) {
      setToastMessage({
        message: entityCreationError?.data?.message || "Something went wrong",
        status: "error",
        heading: entityCreationError?.data?.status || "Error",
      });
    }
  }, [
    createEntitySuccess,
    createEntityError,
    entityCreationError?.data,
    entityCreationError?.status,
  ]);

  useEffect(() => {
    if (deleteEntitySuccess) {
      setToastMessage({
        message: "Entity has been removed from shop",
        status: "success",
        heading: "Success",
      });
    }
    if (deleteEntityError && entityDeletionError?.status === 401) {
      setToastMessage({
        message: entityDeletionError?.data?.message || "Something went wrong",
        status: "error",
        heading: entityDeletionError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (deleteEntityError && entityDeletionError?.status !== 401) {
      setToastMessage({
        message: entityDeletionError?.data?.message || "Something went wrong",
        status: "error",
        heading: entityDeletionError?.data?.status || "Error",
      });
    }
  }, [
    deleteEntitySuccess,
    deleteEntityError,
    entityDeletionError?.data,
    entityDeletionError?.status,
  ]);

  // useEffect(() => {
  //   console.log("is", isInHouse);
  // }, [isInHouse]);

  // console.table("custom is", customInputValues);

  // console.table("original is", originalCustomValues);

  // console.log("normal is", customRates);

  return (
    <>
      {toggleInterStateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {toggleError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {updateRateSuccess || updateRateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {createCustomRateSuccess || createCustomRateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      {createEntitySuccess || createEntityError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {updateEntitySuccess || updateEntityError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      {deleteEntitySuccess || deleteEntityError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <section className={styles["delivery-rates"]}>
        <Form
          headerTitle={"Delivery Rates"}
          hasTitle={false}
          size={549}
          customChildren={
            <div className={styles["switch-container"]}>
              In House Sales
              <label
                className={`${styles.switch} ${
                  isInHouse ? styles["checked"] : ""
                }`}
                onClick={handleInHouseToggle}
              >
                <span className={styles.slider}></span>
              </label>
            </div>
          }
        >
          {isInHouse && (
            <div
              className={styles["tables-container"]}
              style={{ minHeight: rateDetailsLoading && 300 }}
            >
              <header>
                <h1 className={styles["title-main"]}>Tables/Rooms</h1>{" "}
                <button
                  onClick={() =>
                    setOpenContainers((prevState) => ({
                      ...prevState,
                      table: !prevState.table,
                    }))
                  }
                >
                  <Icon
                    icon={"angle-small-right"}
                    color={"shade-100"}
                    rotation={openContainers.table ? "rotate(90deg)" : ""}
                  />
                </button>
              </header>
              {tableDetailsLoading ? (
                <Loader size={70} />
              ) : (
                <>
                  {openContainers?.table && (
                    <>
                      <>
                        {tablesData.length > 0 && (
                          <div className={styles.top}>
                            <label>Title</label>
                            <label>Actions</label>
                          </div>
                        )}
                        {tablesData?.map((entity, index) => (
                          <div
                            className={styles["categories-container"]}
                            key={index}
                          >
                            <div
                              className={styles["categories-container_left"]}
                            >
                              <TextInput
                                hasLabel={false}
                                placeholder={"Title"}
                                value={tableInputValue[index]?.title}
                                onChange={(e) =>
                                  handleTableInputChange(
                                    index,
                                    "title",
                                    e.target.value,
                                    entity.id
                                  )
                                }
                              />
                            </div>
                            <div
                              className={styles["categories-container_right"]}
                            >
                              <span>
                                <button
                                  className={styles["actions-btn"]}
                                  onClick={() =>
                                    handleTableButtonClick(index, "check")
                                  }
                                  // disabled={
                                  //   entity?.id ||
                                  //   tableInputValue[index]?.title.trim() ===
                                  //     (null || "")
                                  // }
                                  disabled={
                                    tableInputValue?.length > 0 &&
                                    originalValues?.length > 0
                                      ? tableInputValue[index]?.title ===
                                          originalValues[index]?.title ||
                                        !tableInputValue[index]?.title
                                      : false
                                  }
                                >
                                  <Icon
                                    icon={
                                      index === entityLoadingIndex &&
                                      (updateEntityLoading ||
                                        createEntityLoading)
                                        ? "spinner-alt"
                                        : "check"
                                    }
                                    width={17}
                                    height={17}
                                    color={"white"}
                                  />
                                </button>
                                <button
                                  className={styles["actions-btn"]}
                                  onClick={() =>
                                    handleTableButtonClick(index, "trash")
                                  }
                                >
                                  <Icon
                                    icon={
                                      index === entityLoadingIndex &&
                                      deleteEntityLoading
                                        ? "spinner-alt"
                                        : "trash"
                                    }
                                    width={17}
                                    height={17}
                                    color={"error"}
                                  />
                                </button>
                              </span>
                            </div>
                          </div>
                        ))}
                      </>
                      <button
                        className={styles["product-btn"]}
                        onClick={handleAddTableClick}
                        disabled={
                          tablesData?.length === 0
                            ? false
                            : tablesData?.length === 1 &&
                              !lastTableEntityObject?.id
                            ? true
                            : !lastTableEntityObject?.id
                            ? true
                            : false
                        }
                      >
                        <span className={styles["product-btn__plus"]}>
                          <Icon icon={"plus-small"} color={"white"} />
                        </span>
                        {tablesDataCount < 1
                          ? "Add Table or Room"
                          : "Add Another Table or Room"}
                      </button>
                    </>
                  )}{" "}
                </>
              )}
            </div>
          )}
          {!isInHouse && (
            <>
              {" "}
              <div
                className={styles["all-rates-container"]}
                style={{ minHeight: rateDetailsLoading && 300 }}
              >
                <header className={styles.headerBox}>
                  <h1 className={styles["title-main"]}>Same State Delivery</h1>{" "}
                  <button
                    onClick={() =>
                      setOpenContainers((prevState) => ({
                        ...prevState,
                        defaultRates: !prevState.defaultRates,
                      }))
                    }
                  >
                    <Icon
                      icon={"angle-small-right"}
                      color={"shade-100"}
                      rotation={
                        openContainers.defaultRates ? "rotate(90deg)" : ""
                      }
                    />
                  </button>
                </header>
                {rateDetailsLoading ? (
                  <Loader size={70} />
                ) : (
                  <>
                    {" "}
                    {openContainers?.defaultRates && (
                      <>
                        {defaultRatesValues?.map((rate, containerIndex) => (
                          <div
                            className={styles["rates-container"]}
                            key={containerIndex}
                          >
                            <div className={styles["rates-container_left"]}>
                              <div className="half-width-form">
                                <TextInput
                                  hasLabel={
                                    !isPhoneScreen && containerIndex !== 0
                                      ? false
                                      : true
                                  }
                                  label={"City/Area"}
                                  placeholder={"City/Area"}
                                  value={inputValues[containerIndex]?.city}
                                  onChange={(e) =>
                                    handleInputChange(
                                      containerIndex,
                                      "city",
                                      e.target.value,
                                      rate.business_delivery_rate_id
                                    )
                                  }
                                  width={
                                    isPhoneScreen ? "calc(100% + 120px)" : ""
                                  }
                                  disabled={
                                    originalValues[containerIndex]?.city
                                  }
                                />
                                <ValueInput
                                  hasLabel={
                                    !isPhoneScreen && containerIndex !== 0
                                      ? false
                                      : true
                                  }
                                  label={"Price"}
                                  quantifier={"₦"}
                                  value={inputValues[containerIndex]?.price}
                                  onChange={(e) =>
                                    handleInputChange(
                                      containerIndex,
                                      "price",
                                      e.target.value,
                                      rate.business_delivery_rate_id
                                    )
                                  }
                                  placeholder={rate?.price}
                                />
                              </div>
                            </div>
                            <div className={styles["rates-container_right"]}>
                              {!isPhoneScreen && containerIndex === 0 ? (
                                <label>Actions</label>
                              ) : (
                                ""
                              )}
                              {isPhoneScreen && <label>Actions</label>}
                              <span>
                                <button
                                  className={styles["actions-btn"]}
                                  onClick={() =>
                                    handleButtonClick(containerIndex, "check")
                                  }
                                  disabled={
                                    (inputValues[containerIndex]?.city ===
                                      originalValues[containerIndex]?.city &&
                                      inputValues[containerIndex]?.price ===
                                        originalValues[containerIndex]
                                          ?.price) ||
                                    !inputValues[containerIndex]?.city ||
                                    !inputValues[containerIndex]?.price
                                  }
                                >
                                  <Icon
                                    icon={
                                      containerIndex === loadingIndex &&
                                      (updateLoading || createCustomRateLoading)
                                        ? "spinner-alt"
                                        : "check"
                                    }
                                    width={17}
                                    height={17}
                                    color={"white"}
                                  />
                                </button>
                                <button
                                  className={styles["actions-btn"]}
                                  onClick={() =>
                                    handleButtonClick(containerIndex, "trash")
                                  }
                                >
                                  <Icon
                                    icon={"trash"}
                                    width={17}
                                    height={17}
                                    color={
                                      defaultRatesValues?.length - 1 ===
                                        containerIndex &&
                                      !lastDefaultRatesObject.business_delivery_rate_id
                                        ? "error"
                                        : "shade-100"
                                    }
                                  />
                                </button>
                              </span>
                            </div>
                          </div>
                        ))}
                        <button
                          className={styles["product-btn"]}
                          onClick={handleAddDefaultRateClick}
                          disabled={
                            lastDefaultRatesObject?.business_delivery_rate_id
                              ? false
                              : true
                          }
                        >
                          <span className={styles["product-btn__plus"]}>
                            <Icon icon={"plus-small"} color={"white"} />
                          </span>
                          Add Another City
                        </button>{" "}
                      </>
                    )}{" "}
                  </>
                )}
              </div>
              <div className={styles["switch-container"]}>
                Deliver to Other States
                <label
                  className={`${styles.switch} ${
                    toOtherStates ? styles["checked"] : ""
                  }`}
                  onClick={handleToOtherStatesToggle}
                >
                  <span className={styles.slider}></span>
                </label>
              </div>
              {toOtherStates && (
                <div
                  className={styles["all-rates-container"]}
                  style={{ minHeight: rateDetailsLoading && 300 }}
                >
                  <header className={styles.headerBox}>
                    <h1 className={styles["title-main"]}>
                      Other States Delivery
                    </h1>{" "}
                    <button
                      onClick={() =>
                        setOpenContainers((prevState) => ({
                          ...prevState,
                          customRates: !prevState.customRates,
                        }))
                      }
                    >
                      <Icon
                        icon={"angle-small-right"}
                        color={"shade-100"}
                        rotation={
                          openContainers.customRates ? "rotate(90deg)" : ""
                        }
                      />
                    </button>
                  </header>
                  {rateDetailsLoading ? (
                    <Loader size={70} />
                  ) : (
                    <>
                      {" "}
                      {openContainers?.customRates && (
                        <>
                          {customRates.map((rate, index) => (
                            <div
                              key={index}
                              className={styles["rates-container2"]}
                            >
                              <div className={styles["rates-container_left"]}>
                                <div className="half-width-form">
                                  <CountrySelect
                                    placeholder={
                                      customRates[index]?.city || "Select State"
                                    }
                                    selectedOption={({ alpha2, name }) =>
                                      handleInputChangeCustom(
                                        index,
                                        "city",
                                        rate.business_delivery_rate_id,
                                        name
                                      )
                                    }
                                    hasLabel={
                                      !isPhoneScreen && index !== 0
                                        ? false
                                        : true
                                    }
                                    label={"State"}
                                    countryList={statesData?.data?.states.filter(
                                      (state) =>
                                        !customRates.some(
                                          (customRate) =>
                                            customRate.city === state.name
                                        )
                                    )}
                                    enabled={!rate?.business_delivery_rate_id}
                                    hasCountryCode={false}
                                    searchPlaceholder="Search State"
                                    type={"state"}
                                    width={
                                      isPhoneScreen ? "calc(100% + 120px)" : ""
                                    }
                                    top={"-520%"}
                                  />
                                  <ValueInput
                                    hasLabel={
                                      !isPhoneScreen && index !== 0
                                        ? false
                                        : true
                                    }
                                    label={"Price"}
                                    placeholder={rate?.price}
                                    quantifier={"₦"}
                                    value={customInputValues[index]?.price}
                                    onChange={(e) =>
                                      handleInputChangeCustom(
                                        index,
                                        "price",
                                        rate.business_delivery_rate_id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className={styles["rates-container_right"]}>
                                {!isPhoneScreen && index === 0 ? (
                                  <label>Actions</label>
                                ) : (
                                  ""
                                )}
                                {isPhoneScreen && <label>Actions</label>}

                                <span>
                                  <button
                                    className={styles["actions-btn"]}
                                    onClick={() =>
                                      handleCustomButtonClick(index, "check")
                                    }
                                    disabled={
                                      (customInputValues[index]?.state ===
                                        originalCustomValues[index]?.state &&
                                        customInputValues[index]?.price ===
                                          originalCustomValues[index]?.price) ||
                                      !customInputValues[index]?.city ||
                                      !customInputValues[index]?.price
                                    }
                                    // disabled={
                                    //   (rate?.business_delivery_rate_id &&
                                    //     customInputValues[index]?.state ===
                                    //       originalCustomValues[index]?.state &&
                                    //     customInputValues[index]?.cities[0]
                                    //       ?.price ===
                                    //       originalCustomValues[index]?.cities[0]
                                    //         ?.price) ||
                                    //   (!rate?.business_delivery_rate_id &&
                                    //     (!customInputValues[index]?.city ||
                                    //       !customInputValues[index]?.price))
                                    // }
                                  >
                                    <Icon
                                      icon={
                                        index === loadingIndexCustom &&
                                        (createCustomRateLoading ||
                                          updateLoading)
                                          ? "spinner-alt"
                                          : "check"
                                      }
                                      width={17}
                                      height={17}
                                      color={"white"}
                                    />
                                  </button>
                                  <button
                                    className={styles["actions-btn"]}
                                    onClick={() =>
                                      handleCustomButtonClick(index, "trash")
                                    }
                                  >
                                    <Icon
                                      icon={"trash"}
                                      width={17}
                                      height={17}
                                      color={
                                        customRates?.length - 1 === index &&
                                        !rate?.business_delivery_rate_id
                                          ? "error"
                                          : "shade-100"
                                      }
                                    />
                                  </button>
                                </span>
                              </div>
                            </div>
                          ))}
                          <button
                            className={styles["product-btn"]}
                            onClick={handleAddRateClick}
                            disabled={
                              customRates?.length === 0
                                ? false
                                : customRates?.length === 1 &&
                                  !lastCustomRatesObject?.business_delivery_rate_id
                                ? true
                                : !lastCustomRatesObject?.business_delivery_rate_id
                                ? true
                                : false
                            }
                            // disabled={
                            //   !lastCustomRatesObject?.business_delivery_rate_id
                            // }
                          >
                            <span className={styles["product-btn__plus"]}>
                              <Icon icon={"plus-small"} color={"white"} />
                            </span>
                            {ratesCount < 1
                              ? "Add a New State"
                              : "Add Another State"}
                          </button>{" "}
                        </>
                      )}{" "}
                    </>
                  )}
                </div>
              )}{" "}
            </>
          )}
        </Form>

        {/* <button onClick={logRefValues}>Log Ref Values</button> */}
      </section>
    </>
  );
};

export default DeliveryRates;
