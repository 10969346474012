import React, { useRef } from "react";
import Form from "../Onboarding/Steps/Form";
import Base from "../Onboarding/Base/Base";
import { TextInput } from "../../Components/Atoms/Input/Input";
import styles from "../Onboarding/Steps/Form.module.scss";
import classes from "../Onboarding/Onboarding.module.scss";
import { CountrySelect, Icon, Toast } from "../../Components/Atoms";
import { useStepFiveLogic } from "../Onboarding/Steps/Helpers/stepFive.helper";

const CreateCompany = ({ setNextStep }) => {
  const legalBusinessNameRef = useRef();
  const registrationNumberRef = useRef();
  const taxNumberRef = useRef();
  const postCodeRef = useRef();
  const aptStreetRef = useRef();

  const {
    data,
    isSuccess,
    isError,
    checked,
    setChecked,
    handleInput,
    checkEmpty,
    postalCode,
    citiesQuerySuccess,
    client,
    handleCitySelection,
    statesQuerySuccess,
    handleStateSelection,
    countriesFetchSuccess,
    handleCountrySelection,
    handleFormSubmit,
    isFetching,
  } = useStepFiveLogic(
    setNextStep,
    legalBusinessNameRef,
    registrationNumberRef,
    taxNumberRef,
    postCodeRef,
    aptStreetRef
  );

  return (
    <div className={classes.onboarding}>
      <Base
        classes={`${styles["steps-form"]} ${styles["organization-form"]}`}
        stepsPage={false}
      >
        <Form
          specialClass="organization"
          buttonLabel={isFetching ? "" : "Continue"}
          setNextStep={setNextStep}
          handleButtonClick={handleFormSubmit}
          icon={isFetching && "spinner-alt"}
          color={checked ? "completed" : "disabled"}
        >
          <div className={styles.inner}>
            <label className={styles.title}>Organization Information</label>
            <span className={styles.info}>
              We would use this information in our{" "}
              <strong>contracts and bills</strong> sent to you after signup
            </span>
            <TextInput
              ref={legalBusinessNameRef}
              hasLabel={true}
              label="Legal Business Name"
              placeholder="including Pty, AG, GmbH, etc"
              outline={
                checkEmpty &&
                legalBusinessNameRef.current.getValue().trim().length === 0
              }
              onInput={handleInput}
            />
            <TextInput
              ref={registrationNumberRef}
              hasLabel={true}
              label="Registration Number"
              placeholder="EIN, RC, etc."
              outline={
                checkEmpty &&
                registrationNumberRef.current.getValue().trim().length === 0
              }
              onInput={handleInput}
            />
            <TextInput
              ref={taxNumberRef}
              hasLabel={true}
              label="Tax Number"
              outline={
                checkEmpty &&
                taxNumberRef.current.getValue().trim().length === 0
              }
              onInput={handleInput}
            />
            <div className={styles.group}>
              <CountrySelect
                placeholder="Select Country"
                selectedOption={handleCountrySelection}
                label={"Country"}
                countryList={client.countries}
                searchPlaceholder="Search Country"
                enabled={countriesFetchSuccess ? true : false}
              />

              <CountrySelect
                placeholder="Select State"
                selectedOption={handleStateSelection}
                label={"State"}
                countryList={client.states}
                enabled={statesQuerySuccess ? true : false}
                hasCountryCode={false}
                searchPlaceholder="Search State"
              />
            </div>
            <div className={styles.group}>
              <CountrySelect
                placeholder="Select City"
                selectedOption={handleCitySelection}
                label={"Country/City"}
                countryList={client.cities}
                enabled={citiesQuerySuccess ? true : false}
                hasCountryCode={false}
                searchPlaceholder="Search City"
              />

              {postalCode ? (
                <TextInput
                  ref={postCodeRef}
                  hasLabel={true}
                  label="Post Code"
                  placeholder="e.g. LU4"
                  outline={
                    checkEmpty &&
                    postCodeRef.current.getValue().trim().length === 0
                  }
                  onInput={handleInput}
                />
              ) : (
                ""
              )}
            </div>
            <TextInput
              ref={aptStreetRef}
              hasLabel={true}
              label="Apt. + Street"
              placeholder="e.g 123 blecker street"
              outline={
                checkEmpty &&
                aptStreetRef.current.getValue().trim().length === 0
              }
              onInput={handleInput}
            />
          </div>
          <div className={styles["checkbox-container"]}>
            <span
              onClick={() => setChecked(!checked)}
              className={`${styles.icon} ${checked ? styles.checked : ""}`}
            >
              {checked ? (
                <Icon icon="check" color="success" width={8} height={8} />
              ) : (
                ""
              )}
            </span>
            <p className={checked ? styles.active : ""}>
              I hereby confirm that the information I have provided so far is,
              to the best of my knowledge, complete & accurate.
            </p>
          </div>
        </Form>
        {isError ? (
          <Toast
            status="error"
            heading="Error"
            message="Something went wrong"
            position="top-right"
          />
        ) : (
          ""
        )}
        {isSuccess && data.status !== "success" ? (
          <Toast
            status="error"
            heading="Error"
            message="One of your credentials is taken"
            position="top-right"
          />
        ) : (
          ""
        )}
      </Base>
    </div>
  );
};

export default React.memo(CreateCompany);
