import React, { useEffect, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import {
  SelectInput,
  TextInput,
  ValueInput,
} from "../../../Components/Atoms/Input/Input";
import { Button, Toast } from "../../../Components/Atoms";
import {
  useGetBanksListQuery,
  useLazyValidateAccountNumberQuery,
  useWithdrawMutation,
} from "../../../redux/services/client/accounts";
import { useSelector } from "react-redux";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router";

const Withdraw = () => {
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const client = useSelector((state) => state.client.value);

  const navigate = useNavigate();

  const { bank_detail: { bank_name, account_number, account_name } = {} } =
    client?.profileDetails?.business || {};

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });
  const [selectedBank, setSelectedBank] = useState({
    accountNumber: account_number,
    bankCode: "",
  });

  useEffect(() => {
    setSelectedBank({
      accountNumber: account_number,
      bankCode: "",
    });
    return () => {};
  }, []);

  const [checkError, setCheckError] = useState(false);

  const { message, status, heading } = toastMessage;

  const {
    data: banksListData,
    isError: getBankListError,
    error: bankListError,
  } = useGetBanksListQuery();

  const [
    accountDetails,
    {
      data: validateData,
      isFetching: accountValidating,
      isSuccess: validateAccountSuccess,
      isError: validateAccountError,
      error: validateError,
    },
  ] = useLazyValidateAccountNumberQuery();

  const [
    withdrawMoney,
    {
      data,
      isLoading: withdrawalLoading,
      isSuccess: withdawalSuccessful,
      isError: withdrawalError,
      error: withdrawError,
    },
  ] = useWithdrawMutation();

  const banksDropdownFormatter = (arr) => {
    return arr?.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  };

  const handleSelection = (option) => {
    setSelectedBank({
      ...selectedBank,
      bankCode: option.value,
    });
  };

  const handleInputChange = async (e) => {
    if (checkError) {
      setCheckError(false);
    }
    const newAccountNumber = e.target.value.trim();
    setSelectedBank({
      ...selectedBank,
      accountNumber: newAccountNumber,
    });

    if (newAccountNumber.length === 10) {
      await accountDetails({
        account_number: newAccountNumber,
        bank_code: selectedBank.bankCode,
      });
    }
  };

  const handleWithdraw = async () => {
    await withdrawMoney({
      amount: withdrawalAmount,
    });
  };

  useEffect(() => {
    if (getBankListError && bankListError?.status === 401) {
      setToastMessage({
        message: bankListError?.data?.message || "Something went wrong",
        status: "error",
        heading: bankListError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (getBankListError && bankListError?.status !== 401) {
      setToastMessage({
        message: bankListError?.data?.message || "Something went wrong",
        status: "error",
        heading: bankListError?.data?.status || "Error",
      });
    }
    return () => {};
  }, [getBankListError, bankListError?.status, bankListError?.data]);

  useEffect(() => {
    if (validateAccountError && validateError?.status === 401) {
      setToastMessage({
        message: validateError?.data?.message || "Something went wrong",
        status: "error",
        heading: validateError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (validateAccountError && validateError?.status !== 401) {
      setCheckError(true);
    }
    return () => {};
  }, [validateAccountError, validateError?.status, validateError?.data]);

  useEffect(() => {
    if (withdawalSuccessful) {
      setToastMessage({
        message: "Bank details updated successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (withdrawalError && withdrawError?.status === 401) {
      setToastMessage({
        message: withdrawError?.data?.message || "Something went wrong",
        status: "error",
        heading: withdrawError?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (withdrawalError && withdrawError?.status !== 401) {
      setToastMessage({
        message: withdrawError?.data?.message || "Something went wrong",
        status: "error",
        heading: withdrawError?.data?.status || "Error",
      });
    }

    return () => {};
  }, [
    withdawalSuccessful,
    withdrawalError,
    withdrawError?.data,
    withdrawError?.status,
  ]);

  return (
    <>
      {withdawalSuccessful || withdrawalError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <section className="min-height" style={{ width: "100%" }}>
        <Form headerTitle={"Withdraw"}>
          <div className="half-width-form">
            <SelectInput
              hasLabel
              label={"Bank/Financial Institution"}
              placeholder={bank_name || "Select Bank"}
              option={banksDropdownFormatter(banksListData?.data)}
              selectMenuType={"search"}
              selectedDynamicOption={handleSelection}
              maxWidth={"50%"}
              enabled={false}
            />
            <TextInput
              hasLabel
              label={"Account Number"}
              placeholder="0093456856"
              hasIcon
              icon={
                accountValidating
                  ? "spinner-alt"
                  : validateAccountError
                  ? "cross-small"
                  : validateAccountSuccess
                  ? "check"
                  : ""
              }
              iconPosition={"right"}
              iconColor={
                accountValidating
                  ? "shade-0"
                  : validateAccountError
                  ? "error"
                  : validateAccountSuccess
                  ? "success"
                  : ""
              }
              value={selectedBank?.accountNumber || account_number}
              onChange={handleInputChange}
              maxLength={10}
              outline={validateAccountError && checkError}
              disabled
            />
          </div>
          <TextInput
            hasLabel
            label={"Account Name"}
            placeholder="Your account name"
            value={validateData?.data?.account_name || account_name}
            disabled
          />
          <ValueInput
            hasLabel
            label={"Amount"}
            quantifier={"NGN"}
            value={withdrawalAmount}
            onChange={(e) => setWithdrawalAmount(e.target.value)}
          />
          <Button
            color={"completed"}
            height={36}
            label={withdrawalLoading ? "" : "Confirm & Withdraw"}
            hasIcon
            iconPosition="right"
            icon={withdrawalLoading ? "spinner-alt" : ""}
            handleClick={handleWithdraw}
          />
        </Form>
      </section>
    </>
  );
};

export default Withdraw;
