export const COMPANY_TYPES = [
  {
    label: "Private Incorporated Company",
    value: "Private Incorporated Company",
  },
  {
    label: "Public Incorporated Company",
    value: "Public Incorporated Company",
  },
  { label: "Free Zone Entity", value: "Free Zone Entity" },
  { label: "Business Name Registration", value: "Business Name Registration" },
  { label: "Incorporated Trustees", value: "Incorporated Trustees" },
  { label: "Government Entity", value: "Government Entity" },
  {
    label: "Private entity created by Government Approval",
    value: "Private entity created by Government Approval",
  },
  { label: "Cooperative Society", value: "Cooperative Society" },
];

export const INDUSTRIES = [
  { label: "Agriculture", value: "Agriculture" },
  { label: "Agricultural cooperatives", value: "Agricultural cooperatives" },
  { label: "Agricultural services", value: "Agricultural services" },
  {
    label: "Farm warehousing and storage",
    value: "Farm warehousing and storage",
  },
  { label: "Automobile", value: "Automobile" },
  { label: "Digital goods", value: "Digital goods" },
  { label: "Physical Goods", value: "Physical Goods" },
  { label: "Electronics", value: "Electronics" },
  { label: "Hardware", value: "Hardware" },
  { label: "Grocery and Supermarket", value: "Grocery and Supermarket" },
  { label: "Clothing and accessories", value: "Clothing and accessories" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Digital services", value: "Digital services" },
  { label: "Legal services", value: "Legal services" },
  { label: "General services", value: "General services" },
  { label: "Professional services", value: "Professional services" },
  { label: "Education", value: "Education" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Gaming", value: "Gaming" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Health", value: "Health" },
  { label: "Sports and Recreation", value: "Sports and Recreation" },
  { label: "Leisure & Entertainment", value: "Leisure & Entertainment" },
  { label: "Logistics", value: "Logistics" },
  { label: "Non-profits", value: "Non-profits" },
  { label: "Travel", value: "Travel" },
  { label: "Transport", value: "Transport" },
  { label: "Utilities", value: "Utilities" },
];

export const GENDER = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

export const VERIFICATION_METHODS = [
  {
    label: "NIN with Phone Number",
    value: "nin_with_phone",
  },
  {
    label: "National Identification Number",
    value: "nin",
  },
  {
    label: "Driver's License",
    value: "drivers_license",
  },
  {
    label: "International Passport",
    value: "passport",
  },
];
