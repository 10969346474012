import React, { createRef, useEffect, useRef, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import { Button, Icon, ProgressBar, Toast } from "../../../Components/Atoms";
import styles from "../_Main.module.scss";
import RTEditor from "../../../Components/Atoms/Input/InputTypes/MarkdownEditor/RTEditor";
import {
  SelectInput,
  TagInput,
  TextInput,
  ValueInput,
} from "../../../Components/Atoms/Input/Input";
import {
  useCreateAProductMutation,
  useGetAProductQuery,
  useGetAllCategoriesQuery,
} from "../../../redux/services/client/accounts";
import { useNavigate } from "react-router-dom";
import { clientPages } from "../../../Routes/routes";
import {
  VARIANT_COLORS,
  VARIANT_SIZES,
  VARIANT_VOLUME,
} from "./AddProduct.helper";
import VariantCard from "../../../Components/Cards/Variants/VariantCard";

const AddProduct = () => {
  const currentPath = window.location.pathname;
  const urlPaths = currentPath.split("/").filter((part) => part !== ""); // Filter out empty parts
  const isDuplicatePage =
    urlPaths[urlPaths.length - 2].toLowerCase() === "duplicate";
  const productID = currentPath.split("/").pop().toLowerCase();
  const [productDescription, setProductDescription] = useState("");
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });
  const [imageDataUrl, setImageDataUrl] = useState("");
  const [categoryID, setCategoryID] = useState(null);
  const [productTitle, setProductTitle] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [inventoryCount, setInventoryCount] = useState("");
  const [imageLoadProgress, setImageLoadProgress] = useState(0);
  const [variantsCount, setVariantsCount] = useState(0);
  const [showContainer, setShowContainer] = useState(false);
  const [isEmptyForm, setIsEmptyForm] = useState(false);
  const variants = Array.from({ length: variantsCount }, (_, index) => index);
  const [variantContainerKey, setVariantContainerKey] = useState(0);
  const [variantsChecked, setVariantsChecked] = useState({
    size: false,
    color: false,
    volume: false,
  });

  const variantContainerRef = useRef(null);

  const [productDetails, setProductDetails] = useState({
    size: "",
    color: "",
    volume: "",
  });

  const isVariantSelected =
    productDetails.color || productDetails.volume || productDetails.size;

  const { data: categoryDetails } = useGetAllCategoriesQuery();

  const [variantStates, setVariantStates] = useState(
    variants.map(() => ({
      size: "",
      color: {},
      volume: "",
      description: "",
      imageDataUrl: "",
      productsAvailable: "",
      price: "",
    }))
  );

  const [currentVariant, setCurrentVariant] = useState({
    productsAvailable: "",
    color: "",
    productPrice: "",
    productSize: "",
    productImage: "",
    productDescription: "",
    productVolume: "",
    index: "",
    colorName: "",
  });

  const isEditingVariant = Object.values(currentVariant).some(
    (value) => value !== ""
  );

  const navigate = useNavigate();

  const { message, status, heading } = toastMessage;

  const {
    data: duplicateProductDetails,
    isError: getProductError,
    error: productError,
  } = useGetAProductQuery(
    {
      id: productID,
    },
    { skip: !isDuplicatePage }
  );

  const {
    title,
    image: { url = {} } = {},
    description,
    price,
    inventory_count,
    variants: product_variants,
    color,
    size,
    volume,
    category,
  } = duplicateProductDetails?.data || {};

  const [
    createProduct,
    {
      isLoading,
      isSuccess: productCreateSuccess,
      isError: productCreateError,
      error,
    },
  ] = useCreateAProductMutation();

  const productData = {
    title: productTitle?.trim() || "",
    description: productDescription || "",
    price: productPrice || "",
    image: imageDataUrl || "",
    inventory_count: inventoryCount || "",
    business_category_id: categoryID || "",
    color: productDetails?.color || "",
    size: productDetails?.size || "",
    volume: productDetails?.volume || "",
  };

  const handleInputChange = (field, value) => {
    setProductDetails({
      ...productDetails,
      [field]: value,
    });
  };

  const handleAddVariant = () => {
    let lastVariant;

    if (variantStates) {
      lastVariant = variantStates[variantStates?.length - 1];
    }

    const hasNonEmptyValue =
      lastVariant &&
      Object.values(lastVariant).some((value) => {
        if (typeof value === "object" && value !== (null || undefined)) {
          return Object.values(value).some((nestedValue) => nestedValue !== "");
        }
        return value !== "";
      });
    if (!showContainer) {
      setShowContainer(true);
      setVariantContainerKey((prevKey) => prevKey + 1);
    } else {
      if (!hasNonEmptyValue) {
        // console.log("falsyyy");
        setIsEmptyForm(true);
      } else {
        if (isEmptyForm) {
          setIsEmptyForm(false);
        }
        setVariantsCount((prevCount) => prevCount + 1);

        setVariantStates((prevStates) => [
          ...prevStates,
          {
            size: "",
            color: {},
            volume: "",
            description: "",
            imageDataUrl: "",
            productsAvailable: "",
            price: "",
          },
        ]);
        setShowContainer(false);
      }
    }
  };

  const convertBase64 = (file, callback) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const base64 = fileReader.result.split(",")[1];
      callback(base64);
    };

    fileReader.readAsDataURL(file);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      convertBase64(file, (base64) => {
        const newImageDataUrl = `data:${file.type};base64,${base64}`;
        setImageDataUrl(newImageDataUrl);
      });
    }
  };

  const handleFileUploadForVariant = (event) => {
    const file = event.target.files[0];

    if (file) {
      convertBase64(file, (base64) => {
        const newImageDataUrl = `data:${file.type};base64,${base64}`;
        document.getElementById(
          "variantLabel"
        ).style.background = `linear-gradient(0deg, rgba(16, 18, 19, 0.70) 0%, rgba(16, 18, 19, 0.70) 100%), url(${newImageDataUrl}) lightgray 50% / cover no-repeat`;
        if (isEditingVariant) {
          setCurrentVariant((prevState) => ({
            ...prevState,
            productImage: newImageDataUrl,
          }));
        } else {
          setVariantStates((prevStates) => [
            ...prevStates.slice(0, prevStates.length - 1),
            {
              ...prevStates[prevStates.length - 1],
              imageDataUrl: newImageDataUrl,
            },
          ]);
        }
      });
    }
  };

  const handleCreateProduct = async () => {
    const updatedProductData = {
      ...productData,
      product_variants_attributes: variantStates
        .filter((variant) => {
          return Object.values(variant).some(
            (value) =>
              (typeof value !== "object" && value !== "") ||
              (typeof value === "object" &&
                value !== (null || undefined) &&
                Object.values(value).some((nestedValue) => nestedValue !== ""))
          );
        })
        .map((variant) => {
          return {
            size: variant.size || "",
            color: variant.color?.value || "",
            description: variant.description || "",
            price: variant.price || "",
            inventory_count: variant.productsAvailable || "",
            image: variant.imageDataUrl || "",
            volume: variant.volume || "",
          };
        }),
    };

    await createProduct(updatedProductData);
  };

  const handleVariantEdit = ({
    productsAvailable,
    color,
    productPrice,
    productSize,
    productImage,
    productDescription,
    productVolume,
    index,
    colorName,
  }) => {
    if (!showContainer) {
      setShowContainer(true);
    }
    setCurrentVariant({
      productsAvailable,
      color,
      productPrice,
      productSize,
      productImage,
      productDescription,
      productVolume,
      index,
      colorName,
    });
  };

  const handleDelete = ({ index }) => {
    setVariantStates((prevStates) => {
      const updatedVariants = [...prevStates];
      updatedVariants.splice(index, 1);
      return updatedVariants;
    });
  };

  const handleUpdateVariant = () => {
    if (currentVariant.index !== "") {
      const indexToUpdate = parseInt(currentVariant.index, 10);
      const currentVariants = {
        size: currentVariant.productSize,
        color: currentVariant.productColor,
        volume: currentVariant.productVolume,
        description: currentVariant.productDescription,
        imageDataUrl: currentVariant.productImage,
        productsAvailable: currentVariant.productsAvailable,
        price: currentVariant.productPrice,
      };

      const nonEmptyKeys = Object.keys(currentVariants).filter(
        (key) => currentVariants[key] !== ""
      );

      setVariantStates((prevStates) => {
        const updatedVariants = [...prevStates];
        const updatedVariant = { ...updatedVariants[indexToUpdate] };

        nonEmptyKeys.forEach((key) => {
          updatedVariant[key] = currentVariants[key];
        });

        updatedVariants[indexToUpdate] = updatedVariant;

        return updatedVariants;
      });
      setCurrentVariant({
        productsAvailable: "",
        productColor: "",
        productPrice: "",
        productSize: "",
        productImage: "",
        productDescription: "",
        productVolume: "",
        index: "",
        colorName: "",
      });

      setShowContainer(false);
    }
  };

  const handleVariantCheck = (field) => {
    setVariantsChecked((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const formatCategories = (categories) => {
    return categories?.map((category) => ({
      label: category.title || "",
      value: category.id || "",
    }));
  };

  const formatProductVariants = (variants) => {
    return variants?.map((variant) => ({
      size: variant.size || "",
      color: { value: variant.color } || {},
      volume: variant.volume || "",
      description: "",
      imageDataUrl: "",
      productsAvailable: variant.inventory_count || "",
      price: variant.price || "",
      id: variant.id || "",
    }));
  };

  // console.log(productDetails);

  useEffect(() => {
    if (isDuplicatePage) {
      setProductTitle("");
      setProductDescription("");
      setProductPrice(price);
      setInventoryCount(inventory_count);
      setProductDetails({
        color: color || "",
        size: size || "",
        volume: volume || "",
      });
      setVariantsChecked({
        size: size ? true : false,
        color: color ? true : false,
        volume: volume ? true : false,
      });
      setCategoryID(category?.id);
      setImageDataUrl("");
      if (product_variants?.length > 0) {
        const variants = formatProductVariants(product_variants);
        variants?.push({
          size: "",
          color: {},
          volume: "",
          description: "",
          imageDataUrl: "",
          productsAvailable: "",
          price: "",
        });
        setVariantStates(variants);
        setVariantsCount(product_variants?.length + 1);
      }
    }
  }, [duplicateProductDetails]);

  useEffect(() => {
    if (showContainer) {
      const containerRef = variantContainerRef?.current;

      if (containerRef) {
        containerRef.scrollTop = 0;
      }
    }

    return () => {};
  }, [showContainer]);

  // useEffect(() => {
  //   let interval;

  //   if (imageDataUrl !== "" && imageDataUrl !== undefined) {
  //     interval = setInterval(() => {
  //       if (imageLoadProgress < 100) {
  //         setImageLoadProgress((prevState) => (prevState += 10));
  //       } else {
  //         setImageLoadProgress(0);
  //         clearInterval(interval);
  //       }
  //     }, 50);
  //   }

  //   return () => {
  //     setImageLoadProgress(0);
  //     clearInterval(interval);
  //   };
  // }, [imageDataUrl]);

  useEffect(() => {
    if (isEmptyForm) {
      setToastMessage({
        message: "Form is empty, cannot another variant",
        status: "error",
        heading: "Error",
      });
    }

    // console.log(variantStates);

    return () => {};
  }, [isEmptyForm]);

  let errorMessage = "Something went wrong";
  let errorStatus = "error";
  let errorHeading = "Error";

  useEffect(() => {
    if (productCreateSuccess) {
      setToastMessage({
        message: "Product created successfully",
        status: "success",
        heading: "Success",
      });
      setTimeout(() => {
        navigate(clientPages.dashboard);
      }, 3000);
    }
    if (productCreateError) {
      if (error?.status === 401) {
        setToastMessage({
          message: error?.data?.message || "Something went wrong",
          status: "error",
          heading: error?.data?.status || "Error",
        });
        localStorage.removeItem("authToken");
        setTimeout(() => {
          navigate(clientPages.login);
        }, 2000);
      } else if (error?.status === 422) {
        const errorData = error?.data?.data;

        if (error?.data?.status) {
          errorHeading = `: ${error?.data?.status}`;
        }

        if (errorData) {
          const firstError = errorData;

          Object.keys(firstError).forEach((key) => {
            errorMessage = `\n${key.toUpperCase()}: ${firstError[key]}`;
            console.log(`\n${key}: ${firstError[key]}`);
          });
        }

        setToastMessage({
          message: errorMessage || "Something went wrong",
          status: errorStatus,
          heading: errorHeading || "Error",
        });
      } else {
        setToastMessage({
          message: error?.data?.message || "Something went wrong",
          status: "error",
          heading: error?.data?.status || "Error",
        });
      }
    }
  }, [
    productCreateSuccess,
    productCreateError,
    error,
    error?.status,
    error?.data?.data,
  ]);

  // console.log("states is", variantStates);

  return (
    <>
      {productCreateSuccess || productCreateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="bottom-right"
        />
      ) : (
        ""
      )}
      {isEmptyForm ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <section className={styles["add-product"]}>
        <Form
          headerTitle={isDuplicatePage ? "Duplicate Product" : "Add A Product"}
          hasTitle={false}
        >
          <label
            htmlFor="browseFiles"
            className={styles["product-btn"]}
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundAttachment: "fixed",
              background:
                "linear-gradient(0deg, rgba(16, 18, 19, 0.70) 0%, rgba(16, 18, 19, 0.70) 100%), url(" +
                imageDataUrl +
                ") lightgray 50% / cover no-repeat",
            }}
          >
            <input
              id="browseFiles"
              type="file"
              accept=".jpg, .jpeg, .png, .webm"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <span className={styles["product-btn__plus"]}>
              <Icon icon={"plus-small"} color={"white"} />
            </span>
            {imageDataUrl ? "Change product image" : "Upload product image"}
          </label>
          {imageLoadProgress > 0 && imageLoadProgress < 100 && (
            <ProgressBar progress={`${imageLoadProgress}%`} />
          )}
          <h1 className="form-title-main">Product Information</h1>
          <SelectInput
            hasIcon
            hasLabel
            enabled={categoryDetails?.data?.length > 0}
            label={"Category"}
            placeholder={category?.title || "Select Category"}
            option={formatCategories(categoryDetails?.data)}
            selectedOption={(item) => setCategoryID(item.value)}
          />
          <TextInput
            hasLabel
            label={"Title"}
            placeholder="eg. 50KG Bag of Rice"
            value={productTitle}
            onChange={(e) => setProductTitle(e.target.value)}
          />
          <div className="half-width-form">
            <ValueInput
              hasLabel
              label={"Price"}
              quantifier={"NGN"}
              value={productPrice}
              onChange={(e) =>
                setProductPrice(e.target.value.trim().replace(/,/g, "0000"))
              }
            />
            <ValueInput
              hasLabel
              label={"Total Products Available"}
              quantifier={"#"}
              value={inventoryCount}
              onChange={(e) => setInventoryCount(e.target.value.trim())}
            />
          </div>
          <div className="description-long">
            <label>Description</label>
            <textarea
              value={productData.productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
              placeholder="Enter description here"
            />
          </div>
          <div className={"checkbox-container"}>
            <span
              onClick={() => handleVariantCheck("size")}
              className={`icon ${variantsChecked.size ? "checked" : ""}`}
            >
              {variantsChecked.size ? (
                <Icon icon="check" color="success" width={8} height={8} />
              ) : (
                ""
              )}
            </span>
            <p className={variantsChecked.size ? "active" : ""}>
              This product has different sizes
            </p>
          </div>
          {variantsChecked?.size && (
            <TagInput
              label={"Size"}
              placeholder={size || "Enter or select a size"}
              allTags={(size) => handleInputChange("size", size)}
              suggestion={VARIANT_SIZES}
              hasLabel
              type={"productVariant"}
            />
          )}
          <div className={"checkbox-container"}>
            <span
              onClick={() => handleVariantCheck("color")}
              className={`icon ${variantsChecked.color ? "checked" : ""}`}
            >
              {variantsChecked.color ? (
                <Icon icon="check" color="success" width={8} height={8} />
              ) : (
                ""
              )}
            </span>
            <p className={variantsChecked.color ? "active" : ""}>
              This product has different colors
            </p>
          </div>
          {variantsChecked?.color && (
            <SelectInput
              hasIcon
              hasLabel
              label={"Color"}
              placeholder={
                color ? (
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 6,
                      marginLeft: "-4px",
                    }}
                  >
                    {" "}
                    <span
                      className={styles["dropdown-icon-container"]}
                      style={{
                        width: 16,
                        height: 16,
                        minWidth: 16,
                        minHeight: 16,
                        maxWidth: 16,
                        maxHeight: 16,
                        background: color,
                        borderRadius: 16,
                      }}
                    ></span>{" "}
                    {VARIANT_COLORS.find((item) => item.value === color)?.label}
                  </p>
                ) : (
                  "Choose Color"
                )
              }
              option={VARIANT_COLORS}
              enabled
              selectedOption={(color) =>
                handleInputChange("color", color.value)
              }
              hasListIcon
            />
          )}
          <div className={"checkbox-container"}>
            <span
              onClick={() => handleVariantCheck("volume")}
              className={`icon ${variantsChecked.volume ? "checked" : ""}`}
            >
              {variantsChecked.volume ? (
                <Icon icon="check" color="success" width={8} height={8} />
              ) : (
                ""
              )}
            </span>
            <p className={variantsChecked.volume ? "active" : ""}>
              This product has different volumes
            </p>
          </div>
          {variantsChecked?.volume && (
            <TagInput
              label={"Volume"}
              placeholder={volume || "Enter or select a volume"}
              allTags={(volume) => handleInputChange("volume", volume)}
              suggestion={VARIANT_VOLUME}
              hasLabel
              type={"productVariant"}
            />
          )}

          {isVariantSelected && (
            <div className={styles["flex-col-8"]}>
              <h1 className="form-title-main">Product Variants</h1>
              <label style={{ color: "var(--shade-0)", marginTop: -8 }}>
                If your product comes in various sizes, colors, or volumes, you
                can easily add different variants here. Customize each variant
                with specific descriptions and images to showcase all available
                options.
              </label>
              <>
                {isDuplicatePage ? (
                  <>
                    {variantStates
                      ?.slice(0, variantStates.length - 1)
                      .map((variant, index) => (
                        <VariantCard
                          key={index}
                          index={index}
                          productColor={variant.color}
                          productPrice={variant.price}
                          productSize={variant.size}
                          productsAvailable={variant.productsAvailable}
                          productImage={variant.imageDataUrl}
                          productDescription={variant.description}
                          productVolume={variant.volume}
                          variantInfo={handleVariantEdit}
                          color={variant?.color?.value}
                          colorName={variant?.color?.label}
                          handleDelete={handleDelete}
                          display={
                            isEditingVariant && index === currentVariant.index
                              ? "none"
                              : ""
                          }
                          isDuplicatePageandEmpty={
                            isDuplicatePage &&
                            !variantStates[index]?.imageDataUrl
                              ? "valid"
                              : ""
                          }
                        />
                      ))}
                  </>
                ) : (
                  <>
                    {variantStates
                      ?.slice(0, variantStates.length - 1)
                      .map((variant, index) => (
                        <VariantCard
                          key={index}
                          index={index}
                          productColor={variant.color}
                          productPrice={variant.price}
                          productSize={variant.size}
                          productsAvailable={variant.productsAvailable}
                          productImage={variant.imageDataUrl}
                          productDescription={variant.description}
                          productVolume={variant.volume}
                          variantInfo={handleVariantEdit}
                          color={variant?.color?.value}
                          colorName={variant?.color?.label}
                          handleDelete={handleDelete}
                          display={
                            isEditingVariant && index === currentVariant.index
                              ? "none"
                              : ""
                          }
                        />
                      ))}
                  </>
                )}
              </>

              {showContainer && (
                <div
                  key={variantContainerKey}
                  className={styles["variant-container"]}
                >
                  <label
                    id="variantLabel"
                    htmlFor={`browseFiles_${variantStates?.length + 1}`}
                    className={styles["product-btn"]}
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundAttachment: "fixed",
                      background:
                        "linear-gradient(0deg, rgba(16, 18, 19, 0.70) 0%, rgba(16, 18, 19, 0.70) 100%), url(" +
                        currentVariant.productImage +
                        ") lightgray 50% / cover no-repeat",
                    }}
                  >
                    <input
                      id={`browseFiles_${variantStates?.length + 1}`}
                      type="file"
                      accept=".jpg, .jpeg, .png, .webm"
                      onChange={(event) => handleFileUploadForVariant(event)}
                      style={{ display: "none" }}
                    />
                    <span
                      className={styles["product-btn__plus"]}
                      ref={variantContainerRef}
                    >
                      <Icon icon={"plus-small"} color={"white"} />
                    </span>
                    {isEditingVariant
                      ? "Change variant image"
                      : "Upload variant image"}
                  </label>
                  {productDetails?.size && (
                    <TagInput
                      label={"Size"}
                      placeholder={
                        isEditingVariant
                          ? currentVariant.productSize
                          : "Select size category"
                      }
                      allTags={(size) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productSize: size,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              size,
                            },
                          ]);
                        }
                      }}
                      suggestion={VARIANT_SIZES}
                      hasLabel
                      type={"productVariant"}
                    />
                  )}
                  <div className="half-width-form">
                    {productDetails?.color && (
                      <SelectInput
                        hasIcon
                        hasLabel
                        label={"Color"}
                        placeholder={
                          isEditingVariant ? (
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 6,
                                marginLeft: "-4px",
                              }}
                            >
                              {" "}
                              <span
                                className={styles["dropdown-icon-container"]}
                                style={{
                                  width: 16,
                                  height: 16,
                                  minWidth: 16,
                                  minHeight: 16,
                                  maxWidth: 16,
                                  maxHeight: 16,
                                  background: currentVariant?.color,
                                  borderRadius: 16,
                                }}
                              ></span>{" "}
                              {currentVariant?.colorName}{" "}
                            </p>
                          ) : (
                            "Select"
                          )
                        }
                        option={VARIANT_COLORS}
                        enabled
                        selectedOption={(variantColor) => {
                          if (isEditingVariant) {
                            setCurrentVariant((prevState) => ({
                              ...prevState,
                              productColor: variantColor,
                            }));
                          } else {
                            setVariantStates((prevStates) => [
                              ...prevStates.slice(0, prevStates.length - 1),
                              {
                                ...prevStates[prevStates.length - 1],
                                color: variantColor,
                              },
                            ]);
                          }
                        }}
                        hasListIcon
                      />
                    )}

                    {productDetails?.volume && (
                      <TagInput
                        label={"Volume"}
                        placeholder={
                          isEditingVariant
                            ? currentVariant.productVolume
                            : "Select"
                        }
                        allTags={(volume) => {
                          if (isEditingVariant) {
                            setCurrentVariant((prevState) => ({
                              ...prevState,
                              productVolume: volume,
                            }));
                          } else {
                            setVariantStates((prevStates) => [
                              ...prevStates.slice(0, prevStates.length - 1),
                              {
                                ...prevStates[prevStates.length - 1],
                                volume,
                              },
                            ]);
                          }
                        }}
                        suggestion={VARIANT_VOLUME}
                        hasLabel
                        type={"productVariant"}
                      />
                    )}
                  </div>
                  <div className="half-width-form">
                    <ValueInput
                      hasLabel
                      label={"Price"}
                      quantifier={"NGN"}
                      onChange={(e) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productPrice: e.target.value,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              price: e.target.value,
                            },
                          ]);
                        }
                      }}
                      placeholder={
                        isEditingVariant && currentVariant?.productPrice
                      }
                    />
                    <ValueInput
                      hasLabel
                      label={"Total Products Available"}
                      quantifier={"#"}
                      onChange={(e) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productsAvailable: e.target.value,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              productsAvailable: e.target.value,
                            },
                          ]);
                        }
                      }}
                      placeholder={
                        isEditingVariant && currentVariant?.productsAvailable
                      }
                    />
                  </div>
                  <div className="description-long">
                    <label>Description</label>
                    <textarea
                      onChange={(e) => {
                        if (isEditingVariant) {
                          setCurrentVariant((prevState) => ({
                            ...prevState,
                            productDescription: e.target.value,
                          }));
                        } else {
                          setVariantStates((prevStates) => [
                            ...prevStates.slice(0, prevStates.length - 1),
                            {
                              ...prevStates[prevStates.length - 1],
                              description: e.target.value,
                            },
                          ]);
                        }
                      }}
                      placeholder={
                        isEditingVariant
                          ? currentVariant?.productDescription
                          : "Enter description here"
                      }
                    />
                  </div>
                  {isEditingVariant && (
                    <div className={styles["create-variant-btn"]}>
                      {" "}
                      <Button
                        color={"completed-opacity"}
                        label={"Save Changes"}
                        height={36}
                        size={"fit-content"}
                        handleClick={handleUpdateVariant}
                      />{" "}
                    </div>
                  )}
                </div>
              )}
              {!isEditingVariant && (
                <button className="product-btn-2" onClick={handleAddVariant}>
                  <span className="product-btn__plus">
                    <Icon icon={"plus-small"} color={"white"} />
                  </span>
                  {showContainer
                    ? "Create Variant"
                    : !showContainer && variantsCount > 0
                    ? "Add Another Variant"
                    : "Add Variants"}
                </button>
              )}
            </div>
          )}
          {isVariantSelected && variantStates.length < 2 && (
            <label className={styles.warning}>
              <Icon
                icon={"shield-exclamation"}
                color={"orange"}
                width={24}
                height={24}
              />{" "}
              <p>
                Products with sizes, colors or volumes usually have multiple
                variants to show other options, like <b>shoes</b> with sizes{" "}
                <b>42</b>,<b>43</b>, <b>44</b>, etc. You can continue and{" "}
                <b>add them later</b>{" "}
              </p>
            </label>
          )}
          <Button
            color={isLoading ? "disabled" : "completed"}
            label={
              isLoading
                ? ""
                : isDuplicatePage
                ? "Create Duplicate"
                : "Create product"
            }
            hasIcon
            iconPosition="right"
            icon={isLoading ? "spinner-alt" : ""}
            height={36}
            handleClick={handleCreateProduct}
          />
        </Form>
      </section>
    </>
  );
};

export default AddProduct;
