import React, { useEffect, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import styles from "./_Main.module.scss";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import Phone from "../../../Components/Atoms/Input/InputTypes/Phone/Phone";
import Select from "../../../Components/Atoms/Input/InputTypes/Select/Select";
import {
  Button,
  CountrySelect,
  Icon,
  Loader,
  ProgressBar,
  Toast,
} from "../../../Components/Atoms";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../redux/services/client/accounts";
import {
  useGetCitiesListQuery,
  useGetCountriesListQuery,
  useGetStatesListQuery,
} from "../../../redux/services/client/onboarding";
import { useDispatch, useSelector } from "react-redux";
import { updateClientData } from "../../../redux/features/newClient";
import { COMPANY_TYPES, INDUSTRIES } from "./EditProfile.helpers";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router";

const EditBusiness = () => {
  const [imageLoadProgress, setImageLoadProgress] = useState(0);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });
  const { data: profileDetails, isFetching: profileFetching } =
    useGetProfileQuery();

  const navigate = useNavigate();

  const {
    business: {
      legal_name,
      industry,
      company_type,
      tax_number,
      registration_number,
      description,
      logo,
      address: { country, state, city, post_code, street } = {},
    } = {},
  } = profileDetails?.data || {};

  const [imageDataUrl, setImageDataUrl] = useState(logo?.url || "");
  const [selectedCompanyType, setSelectedCompanyType] = useState(
    company_type || ""
  );
  const [selectedIndustry, setSelectedIndustry] = useState(industry || "");

  const [
    updateProfile,
    {
      isLoading,
      isSuccess: profileUpdateSuccess,
      isError: profileUpdateError,
      error,
    },
  ] = useUpdateProfileMutation();

  const { message, status, heading } = toastMessage;

  const [formData, setFormData] = useState({
    legalName: legal_name,
    taxNumber: tax_number,
    registrationNumber: registration_number,
    postCode: post_code,
    streetAddress: street,
    businessDescription: description || "",
  });

  const [selectedCountry, setSelectedCountry] = useState({
    countryName: country,
    postalCode: null,
    alpha2: "NG",
  });

  const [selectedState, setSelectedState] = useState({
    stateName: state,
    stateCode: null,
  });

  const [selectedCity, setSelectedCity] = useState({
    cityName: city,
  });

  useEffect(() => {
    setFormData({
      legalName: legal_name,
      taxNumber: tax_number,
      registrationNumber: registration_number,
      postCode: post_code,
      streetAddress: street,
      businessDescription: description || "",
    });
    setImageDataUrl(logo?.url);
    setSelectedCountry({
      countryName: country,
      postalCode: null,
      alpha2: "NG",
    });
    setSelectedState({
      stateName: state,
      stateCode: null,
    });
    setSelectedCity({
      cityName: city,
    });

    return () => {};
  }, [profileDetails]);

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const client = useSelector((state) => state.client.value);
  const dispatch = useDispatch();

  const { countryName, alpha2, postalCode } = selectedCountry;
  const { stateName, stateCode } = selectedState;
  const { cityName } = selectedCity;

  const stateDropdownFormatter = (arr) => {
    return arr.map((item) => ({
      name: item?.name,
      alpha2: item?.code,
    }));
  };

  const {
    data: countriesData,
    isSuccess: countriesFetchSuccess,
    isError: countriesFetchError,
    refetch,
  } = useGetCountriesListQuery();

  useEffect(() => {
    if (countriesFetchSuccess) {
      dispatch(
        updateClientData({
          countries: countriesData.data.countries,
        })
      );
    }
    return () => {};
  }, [countriesFetchSuccess, countriesFetchError]);

  const {
    data: statesData,
    isSuccess: statesQuerySuccess,
    isError: statesQueryError,
    refetch: refetchStates,
  } = useGetStatesListQuery(
    {
      country_alpha2: alpha2,
    },
    { skip: !alpha2 }
  );

  useEffect(() => {
    if (statesQuerySuccess) {
      dispatch(
        updateClientData({
          states: stateDropdownFormatter(statesData.data.states),
        })
      );
    }
    return () => {};
  }, [statesQuerySuccess, statesData, statesQueryError]);

  const {
    data: citiesData,
    isSuccess: citiesQuerySuccess,
    isError: citiesQueryError,
    refetch: refetchCities,
  } = useGetCitiesListQuery(
    {
      country_alpha2: alpha2,
      state_code: stateCode,
    },
    { skip: !stateCode }
  );

  useEffect(() => {
    if (citiesQuerySuccess) {
      dispatch(
        updateClientData({
          cities: citiesData.data.cities,
        })
      );
    }
    return () => {};
  }, [citiesQuerySuccess, citiesData, citiesQueryError]);

  const handleCountrySelection = ({ alpha2, postal_code, name }) => {
    setSelectedCountry({
      countryName: name,
      alpha2: alpha2,
      postalCode: postal_code,
    });
  };

  const handleStateSelection = ({ alpha2, name }) => {
    setSelectedState({
      stateName: name,
      stateCode: alpha2,
    });
  };

  const handleCitySelection = ({ name }) => {
    setSelectedCity({
      cityName: name,
    });
  };

  const handleUpdateProfile = async () => {
    const updatedProfileData = {};

    if (formData.legalName !== legal_name) {
      updatedProfileData.legal_name = formData.legalName.trim();
    }

    if (formData?.businessDescription !== description) {
      updatedProfileData.description = formData?.businessDescription;
    }

    if (selectedCompanyType !== company_type) {
      updatedProfileData.company_type = selectedCompanyType;
    }

    if (selectedIndustry !== industry) {
      updatedProfileData.industry = selectedIndustry;
    }

    if (formData.taxNumber !== tax_number) {
      updatedProfileData.tax_number = formData.taxNumber.trim();
    }

    if (formData.registrationNumber !== registration_number) {
      updatedProfileData.registration_number =
        formData.registrationNumber.trim();
    }

    if (imageDataUrl !== logo?.url) {
      updatedProfileData.logo = imageDataUrl;
    }

    if (countryName !== country) {
      updatedProfileData.address_attributes = {
        ...updatedProfileData.address_attributes,
        country: countryName,
      };
    }

    if (stateName !== state) {
      updatedProfileData.address_attributes = {
        ...updatedProfileData.address_attributes,
        state: stateName,
      };
    }

    if (cityName !== city) {
      updatedProfileData.address_attributes = {
        ...updatedProfileData.address_attributes,
        city: cityName,
      };
    }

    if (formData.postCode !== post_code) {
      updatedProfileData.address_attributes = {
        ...updatedProfileData.address_attributes,
        post_code: formData.postCode.trim(),
      };
    }

    if (formData.streetAddress !== street) {
      updatedProfileData.address_attributes = {
        ...updatedProfileData.address_attributes,
        street: formData.streetAddress.trim(),
      };
    }

    if (Object.keys(updatedProfileData).length > 0) {
      await updateProfile(updatedProfileData);
    }
  };

  const convertBase64 = (file, callback) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const base64 = fileReader.result.split(",")[1];
      callback(base64);
    };

    fileReader.readAsDataURL(file);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      convertBase64(file, (base64) => {
        const newImageDataUrl = `data:${file.type};base64,${base64}`;
        setImageDataUrl(newImageDataUrl);
      });
    }
  };

  useEffect(() => {
    if (
      imageDataUrl !== logo?.url &&
      imageDataUrl !== "" &&
      imageDataUrl !== undefined
    ) {
      const interval = setInterval(() => {
        if (imageLoadProgress < 100) {
          setImageLoadProgress((prevState) => (prevState += 10));
        } else {
          setImageLoadProgress(0);
          clearInterval(interval);
        }
      }, 500);
    }

    return () => {};
  }, [imageDataUrl]);

  useEffect(() => {
    if (profileUpdateSuccess) {
      setImageDataUrl("");
      setToastMessage({
        message: "Profile updated successfully",
        status: "success",
        heading: "Success",
      });
    }

    if (profileUpdateError && error?.status === 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    } else if (profileUpdateError && !error?.status !== 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [profileUpdateSuccess, profileUpdateError, error?.data, error?.status]);

  return (
    <>
      {profileUpdateSuccess || profileUpdateError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="bottom-right"
        />
      ) : (
        ""
      )}
      <section className={styles["edit-business"]}>
        {!profileDetails ? (
          <Loader size={70} />
        ) : (
          <Form
            headerTitle={"Editing Business"}
            title={"Business Information"}
            size={549}
          >
            <label
              htmlFor="browseFiles"
              className={styles["product-btn"]}
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundAttachment: "fixed",
                background:
                  "linear-gradient(0deg, rgba(16, 18, 19, 0.70) 0%, rgba(16, 18, 19, 0.70) 100%), url(" +
                  imageDataUrl +
                  ") lightgray 50% / cover no-repeat",
              }}
            >
              <input
                id="browseFiles"
                type="file"
                accept=".jpg, .jpeg, .png, .webm"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <span className={styles["product-btn__plus"]}>
                <Icon icon={"plus-small"} color={"white"} />
              </span>
              {imageDataUrl ? "Change company logo" : "Upload company logo"}
            </label>
            {imageLoadProgress > 0 && imageLoadProgress < 100 && (
              <ProgressBar progress={`${imageLoadProgress}%`} />
            )}
            <TextInput
              hasLabel
              label={"Legal/Registered Business Name"}
              value={formData.legalName}
              onChange={(e) => handleInputChange("legalName", e.target.value)}
            />
            <div className="description-long">
              <label>Description</label>
              <textarea
                value={formData.businessDescription}
                onChange={(e) =>
                  handleInputChange("businessDescription", e.target.value)
                }
                placeholder="Enter description here"
              />
            </div>
            <div className="half-width-form">
              <Select
                hasLabel
                label={"Industry"}
                placeholder={industry || "Select"}
                enabled
                options={INDUSTRIES}
                selectedOption={(item) => setSelectedIndustry(item.label)}
              />
              <Select
                hasLabel
                label={"Company Type"}
                placeholder={company_type || "Select"}
                enabled
                options={COMPANY_TYPES}
                selectedOption={(item) => setSelectedCompanyType(item.label)}
              />
            </div>
            <div className="half-width-form">
              <TextInput
                hasLabel
                label={"Tax Number"}
                value={formData.taxNumber}
                onChange={(e) => handleInputChange("taxNumber", e.target.value)}
              />
              <TextInput
                hasLabel
                label={"Registration Number"}
                value={formData.registrationNumber}
                onChange={(e) =>
                  handleInputChange("registrationNumber", e.target.value)
                }
              />
            </div>
            <h1 className={styles["title-main"]}>Operational Address</h1>
            <div className="half-width-form">
              <CountrySelect
                placeholder={country}
                selectedOption={handleCountrySelection}
                label={"Country"}
                countryList={client.countries}
                searchPlaceholder="Search Country"
                enabled
              />

              <CountrySelect
                placeholder={state}
                selectedOption={handleStateSelection}
                label={"State"}
                countryList={client.states}
                enabled
                hasCountryCode={false}
                searchPlaceholder="Search State"
                type={"state"}
              />
            </div>
            <div className="half-width-form">
              <CountrySelect
                placeholder={city}
                selectedOption={handleCitySelection}
                label={"City"}
                countryList={client.cities}
                enabled={citiesQuerySuccess ? true : false}
                hasCountryCode={false}
                searchPlaceholder="Search City"
                type={"city"}
              />
              <TextInput
                hasLabel
                label={"Post/Zip Code"}
                value={formData.postCode}
                onChange={(e) => handleInputChange("postCode", e.target.value)}
              />
            </div>
            <TextInput
              hasLabel
              label={"Street"}
              value={formData.streetAddress}
              onChange={(e) =>
                handleInputChange("streetAddress", e.target.value)
              }
            />
            <Button
              color={"completed"}
              label={isLoading ? "" : "Update Profile"}
              hasIcon
              iconPosition="right"
              icon={isLoading ? "spinner-alt" : ""}
              handleClick={handleUpdateProfile}
              height={36}
            />
          </Form>
        )}
      </section>
    </>
  );
};

export default EditBusiness;
