import React, { useEffect, useState } from "react";
import styles from "./Main.module.scss";
import { Icon, Loader, Logo, Toast } from "../../../Components/Atoms";
import { TextInput } from "../../../Components/Atoms/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ProductCard from "../../../Components/Cards/Product/ProductCard";
import useLocalStorage from "use-local-storage";
import {
  useGetAllProductsQuery,
  useGetCartQuery,
  useGetShopProfileQuery,
} from "../../../redux/services/client/buyer";
import PageMetaData from "../PageMetaData";
import { localStorageCart } from "../helpers";

const ViewProducts = () => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const localTheme = useLocalStorage("theme")[0];
  const theme =
    localTheme !== null && localTheme !== undefined && localTheme !== ""
      ? localTheme
      : defaultDark
      ? "dark"
      : "light";
  const [searchTerm, setSearchTerm] = useState("");

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const currentPath = window.location.pathname;

  console.log("shop url is", window.location.href);

  const pathnameParts = currentPath.split("/");

  const shopSlug = pathnameParts[1];

  const existingAwashopData = JSON.parse(
    localStorage.getItem(localStorageCart)
  );

  const matchingCartItem = existingAwashopData?.find(
    (item) => item.slug === shopSlug
  );

  const { cartId } = matchingCartItem || {};

  const phoneScreenWidth = 606;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const navigate = useNavigate();

  const { data: profileDetails, isLoading } = useGetShopProfileQuery({
    shop_slug: shopSlug,
  });

  const {
    data: cartDetails,
    isSuccess: cartFetchSuccess,
    isError,
    error,
    isLoading: cartFetching,
  } = useGetCartQuery({
    shop_slug: shopSlug,
    cart_id: cartId,
  });

  const {
    legal_name,
    description,
    logo,
    social_link: { tiktok, instagram, facebook, whatsapp } = {},
  } = profileDetails?.data || {};

  const { data: productDetails } = useGetAllProductsQuery({
    shop_slug: shopSlug,
  });

  const filteredProducts = productDetails?.data?.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const gradientBackground =
    theme === "dark"
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%), url(${logo?.url}) lightgray 50% / cover no-repeat`
      : `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), url(${logo?.url}) lightgray 50% / cover no-repeat`;

  // useEffect(() => {
  //   if (cartFetchSuccess) {
  //     dispatch(
  //       updateBuyerData({
  //         cartId: cartDetails?.data?.id,
  //       })
  //     );
  //   }

  //   return () => {};
  // }, [cartFetchSuccess, cartDetails]);

  const handleGoToCart = () => {
    if (cartId) {
      navigate(`/${shopSlug}/cart`);
    }
  };

  useEffect(() => {
    if (isError && error?.status === 404) {
      navigate("*");
    }
    if (isError && cartId !== null && cartId !== undefined) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isError, error?.data, error?.status]);

  return (
    <section className={styles["view-products"]} id="buyerPage">
      <PageMetaData
        shopUrl={`https://awashop.ng/${shopSlug}`}
        shopDescription={description}
        shopLogoUrl={logo?.url}
        shopTitle={legal_name}
      />
      {isError && cartId !== null && cartId !== undefined ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
          portal={"buyerPage"}
        />
      ) : (
        ""
      )}
      {isLoading ? (
        <Loader size={70} />
      ) : (
        <div className={styles["view-products__inner"]}>
          <header
            className={styles.header}
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundAttachment: "fixed",
              background: gradientBackground,
            }}
          >
            <figure className={styles["header-left"]}>
              <img src={logo?.url} alt="business-logo" />
            </figure>
            <div className={styles["header-right"]}>
              <span className={styles.details}>
                <h3 className={styles["business-name"]}>{legal_name}</h3>
                <figure className={styles.icons}>
                  {tiktok && (
                    <Link
                      to={`https://${tiktok}`}
                      className={styles["icons__btns"]}
                      target="_blank"
                    >
                      <Icon
                        icon={"tiktok"}
                        width={24}
                        height={24}
                        activeLinks={true}
                      />
                    </Link>
                  )}

                  {instagram && (
                    <Link
                      to={`https://${instagram}`}
                      className={styles["icons__btns"]}
                      target="_blank"
                    >
                      <Icon
                        icon={"instagram"}
                        width={24}
                        height={24}
                        activeLinks={true}
                      />
                    </Link>
                  )}

                  {facebook && (
                    <Link
                      to={`https://${facebook}`}
                      className={styles["icons__btns"]}
                      target="_blank"
                    >
                      <Icon
                        icon={"facebook"}
                        width={24}
                        height={24}
                        activeLinks={true}
                      />
                    </Link>
                  )}

                  {whatsapp && (
                    <Link
                      to={`https://${whatsapp}`}
                      className={styles["icons__btns"]}
                      target="_blank"
                    >
                      <Icon
                        icon={"whatsapp"}
                        width={24}
                        height={24}
                        activeLinks={true}
                      />
                    </Link>
                  )}
                </figure>
              </span>
              <p className={styles.desc}>{description || ""}</p>
            </div>
          </header>
          <main className={styles.main}>
            <div className={styles["main-header"]}>
              <h3 className={styles.title}>Our Products</h3>
              <span>
                <TextInput
                  hasIcon
                  iconPosition="left"
                  icon="search"
                  iconColor="shade-0"
                  placeholder="Search Products"
                  hasLabel={false}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

                <button
                  className={styles["shopping-cart-btn"]}
                  onClick={handleGoToCart}
                >
                  {cartFetching ? (
                    <Loader size={20} />
                  ) : (
                    <>
                      {" "}
                      <Icon
                        icon={"shopping-cart"}
                        color={"primary"}
                        width={24}
                        height={24}
                      />
                      <span className={styles["no-in-cart"]}>
                        {cartDetails?.data?.order_items?.length || 0}
                      </span>{" "}
                    </>
                  )}
                </button>
              </span>
            </div>
            <div className={styles.products}>
              {filteredProducts?.map((product) => {
                const matchingOrderItem = cartDetails?.data?.order_items.find(
                  (orderItem) => orderItem.product_id === product.id
                );
                return (
                  <ProductCard
                    productName={product.title}
                    productDescription={product.description}
                    price={product.price}
                    image={product.image?.url}
                    noOfItems={product.inventory_count}
                    productId={product.id}
                    key={product.id}
                    itemID={matchingOrderItem?.id}
                    quantity={matchingOrderItem?.quantity}
                    shopSlug={shopSlug}
                  />
                );
              })}
            </div>
          </main>
          <footer className={styles.footer}>
            powered by <Logo width={118.371} height={24} />
          </footer>
          <button
            className={`${styles["cart-prompt"]} ${
              cartDetails?.data?.order_items?.length > 0 && isPhoneScreen
                ? styles.visible
                : ""
            }`}
            onClick={handleGoToCart}
          >
            Go to Cart{" "}
            <span className={styles["qty-in-cart"]}>
              {" "}
              {cartDetails?.data?.order_items?.length}
            </span>
            <Icon
              icon={"arrow-small-right"}
              width={14}
              height={14}
              color={"white"}
            />
          </button>
        </div>
      )}
    </section>
  );
};

export default ViewProducts;
