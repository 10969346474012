import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Icon } from "../Atoms";
import "./Modal.scss";

const Modal = ({
  modalOpen,
  setModalOpen,
  headerTitle,
  children,
  modalSize,
  className,
  style,
  onClose,
  isForCheckout,
  modalheight,
}) => {
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalOpen]);

  if (!modalOpen) {
    return null;
  } else
    window.scrollTo({
      top: document.body.scrollTop,
      behavior: "smooth",
    });

  {
    return ReactDOM.createPortal(
      <>
        <div
          className="backdrop"
          onClick={() => !isForCheckout && setModalOpen(!modalOpen)}
        ></div>
        <div
          className="modal"
          style={{
            maxWidth: modalSize,
            width: modalSize,
            height: modalheight,
            padding: isForCheckout ? "0" : "",
          }}
        >
          <div
            className={`modal-inner ${className}`}
            style={{ background: isForCheckout ? "transparent" : "" }}
          >
            {!isForCheckout ? (
              <div className="header">
                <h3 style={style}>{headerTitle}</h3>
                <button>
                  <Icon
                    icon="cross-small"
                    color="shade-0"
                    onClick={() =>
                      !isForCheckout ? setModalOpen(!modalOpen) : onClose()
                    }
                  />
                </button>
              </div>
            ) : (
              ""
            )}
            {children}
          </div>
        </div>
      </>,
      document.getElementById("portal")
    );
  }
};

Modal.defaultProps = {
  isForCheckout: false,
};

export default Modal;
