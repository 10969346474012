import React, { useEffect } from "react";
import { Button } from "../../../Components/Atoms";
import styles from "./Main.module.scss";

const PageCloser = () => {
  const sendMessageToParent = () => {
    window.parent.postMessage({ action: "closeIframe" }, "*");
  };

  useEffect(() => {
    sendMessageToParent();
    setTimeout(() => {
      sendMessageToParent();
    }, 300);
  }, []);

  return (
    <section className={styles.close}>
      <p className={styles.header}>Close this page to continue</p>
      <div className={styles.div}>
        <Button
          color={"completed"}
          label={"Close Page"}
          height={36}
          handleClick={sendMessageToParent}
        />
      </div>
    </section>
  );
};

export default PageCloser;
