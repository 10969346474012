import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import clientReducer from "../features/newClient";
import { accountsApi } from "../services/client/accounts";
import { onboardingApi } from "../services/client/onboarding";
import { countriesApi } from "../services/client/countries";
import { buyerApi } from "../services/client/buyer";
import buyerReducer from "../features/buyerState";

const clientStore = configureStore({
  reducer: {
    client: clientReducer,
    buyer: buyerReducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [buyerApi.reducerPath]: buyerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      accountsApi.middleware,
      onboardingApi.middleware,
      countriesApi.middleware,
      buyerApi.middleware
    ),
});

setupListeners(clientStore.dispatch);

export default clientStore;
