import React, { useEffect } from "react";

const MetaUpdater = ({ property, content }) => {
  useEffect(() => {
    const updateMetaTag = (property, content) => {
      let element = document.head.querySelector(`meta[property="${property}"]`);

      if (element) {
        element.setAttribute("content", content);
      } else {
        element = document.createElement("meta");
        element.setAttribute("property", property);
        element.setAttribute("content", content);
        document.head.appendChild(element);
      }
    };

    updateMetaTag(property, content);
  }, [property, content]);
  return null;
};

export default MetaUpdater;
