export const VARIANT_COLORS = [
  { iconWidth: 16, iconHeight: 16, label: "Black", value: "#000000" },
  { iconWidth: 16, iconHeight: 16, label: "Silver", value: "#C0C0C0" },
  { iconWidth: 16, iconHeight: 16, label: "Gray", value: "#808080" },
  { iconWidth: 16, iconHeight: 16, label: "White", value: "#FFFFFF" },
  { iconWidth: 16, iconHeight: 16, label: "Maroon", value: "#800000" },
  { iconWidth: 16, iconHeight: 16, label: "Red", value: "#FF0000" },
  { iconWidth: 16, iconHeight: 16, label: "Purple", value: "#800080" },
  { iconWidth: 16, iconHeight: 16, label: "Fuchsia", value: "#FF00FF" },
  { iconWidth: 16, iconHeight: 16, label: "Green", value: "#008000" },
  { iconWidth: 16, iconHeight: 16, label: "Lime", value: "#00FF00" },
  { iconWidth: 16, iconHeight: 16, label: "Olive", value: "#808000" },
  { iconWidth: 16, iconHeight: 16, label: "Yellow", value: "#FFFF00" },
  { iconWidth: 16, iconHeight: 16, label: "Navy", value: "#000080" },
  { iconWidth: 16, iconHeight: 16, label: "Blue", value: "#0000FF" },
  { iconWidth: 16, iconHeight: 16, label: "Teal", value: "#008080" },
  { iconWidth: 16, iconHeight: 16, label: "Aqua", value: "#00FFFF" },
  { iconWidth: 16, iconHeight: 16, label: "Alice Blue", value: "#F0F8FF" },
  { iconWidth: 16, iconHeight: 16, label: "Antique White", value: "#FAEBD7" },
  { iconWidth: 16, iconHeight: 16, label: "Aquamarine", value: "#7FFFD4" },
  { iconWidth: 16, iconHeight: 16, label: "Azure", value: "#F0FFFF" },
  { iconWidth: 16, iconHeight: 16, label: "Beige", value: "#F5F5DC" },
  { iconWidth: 16, iconHeight: 16, label: "Bisque", value: "#FFE4C4" },
  { iconWidth: 16, iconHeight: 16, label: "Blanched Almond", value: "#FFEBCD" },
  { iconWidth: 16, iconHeight: 16, label: "Blue Violet", value: "#8A2BE2" },
  { iconWidth: 16, iconHeight: 16, label: "Brown", value: "#A52A2A" },
  { iconWidth: 16, iconHeight: 16, label: "Burlywood", value: "#DEB887" },
  { iconWidth: 16, iconHeight: 16, label: "Cadet Blue", value: "#5F9EA0" },
  { iconWidth: 16, iconHeight: 16, label: "Chartreuse", value: "#7FFF00" },
  { iconWidth: 16, iconHeight: 16, label: "Chocolate", value: "#D2691E" },
  { iconWidth: 16, iconHeight: 16, label: "Coral", value: "#FF7F50" },
  { iconWidth: 16, iconHeight: 16, label: "Cornflower Blue", value: "#6495ED" },
  { iconWidth: 16, iconHeight: 16, label: "Cornsilk", value: "#FFF8DC" },
  { iconWidth: 16, iconHeight: 16, label: "Crimson", value: "#DC143C" },
  { iconWidth: 16, iconHeight: 16, label: "Cyan", value: "#00FFFF" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Blue", value: "#00008B" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Cyan", value: "#008B8B" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Goldenrod", value: "#B8860B" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Gray", value: "#A9A9A9" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Green", value: "#006400" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Grey", value: "#A9A9A9" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Khaki", value: "#BDB76B" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Magenta", value: "#8B008B" },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Dark Olive Green",
    value: "#556B2F",
  },
  { iconWidth: 16, iconHeight: 16, label: "Dark Orange", value: "#FF8C00" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Orchid", value: "#9932CC" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Red", value: "#8B0000" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Salmon", value: "#E9967A" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Sea Green", value: "#8FBC8F" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Slate Blue", value: "#483D8B" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Slate Gray", value: "#2F4F4F" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Slate Grey", value: "#2F4F4F" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Turquoise", value: "#00CED1" },
  { iconWidth: 16, iconHeight: 16, label: "Dark Violet", value: "#9400D3" },
  { iconWidth: 16, iconHeight: 16, label: "Deep Pink", value: "#FF1493" },
  { iconWidth: 16, iconHeight: 16, label: "Deep Sky Blue", value: "#00BFFF" },
  { iconWidth: 16, iconHeight: 16, label: "Dim Gray", value: "#696969" },
  { iconWidth: 16, iconHeight: 16, label: "Dim Grey", value: "#696969" },
  { iconWidth: 16, iconHeight: 16, label: "Dodger Blue", value: "#1E90FF" },
  { iconWidth: 16, iconHeight: 16, label: "Firebrick", value: "#B22222" },
  { iconWidth: 16, iconHeight: 16, label: "Floral White", value: "#FFFAF0" },
  { iconWidth: 16, iconHeight: 16, label: "Forest Green", value: "#228B22" },
  { iconWidth: 16, iconHeight: 16, label: "Gainsboro", value: "#DCDCDC" },
  { iconWidth: 16, iconHeight: 16, label: "Ghost White", value: "#F8F8FF" },
  { iconWidth: 16, iconHeight: 16, label: "Gold", value: "#FFD700" },
  { iconWidth: 16, iconHeight: 16, label: "Goldenrod", value: "#DAA520" },
  { iconWidth: 16, iconHeight: 16, label: "Green Yellow", value: "#ADFF2F" },
  { iconWidth: 16, iconHeight: 16, label: "Grey", value: "#808080" },
  { iconWidth: 16, iconHeight: 16, label: "Honeydew", value: "#F0FFF0" },
  { iconWidth: 16, iconHeight: 16, label: "Hot Pink", value: "#FF69B4" },
  { iconWidth: 16, iconHeight: 16, label: "Indian Red", value: "#CD5C5C" },
  { iconWidth: 16, iconHeight: 16, label: "Indigo", value: "#4B0082" },
  { iconWidth: 16, iconHeight: 16, label: "Ivory", value: "#FFFFF0" },
  { iconWidth: 16, iconHeight: 16, label: "Khaki", value: "#F0E68C" },
  { iconWidth: 16, iconHeight: 16, label: "Lavender", value: "#E6E6FA" },
  { iconWidth: 16, iconHeight: 16, label: "Lavender Blush", value: "#FFF0F5" },
  { iconWidth: 16, iconHeight: 16, label: "Lawn Green", value: "#7CFC00" },
  { iconWidth: 16, iconHeight: 16, label: "Lemon Chiffon", value: "#FFFACD" },
  { iconWidth: 16, iconHeight: 16, label: "Light Blue", value: "#ADD8E6" },
  { iconWidth: 16, iconHeight: 16, label: "Light Coral", value: "#F08080" },
  { iconWidth: 16, iconHeight: 16, label: "Light Cyan", value: "#E0FFFF" },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Light Goldenrod Yellow",
    value: "#FAFAD2",
  },
  { iconWidth: 16, iconHeight: 16, label: "Light Gray", value: "#D3D3D3" },
  { iconWidth: 16, iconHeight: 16, label: "Light Green", value: "#90EE90" },
  { iconWidth: 16, iconHeight: 16, label: "Light Grey", value: "#D3D3D3" },
  { iconWidth: 16, iconHeight: 16, label: "Light Pink", value: "#FFB6C1" },
  { iconWidth: 16, iconHeight: 16, label: "Light Salmon", value: "#FFA07A" },
  { iconWidth: 16, iconHeight: 16, label: "Light Sea Green", value: "#20B2AA" },
  { iconWidth: 16, iconHeight: 16, label: "Light Sky Blue", value: "#87CEFA" },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Light Slate Gray",
    value: "#778899",
  },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Light Slate Grey",
    value: "#778899",
  },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Light Steel Blue",
    value: "#B0C4DE",
  },
  { iconWidth: 16, iconHeight: 16, label: "Light Yellow", value: "#FFFFE0" },
  { iconWidth: 16, iconHeight: 16, label: "Lime", value: "#00FF00" },
  { iconWidth: 16, iconHeight: 16, label: "Lime Green", value: "#32CD32" },
  { iconWidth: 16, iconHeight: 16, label: "Linen", value: "#FAF0E6" },
  { iconWidth: 16, iconHeight: 16, label: "Magenta", value: "#FF00FF" },
  { iconWidth: 16, iconHeight: 16, label: "Maroon", value: "#800000" },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Medium Aquamarine",
    value: "#66CDAA",
  },
  { iconWidth: 16, iconHeight: 16, label: "Medium Blue", value: "#0000CD" },
  { iconWidth: 16, iconHeight: 16, label: "Medium Orchid", value: "#BA55D3" },
  { iconWidth: 16, iconHeight: 16, label: "Medium Purple", value: "#9370DB" },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Medium Sea Green",
    value: "#3CB371",
  },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Medium Slate Blue",
    value: "#7B68EE",
  },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Medium Spring Green",
    value: "#00FA9A",
  },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Medium Turquoise",
    value: "#48D1CC",
  },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Medium Violet Red",
    value: "#C71585",
  },
  { iconWidth: 16, iconHeight: 16, label: "Midnight Blue", value: "#191970" },
  { iconWidth: 16, iconHeight: 16, label: "Mint Cream", value: "#F5FFFA" },
  { iconWidth: 16, iconHeight: 16, label: "Misty Rose", value: "#FFE4E1" },
  { iconWidth: 16, iconHeight: 16, label: "Moccasin", value: "#FFE4B5" },
  { iconWidth: 16, iconHeight: 16, label: "Navajo White", value: "#FFDEAD" },
  { iconWidth: 16, iconHeight: 16, label: "Navy", value: "#000080" },
  { iconWidth: 16, iconHeight: 16, label: "Old Lace", value: "#FDF5E6" },
  { iconWidth: 16, iconHeight: 16, label: "Olive", value: "#808000" },
  { iconWidth: 16, iconHeight: 16, label: "Olive Drab", value: "#6B8E23" },
  { iconWidth: 16, iconHeight: 16, label: "Orange", value: "#FFA500" },
  { iconWidth: 16, iconHeight: 16, label: "Orange Red", value: "#FF4500" },
  { iconWidth: 16, iconHeight: 16, label: "Orchid", value: "#DA70D6" },
  { iconWidth: 16, iconHeight: 16, label: "Pale Goldenrod", value: "#EEE8AA" },
  { iconWidth: 16, iconHeight: 16, label: "Pale Green", value: "#98FB98" },
  { iconWidth: 16, iconHeight: 16, label: "Pale Turquoise", value: "#AFEEEE" },
  { iconWidth: 16, iconHeight: 16, label: "Pale Violet Red", value: "#DB7093" },
  { iconWidth: 16, iconHeight: 16, label: "Papaya Whip", value: "#FFEFD5" },
  { iconWidth: 16, iconHeight: 16, label: "Peach Puff", value: "#FFDAB9" },
  { iconWidth: 16, iconHeight: 16, label: "Peru", value: "#CD853F" },
  { iconWidth: 16, iconHeight: 16, label: "Pink", value: "#FFC0CB" },
  { iconWidth: 16, iconHeight: 16, label: "Plum", value: "#DDA0DD" },
  { iconWidth: 16, iconHeight: 16, label: "Powder Blue", value: "#B0E0E6" },
  { iconWidth: 16, iconHeight: 16, label: "Purple", value: "#800080" },
  { iconWidth: 16, iconHeight: 16, label: "Red", value: "#FF0000" },
  { iconWidth: 16, iconHeight: 16, label: "Rosy Brown", value: "#BC8F8F" },
  { iconWidth: 16, iconHeight: 16, label: "Royal Blue", value: "#4169E1" },
  { iconWidth: 16, iconHeight: 16, label: "Saddle Brown", value: "#8B4513" },
  { iconWidth: 16, iconHeight: 16, label: "Salmon", value: "#FA8072" },
  { iconWidth: 16, iconHeight: 16, label: "Sandy Brown", value: "#F4A460" },
  { iconWidth: 16, iconHeight: 16, label: "Sea Green", value: "#2E8B57" },
  { iconWidth: 16, iconHeight: 16, label: "Seashell", value: "#FFF5EE" },
  { iconWidth: 16, iconHeight: 16, label: "Sienna", value: "#A0522D" },
  { iconWidth: 16, iconHeight: 16, label: "Silver", value: "#C0C0C0" },
  { iconWidth: 16, iconHeight: 16, label: "Sky Blue", value: "#87CEEB" },
  { iconWidth: 16, iconHeight: 16, label: "Slate Blue", value: "#6A5ACD" },
  { iconWidth: 16, iconHeight: 16, label: "Slate Gray", value: "#708090" },
  { iconWidth: 16, iconHeight: 16, label: "Slate Grey", value: "#708090" },
  { iconWidth: 16, iconHeight: 16, label: "Snow", value: "#FFFAFA" },
  { iconWidth: 16, iconHeight: 16, label: "Spring Green", value: "#00FF7F" },
  { iconWidth: 16, iconHeight: 16, label: "Steel Blue", value: "#4682B4" },
  { iconWidth: 16, iconHeight: 16, label: "Tan", value: "#D2B48C" },
  { iconWidth: 16, iconHeight: 16, label: "Teal", value: "#008080" },
  { iconWidth: 16, iconHeight: 16, label: "Thistle", value: "#D8BFD8" },
  { iconWidth: 16, iconHeight: 16, label: "Tomato", value: "#FF6347" },
  { iconWidth: 16, iconHeight: 16, label: "Turquoise", value: "#40E0D0" },
  { iconWidth: 16, iconHeight: 16, label: "Violet", value: "#EE82EE" },
  { iconWidth: 16, iconHeight: 16, label: "Wheat", value: "#F5DEB3" },
  { iconWidth: 16, iconHeight: 16, label: "White", value: "#FFFFFF" },
  { iconWidth: 16, iconHeight: 16, label: "White Smoke", value: "#F5F5F5" },
  { iconWidth: 16, iconHeight: 16, label: "Yellow", value: "#FFFF00" },
  {
    iconWidth: 16,
    iconHeight: 16,
    label: "Yellow Green",
    value: "#9ACD32",
  },
];

export const VARIANT_SIZES = [
  { label: "XS", value: "extra-small" },
  { label: "S", value: "small" },
  { label: "M", value: "medium" },
  { label: "L", value: "large" },
  { label: "XL", value: "extra-large" },
];

export const VARIANT_VOLUME = [
  { label: "250 ml", value: "250ml" },
  { label: "500 ml", value: "500ml" },
  { label: "1 liter", value: "1l" },
  { label: "2 liters", value: "2l" },
  { label: "500 g", value: "500g" },
  { label: "1 kg", value: "1kg" },
  { label: "2 kg", value: "2kg" },
  { label: "5 kg", value: "5kg" },
  { label: "10 kg", value: "10kg" },
  { label: "25 kg", value: "25kg" },
  { label: "50 kg", value: "50kg" },
  { label: "100 kg", value: "100kg" },
];

// color
// :
// "#FFFFFF"
// current_inventory_count
// :
// 10
// description
// :
// null
// id
// :
// 2
// image
// :
// email_thumb
// :
// {url: null}
// url
// :
// null
// [[Prototype]]
// :
// Object
// inventory_count
// :
// 10
// previous_inventory_count
// :
// 10
// price
// :
// "0.0"
// size
// :
