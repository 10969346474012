import React, { useEffect, useState } from "react";
import Base from "../Base/Base";
import { Button, Icon, ProgressBar } from "../../../Components/Atoms";
import styles from "./Form.module.scss";
import { useNavigate } from "react-router";
import { clientPages } from "../../../Routes/routes";

const PhoneVerificationDone = () => {
  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate(clientPages.dashboard);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  return (
    <Base stepsPage={false} displayLogo={false}>
      <div className={styles["last-step"]}>
        <Icon icon="modal-icon" width={100} height={100} />
        <span className={styles["middle-span"]}>
          <h3 className={styles.title}>All done!</h3>
          <p className={styles.info}>
            Your phone number verification is complete, click the button to get
            into your account and access your space
          </p>
        </span>
        <div className={styles.actions}>
          <Button
            color={"completed"}
            label={"Go to dashboard"}
            height={36}
            hasIcon
            iconPosition={"right"}
            icon={"arrow-small-right"}
            handleClick={() => navigate(clientPages.dashboard)}
          />
          <p className={styles.progress}>press enter</p>
        </div>
      </div>
    </Base>
  );
};

export default PhoneVerificationDone;
