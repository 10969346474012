import React, { useEffect, useRef, useState } from "react";
import Form from "../Onboarding/Steps/Form";
import Base from "../Onboarding/Base/Base";
import styles from "../Onboarding/Steps/Form.module.scss";
import { TextInput } from "../../Components/Atoms/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import { Toast } from "../../Components/Atoms";
import { clientPages } from "../../Routes/routes";
import { useLazyLoginQuery } from "../../redux/services/client/accounts";

const Login = ({ setNextStep }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const emailRef = useRef();
  const passwordRef = useRef();

  const emailValue = emailRef.current?.getValue();
  const passwordValue = passwordRef.current?.getValue();

  const [
    loginDetails,
    { data: verfiedLogin, isFetching, isSuccess, isError, error },
  ] = useLazyLoginQuery();

  useEffect(() => {
    if (localStorage.getItem("authToken") !== null) {
      navigate(clientPages.dashboard);
      return;
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (verfiedLogin.status === "success") {
        localStorage.setItem("authToken", verfiedLogin.data.auth);
        navigate(`/clients/dashboard`);
      }
    }
    if (isError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }
  }, [isSuccess, isError, error?.data]);

  const handleLoginDetails = (e) => {
    setCheckEmpty(false);
  };

  const validateLogin = async () => {
    const emailValue = emailRef.current?.getValue().trim();
    const passwordValue = passwordRef.current?.getValue().trim();
    if (emailValue.trim().length === 0 || passwordValue.trim().length === 0) {
      const inputRefs = [emailRef, passwordRef];

      const firstEmptyInputRef = inputRefs.find(
        (inputRef) => inputRef.current?.getValue().trim() === ""
      );

      if (firstEmptyInputRef) {
        setCheckEmpty(true);
        firstEmptyInputRef.current?.focus();
      }
    } else {
      await loginDetails({
        email: emailValue,
        password: passwordValue,
      });
    }
  };

  const handleIconClick = () => {
    const icon = document.getElementById("passwordInput");
    if (icon.type === "text") {
      icon.type = "password";
      setVisible(!visible);
      passwordRef.current.focus();
    } else if (icon.type === "password") {
      icon.type = "text";
      setVisible(!visible);
      passwordRef.current.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      validateLogin();
    }
  };

  return (
    <>
      {isError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <Base classes={styles["steps-form"]} stepsPage={false}>
        <Form
          buttonLabel={isFetching ? "" : "Continue"}
          setNextStep={setNextStep}
          handleButtonClick={validateLogin}
          icon={isFetching && "spinner-alt"}
          onSubmit={handleSubmit}
          onKeyPress={handleKeyDown}
        >
          <div className={styles.inner}>
            <label className={styles.title}>Login</label>
            <TextInput
              placeholder="eg. elon@musk.com"
              onChange={handleLoginDetails}
              name="email"
              ref={emailRef}
              outline={
                checkEmpty && emailValue.trim().length === 0 ? true : false
              }
            />
            <TextInput
              placeholder="••••••••••"
              type="password"
              name="password"
              hasIcon
              icon={!visible ? "eye-crossed" : "eye"}
              iconColor="shade-100"
              iconPosition="right"
              onChange={handleLoginDetails}
              id="passwordInput"
              onIconClick={handleIconClick}
              ref={passwordRef}
              outline={
                checkEmpty && passwordValue.trim().length === 0 ? true : false
              }
            />
            <button
              className={styles["forgot-password-btn"]}
              onClick={() => navigate(clientPages.verifyEmail)}
            >
              forgot password?
            </button>
          </div>
        </Form>
        <div className={styles.login}>
          <span>
            Don't have an account?{" "}
            <Link to={clientPages.signup} className={styles["login-btn"]}>
              Signup
            </Link>{" "}
          </span>
        </div>
      </Base>
    </>
  );
};

export default Login;
