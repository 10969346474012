import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Pages/Login/Login";
import clientStore from "../redux/store/clientStore";
import Start from "../Pages/Onboarding/Steps/Start";
import Onboarding from "../Pages/Onboarding/Onboarding";
import CreateCompany from "../Pages/CreateCompany/Main";
import { Provider } from "react-redux";
import CompanyDashboard from "../Pages/Dashboard/CompanyDashboard";
import Dashboard from "../Pages/Dashboard/Main";
import EditBusiness from "../Pages/Dashboard/Edit/Main";
import Links from "../Pages/Dashboard/Links/Main";
import AddProduct from "../Pages/Dashboard/AddProduct/Main";
import Payout from "../Pages/Dashboard/Payout/Main";
import UpdateProduct from "../Pages/Dashboard/UpdateProduct/Main";
import SellerProfile from "../Pages/SellerProfile/Main";
import CheckoutScreen from "../Pages/CheckoutScreen/Main";
import EditProfile from "../Pages/Dashboard/EditProfile/Main";
import PhoneVerificationDone from "../Pages/Onboarding/Steps/PhoneVerificationDone";
import PhoneVerification from "../Pages/Onboarding/Steps/PhoneVerification";
import VerifyPhoneOtp from "../Pages/Onboarding/Steps/VerifyPhoneOtp";
import OwnerSales from "../Pages/Dashboard/Sales/Main";
import ViewProducts from "../Pages/Buyers/ViewProducts/Main";
import Checkout from "../Pages/Buyers/Checkout/Main";
import Withdraw from "../Pages/Dashboard/Withdraw/Main";
import Cart from "../Pages/Buyers/Cart/Cart";
import PageCloser from "../Pages/Buyers/PageCloser/Main";
import VerifyPayment from "../Pages/Buyers/VerifyPayment/Main";
import ErrorPage from "../Pages/ErrorPage/Main";
import DeliveryRates from "../Pages/Dashboard/DeliveryRates/DeliveryRates";
import Categories from "../Pages/Dashboard/Categories/Main";
import VerifyEmail from "../Pages/ForgotPassword/VerifyEmail";
import ResetPassword from "../Pages/ForgotPassword/ResetPassword";
import ShareImage from "../Components/ShareImage/ShareImage";
import AllProducts from "../Pages/Dashboard/AllProducts/Main";

const ClientRoutes = () => {
  return (
    <Provider store={clientStore}>
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="password/reset" element={<ResetPassword />} />
        {/* Onboarding Routes */}
        <Route path="clients/auth">
          <Route path="start" element={<Start />} />
          <Route path="start/invitations" element={<Onboarding />} />
          <Route path="signup" element={<Onboarding />} />
          <Route path="create_company" element={<CreateCompany />} />
          <Route path="verify-phone" element={<PhoneVerification />} />
          <Route
            path="verification-complete"
            element={<PhoneVerificationDone />}
          />
          <Route path="verify-otp" element={<VerifyPhoneOtp />} />
          <Route path="verify-email" element={<VerifyEmail />} />
        </Route>

        {/* Auth Routes */}
        <Route path="/clients/auth/login" element={<Login />} />

        {/* Dashboard Routes */}
        <Route path="clients/dashboard" element={<CompanyDashboard />}>
          <Route path="" element={<Dashboard />} />
          <Route path="edit" element={<EditBusiness />} />
          <Route path="links" element={<Links />} />
          <Route path="product/add" element={<AddProduct />} />
          <Route path="payout" element={<Payout />} />
          <Route path="product/edit/:productId" element={<UpdateProduct />} />
          <Route path="seller-profile" element={<SellerProfile />} />
          <Route path="cart/checkout" element={<CheckoutScreen />} />
          <Route path="profile/edit" element={<EditProfile />} />
          <Route path="sales" element={<OwnerSales />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="delivery_rates" element={<DeliveryRates />} />
          <Route path="categories" element={<Categories />} />
          <Route path="all-products" element={<AllProducts />} />
          <Route path="product/duplicate/:productId" element={<AddProduct />} />
        </Route>

        {/* Buyer Routes */}

        <Route path=":shop_slug" element={<ViewProducts />} />
        <Route path=":shop_slug/cart/checkout" element={<Checkout />} />
        <Route path=":shop_slug/cart" element={<Cart />} />
        <Route path="close-payment-page" element={<PageCloser />} />
        <Route path=":shop_slug/verify-payment" element={<VerifyPayment />} />

        {/* Error Routes */}
        <Route path="share-image" element={<ShareImage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Provider>
  );
};

export const clientPages = {
  start: "/clients/auth/start",
  login: "/clients/auth/login",
  signup: "/clients/auth/signup",
  createCompany: "/clients/auth/create_company",
  verifyPhone: "/clients/auth/verify-phone",
  verificationComplete: "/clients/auth/verification-complete",
  dashboard: "/clients/dashboard",
  editBusiness: "/clients/dashboard/edit",
  links: "/clients/dashboard/links",
  addProduct: "/clients/dashboard/product/add",
  payout: "/clients/dashboard/payout",
  updateProduct: "/clients/dashboard/product/edit",
  sellerProfile: "/clients/dashboard/seller-profile",
  checkoutScreen: "/clients/dashboard/cart/checkout",
  editProfile: "/clients/dashboard/profile/edit",
  verifyPhoneOtp: "/clients/auth/verify-otp",
  ownerSales: "/clients/dashboard/sales",
  viewProducts: "/users/shop",
  checkout: "/u",
  withdraw: "/clients/dashboard/withdraw",
  verifyEmail: "/clients/auth/verify-email",
  resetPassword: "/password/reset",
  AllProducts: "/clients/dashboard/all-products",
  duplicateProduct: "/clients/dashboard/product/duplicate",
};

export const buyerPages = {
  viewProducts: "/:shop_slug",
  cart: "/:shop_slug/cart",
  checkout: "/:shop_slug/cart/checkout",
};

export default ClientRoutes;
