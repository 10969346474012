import React, { useEffect } from "react";
import MetaUpdater from "./Metadata/MetaUpdater";

const PageMetaData = ({ shopUrl, shopTitle, shopDescription, shopLogoUrl }) => {
  useEffect(() => {
    document.title = `${shopTitle} - on Awashop`;
  }, [shopTitle]);

  return (
    <>
      <MetaUpdater property="og:type" content="website" />
      <MetaUpdater property="og:url" content={shopUrl} />
      <MetaUpdater property="og:title" content={shopTitle} />
      <MetaUpdater property="og:description" content={shopDescription} />
      <MetaUpdater property="og:image" content={shopLogoUrl} />

      <MetaUpdater property="twitter:card" content="app" />
      <MetaUpdater property="twitter:url" content={shopUrl} />
      <MetaUpdater property="twitter:title" content={shopTitle} />
      <MetaUpdater property="twitter:description" content={shopDescription} />
      <MetaUpdater property="twitter:image" content={shopLogoUrl} />
    </>
  );
};

PageMetaData.defaultProps = {
  shopUrl: "https://awashop.ng/",
  shopTitle: "Awashop - Simplify Your Online Selling Experience",
  shopDescription:
    "Awashop: the one-stop platform for seamless shop setup and secure online transactions, catering to Instagram vendors and online merchants.",
  shopLogoUrl: "%PUBLIC_URL%/awashop-og-img.png",
};

export default PageMetaData;
