import React from "react";
import { ReactComponent as App } from "../../../assets/uicons/apps.svg";
import { ReactComponent as Check } from "../../../assets/uicons/check.svg";
import { ReactComponent as Trash } from "../../../assets/uicons/trash.svg";
import { ReactComponent as CrossCircle } from "../../../assets/uicons/cross-circle.svg";
import { ReactComponent as CrossSmall } from "../../../assets/uicons/cross-small.svg";
import { ReactComponent as Info } from "../../../assets/uicons/info.svg";
import { ReactComponent as ChevronDown } from "../../../assets/uicons/angle-small-down.svg";
import { ReactComponent as ArrowSmallRight } from "../../../assets/uicons/arrow-small-right.svg";
import { ReactComponent as Search } from "../../../assets/uicons/search.svg";
import { ReactComponent as Star } from "../../../assets/uicons/star.svg";
import { ReactComponent as Plus } from "../../../assets/uicons/plus.svg";
import { ReactComponent as User } from "../../../assets/uicons/user.svg";
import { ReactComponent as Users } from "../../../assets/uicons/users.svg";
import { ReactComponent as Bell } from "../../../assets/uicons/bell.svg";
import { ReactComponent as Settings } from "../../../assets/uicons/settings.svg";
import { ReactComponent as EyeCrossed } from "../../../assets/uicons/eye-crossed.svg";
import { ReactComponent as Stories } from "../../../assets/uicons/chart-connected.svg";
import { ReactComponent as Tasks } from "../../../assets/uicons/chart-network.svg";
import { ReactComponent as Teams } from "../../../assets/uicons/chart-tree.svg";
import { ReactComponent as RoadMaps } from "../../../assets/uicons/rocket.svg";
import { ReactComponent as Circle } from "../../../assets/uicons/circle.svg";
import { ReactComponent as Power } from "../../../assets/uicons/power.svg";
import { ReactComponent as Refresh } from "../../../assets/uicons/refresh.svg";
import { ReactComponent as CheckBox } from "../../../assets/uicons/checkbox.svg";
import { ReactComponent as Link } from "../../../assets/uicons/link.svg";
import { ReactComponent as Comments } from "../../../assets/uicons/comments.svg";
import { ReactComponent as MenuDots } from "../../../assets/uicons/menu-dots.svg";
import { ReactComponent as ConnectorBranch } from "../../../assets/uicons/connector-branch.svg";
import { ReactComponent as SettingsSliders } from "../../../assets/uicons/settings-sliders.svg";
import { ReactComponent as Clock } from "../../../assets/uicons/clock.svg";
import { ReactComponent as GreenCheckBox } from "../../../assets/uicons/green-checkbox.svg";
import { ReactComponent as ListCheck } from "../../../assets/uicons/list-check.svg";
import { ReactComponent as CreditCard } from "../../../assets/uicons/credit-card.svg";
import { ReactComponent as Folder } from "../../../assets/uicons/folder.svg";
import { ReactComponent as Bold } from "../../../assets/uicons/bold.svg";
import { ReactComponent as Underline } from "../../../assets/uicons/underline.svg";
import { ReactComponent as Italics } from "../../../assets/uicons/italic.svg";
import { ReactComponent as List } from "../../../assets/uicons/list.svg";
import { ReactComponent as AlignLeft } from "../../../assets/uicons/align-left.svg";
import { ReactComponent as AlignRight } from "../../../assets/uicons/align-right.svg";
import { ReactComponent as AlignCenter } from "../../../assets/uicons/align-center.svg";
import { ReactComponent as CodeSimple } from "../../../assets/uicons/code-simple.svg";
import { ReactComponent as Picture } from "../../../assets/uicons/picture.svg";
import { ReactComponent as StrikeThrough } from "../../../assets/uicons/strike-through.svg";
import { ReactComponent as AngleSmallUp } from "../../../assets/uicons/angle-small-up.svg";
import { ReactComponent as AngleSmallRight } from "../../../assets/uicons/angle-small-right.svg";
import { ReactComponent as Document } from "../../../assets/uicons/document.svg";
import { ReactComponent as Pencil } from "../../../assets/uicons/pencil.svg";
import { ReactComponent as CloudUpload } from "../../../assets/uicons/cloud-upload.svg";
import { ReactComponent as Download } from "../../../assets/uicons/download.svg";
import { ReactComponent as Calendar } from "../../../assets/uicons/calendar.svg";
import { ReactComponent as Add } from "../../../assets/uicons/add.svg";
import { ReactComponent as Eye } from "../../../assets/uicons/eye.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/uicons/arrow-left.svg";
import { ReactComponent as SpinnerAlt } from "../../../assets/uicons/spinner-alt.svg";
import { ReactComponent as Spinner } from "../../../assets/uicons/spinner.svg";
import { ReactComponent as DocumentSigned } from "../../../assets/uicons/document-signed.svg";
import { ReactComponent as Building } from "../../../assets/uicons/building.svg";
import { ReactComponent as Cube } from "../../../assets/uicons/cube.svg";
import { ReactComponent as Sun } from "../../../assets/uicons/sun.svg";
import { ReactComponent as Moon } from "../../../assets/uicons/moon.svg";
import { ReactComponent as ArrowUp } from "../../../assets/uicons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/uicons/arrow-down.svg";
import { ReactComponent as AngleSmallLeft } from "../../../assets/uicons/angle-small-left.svg";
import { ReactComponent as PlusSmall } from "../../../assets/uicons/plus-small.svg";
import { ReactComponent as Edit } from "../../../assets/uicons/edit.svg";
import { ReactComponent as AppsSort } from "../../../assets/uicons/apps-sort.svg";
import { ReactComponent as Comment } from "../../../assets/uicons/comment.svg";
import { ReactComponent as LogoFigmaColored } from "../../../assets/uicons/logos-figma-colored.svg";
import { ReactComponent as LogoGithub } from "../../../assets/uicons/logo-github.svg";
import { ReactComponent as LogoDriveColored } from "../../../assets/uicons/logo-drive-colored.svg";
import { ReactComponent as Shuffle } from "../../../assets/uicons/shuffle.svg";
import { ReactComponent as ArrowSmallLeft } from "../../../assets/uicons/arrow-small-left.svg";
import { ReactComponent as Browser } from "../../../assets/uicons/browser.svg";
import { ReactComponent as Confetti } from "../../../assets/uicons/confetti.svg";
import { ReactComponent as Lock } from "../../../assets/uicons/lock.svg";
import { ReactComponent as Expand } from "../../../assets/uicons/expand.svg";
import { ReactComponent as Smile } from "../../../assets/uicons/smile.svg";
import { ReactComponent as Microphone } from "../../../assets/uicons/microphone.svg";
import { ReactComponent as PaperPlane } from "../../../assets/uicons/paper-plane.svg";
import { ReactComponent as Headphones } from "../../../assets/uicons/headphones.svg";
import { ReactComponent as Money } from "../../../assets/uicons/money.svg";
import { ReactComponent as Tiktok } from "../../../assets/svgs/tiktok.svg";
import { ReactComponent as InstagramGrey } from "../../../assets/svgs/instagram-grey.svg";
import { ReactComponent as FacebookGrey } from "../../../assets/svgs/facebook-grey.svg";
import { ReactComponent as LinkedinGrey } from "../../../assets/svgs/linkedin-grey.svg";
import { ReactComponent as Edit2 } from "../../../assets/svgs/edit-2.svg";
import { ReactComponent as NigeriaFlag } from "../../../assets/svgs/nigeria-flag.svg";
import { ReactComponent as LogoDrive } from "../../../assets/uicons/logo-drive.svg";
import { ReactComponent as Flag } from "../../../assets/uicons/flag.svg";
import { ReactComponent as Share } from "../../../assets/uicons/share.svg";
import { ReactComponent as Instagram } from "../../../assets/svgs/instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/svgs/facebook.svg";
import { ReactComponent as MinusSmall } from "../../../assets/uicons/minus-small.svg";
import { ReactComponent as MenuDotsVertical } from "../../../assets/uicons/menu-dots-vertical.svg";
import { ReactComponent as CopyAlt } from "../../../assets/uicons/copy-alt.svg";
import { ReactComponent as CommentUser } from "../../../assets/uicons/comment-user.svg";
import { ReactComponent as GreenCheckCircle } from "../../../assets/svgs/green-check-circle.svg";
import { ReactComponent as BellRing } from "../../../assets/uicons/bell-ring.svg";
import { ReactComponent as ModalIcon } from "../../../assets/images/ModalIcon.svg";
import { ReactComponent as CartChecked } from "../../../assets/uicons/shopping-cart-check.svg";
import { ReactComponent as SignOut } from "../../../assets/uicons/sign-out.svg";
import { ReactComponent as GreenCheckCircleOpacity } from "../../../assets/svgs/green-check-opacity.svg";
import { ReactComponent as PaystackIcon } from "../../../assets/svgs/paystack 1.svg";
import { ReactComponent as FlutterwaveIcon } from "../../../assets/svgs/flutterwave 1.svg";
import { ReactComponent as MonnifyIcon } from "../../../assets/svgs/monnify 1.svg";
import { ReactComponent as ShoppingCart } from "../../../assets/uicons/shopping-cart.svg";
import { ReactComponent as Whatsapp } from "../../../assets/svgs/whatsapp.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svgs/404-icon.svg";
import { ReactComponent as ShieldExclamation } from "../../../assets/uicons/shield-exclamation.svg";
import { ReactComponent as ShieldCheck } from "../../../assets/uicons/shield-check.svg";
import { ReactComponent as Bike } from "../../../assets/uicons/bike.svg";
import { ReactComponent as RedEllipse } from "../../../assets/svgs/red-ellipse.svg";
import { ReactComponent as BlueEllipse } from "../../../assets/svgs/blue-ellipse.svg";
import { ReactComponent as GreenEllipse } from "../../../assets/svgs/green-ellipse.svg";
import { ReactComponent as YellowEllipse } from "../../../assets/svgs/yellow-ellipse.svg";
import { ReactComponent as MenuBurger } from "../../../assets/uicons/menu-burger.svg";
import { ReactComponent as ShoppingBag } from "../../../assets/uicons/shopping-bag.svg";
import { ReactComponent as CaretDown } from "../../../assets/uicons/caret-down.svg";

import styles from "./Icon.module.scss";

const Icon = ({
  icon,
  color,
  size,
  onClick,
  rotation,
  width,
  height,
  position,
  cursor,
  activeLinks,
}) => {
  const uIcons = {
    app: App,
    check: Check,
    trash: Trash,
    crossCircle: CrossCircle,
    info: Info,
    chevronDown: ChevronDown,
    search: Search,
    star: Star,
    plus: Plus,
    user: User,
    users: Users,
    bell: Bell,
    settings: Settings,
    "arrow-small-right": ArrowSmallRight,
    "eye-crossed": EyeCrossed,
    stories: Stories,
    tasks: Tasks,
    teams: Teams,
    roadmaps: RoadMaps,
    circle: Circle,
    power: Power,
    refresh: Refresh,
    checkbox: CheckBox,
    link: Link,
    comments: Comments,
    "menu-dots": MenuDots,
    "connector-branch": ConnectorBranch,
    "settings-sliders": SettingsSliders,
    clock: Clock,
    "green-checkbox": GreenCheckBox,
    "list-check": ListCheck,
    "credit-card": CreditCard,
    folder: Folder,
    bold: Bold,
    italics: Italics,
    underline: Underline,
    "align-right": AlignRight,
    "align-left": AlignLeft,
    "align-center": AlignCenter,
    list: List,
    "code-simple": CodeSimple,
    picture: Picture,
    "strike-through": StrikeThrough,
    "cross-small": CrossSmall,
    "angle-small-up": AngleSmallUp,
    "angle-small-right": AngleSmallRight,
    document: Document,
    pencil: Pencil,
    "cloud-upload": CloudUpload,
    download: Download,
    calendar: Calendar,
    add: Add,
    eye: Eye,
    "arrow-left": ArrowLeft,
    "spinner-alt": SpinnerAlt,
    spinner: Spinner,
    "document-signed": DocumentSigned,
    building: Building,
    cube: Cube,
    moon: Moon,
    sun: Sun,
    "arrow-up": ArrowUp,
    "arrow-down": ArrowDown,
    "angle-small-left": AngleSmallLeft,
    "plus-small": PlusSmall,
    edit: Edit,
    "apps-sort": AppsSort,
    comment: Comment,
    "logo-figma-colored": LogoFigmaColored,
    "logo-github": LogoGithub,
    "logo-drive-colored": LogoDriveColored,
    shuffle: Shuffle,
    "arrow-small-left": ArrowSmallLeft,
    browser: Browser,
    confetti: Confetti,
    lock: Lock,
    expand: Expand,
    smile: Smile,
    microphone: Microphone,
    "paper-plane": PaperPlane,
    headphones: Headphones,
    money: Money,
    tiktok: Tiktok,
    "instagram-grey": InstagramGrey,
    "facebook-grey": FacebookGrey,
    "linkedin-grey": LinkedinGrey,
    "edit-2": Edit2,
    "nigeria-flag": NigeriaFlag,
    "logo-drive": LogoDrive,
    flag: Flag,
    share: Share,
    instagram: Instagram,
    facebook: Facebook,
    "minus-small": MinusSmall,
    "menu-dots-vertical": MenuDotsVertical,
    "copy-alt": CopyAlt,
    "comment-user": CommentUser,
    "green-check-circle": GreenCheckCircle,
    "bell-ring": BellRing,
    "modal-icon": ModalIcon,
    "shopping-cart-checked": CartChecked,
    "sign-out": SignOut,
    "green-check-circle-opacity": GreenCheckCircleOpacity,
    "paystack-icon": PaystackIcon,
    "flutterwave-icon": FlutterwaveIcon,
    "monnify-icon": MonnifyIcon,
    "shopping-cart": ShoppingCart,
    whatsapp: Whatsapp,
    "error-icon": ErrorIcon,
    "shield-exclamation": ShieldExclamation,
    "shield-check": ShieldCheck,
    bike: Bike,
    "red-ellipse": RedEllipse,
    "blue-ellipse": BlueEllipse,
    "green-ellipse": GreenEllipse,
    "yellow-ellipse": YellowEllipse,
    "menu-burger": MenuBurger,
    "shopping-bag": ShoppingBag,
    "caret-down": CaretDown,
  };

  const isSpinner = icon === "spinner" || icon === "spinner-alt";

  const isGrey = activeLinks !== true;

  const IconComponent = uIcons[icon];

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      className={`${styles["icon"]} space-ui-icon  ${styles[color]} ${
        isSpinner ? styles.spinner : ""
      } ${isGrey ? styles.isGrey : ""}`}
      style={{
        cursor: cursor || "pointer",
        width: width,
        height: height,
        transform: rotation,
        position: position,
      }}
      onClick={onClick}
    />
  );
};

Icon.defaultProps = {
  width: 14,
  height: 14,
  activeLinks: true,
};

export default Icon;
