import React, { useEffect, useState } from "react";
import Form from "./Form";
import Base from "../Base/Base";
import { PhoneInput, TextInput } from "../../../Components/Atoms/Input/Input";
import styles from "./Form.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useUpdatePhoneMutation } from "../../../redux/services/client/accounts";
import { Icon, Toast } from "../../../Components/Atoms";
import { clientPages } from "../../../Routes/routes";
import { updateClientData } from "../../../redux/features/newClient";
import { useDispatch } from "react-redux";

const StepTwo = () => {
  const navigate = useNavigate();
  const [phoneNo, setPhoneNo] = useState(null);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const { message, status, heading } = toastMessage;

  const dispatch = useDispatch();

  const [updatePhone, { isSuccess, isError, error, isLoading }] =
    useUpdatePhoneMutation();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleContinueToVerify();
    }
  };

  const handleContinueToVerify = async () => {
    await updatePhone({
      phone_number: phoneNo,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(clientPages.verifyPhoneOtp);
    }

    if (isError) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isSuccess, isError, error?.data]);

  return (
    <>
      {isError ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <Base classes={styles["steps-form"]} stepsPage={false}>
        {/* <div className={styles.prompt}>
          <span className={styles["prompt-left"]}>
            <Icon icon={"bell-ring"} color={"orange"} width={24} height={24} />
            You need to verify your phone number first
          </span>
        </div>
        <div className={styles.step}>
          <span>
            <strong>Step 2 </strong>
          </span>
          <span>/ 6</span>
        </div> */}
        <Form
          buttonLabel={isLoading ? "" : "Continue"}
          color={"completed"}
          handleButtonClick={handleContinueToVerify}
          onKeyPress={handleKeyPress}
          icon={isLoading ? "spinner-alt" : ""}
        >
          <div className={styles.inner}>
            <label className={styles.title}>Phone Number</label>
            <div style={{ width: "100%" }}>
              <PhoneInput
                hasLabel={false}
                value={phoneNo}
                maxLength={10}
                onChange={(e) => {
                  setPhoneNo(e.target.value.trim());
                  dispatch(
                    updateClientData({
                      phoneNumber: e.target.value.trim(),
                    })
                  );
                }}
              />
            </div>
          </div>
        </Form>
      </Base>
    </>
  );
};

export default React.memo(StepTwo);
