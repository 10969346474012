import React, { useState, useImperativeHandle, useRef, useEffect } from "react";
import styles from "./Link.module.scss";
import Icon from "../../../Icons/Icon";

const Link = React.forwardRef(
  (
    {
      hasLabel,
      icon,
      iconColor,
      label,
      url,
      outline,
      value,
      onChange,
      activeLinks,
      disabled,
      onInput,
    },
    ref
  ) => {
    const [isActive, setisActive] = useState(false);
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return inputRef?.current?.value;
      },
      focus: () => {
        inputRef?.current?.focus();
      },
    }));

    const setActive = () => {
      setisActive(true);
    };

    const unsetActive = () => {
      setisActive(false);
    };

    useEffect(() => {
      if (isActive) {
        inputRef?.current?.focus();
      }
    }, [isActive]);

    return (
      <div className="space-ui-text-input">
        {hasLabel ? <label htmlFor="text-input">{label}</label> : ""}
        <div
          className={`${styles["link-container"]} ${
            isActive ? styles.active : ""
          } ${outline ? styles.error : ""}`}
          style={{
            marginTop: hasLabel ? "10px" : "",
            cursor: disabled ? "not-allowed" : "",
          }}
        >
          <div className={styles.left}>
            <span>{url}</span>
            <input
              className={styles["dynamic-text"]}
              onChange={onChange}
              ref={inputRef}
              value={value}
              onFocus={setActive}
              onBlur={unsetActive}
              disabled={disabled}
              onInput={onInput}
            />
          </div>
          <Icon
            icon={icon}
            color={iconColor || "shade-0"}
            activeLinks={activeLinks}
          />
        </div>
      </div>
    );
  }
);

Link.defaultProps = {
  hasLabel: true,
  outline: false,
};

export default Link;
