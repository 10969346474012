import React, { useEffect, useRef, useState } from "react";
import Form from "../../../Components/Bases/Form/Form";
import styles from "./_Main.module.scss";
import { LinkInput } from "../../../Components/Atoms/Input/Input";
import { Button, Toast } from "../../../Components/Atoms";
import { useUpdateLinksMutation } from "../../../redux/services/client/accounts";
import { useSelector } from "react-redux";
import { clientPages } from "../../../Routes/routes";
import { useNavigate } from "react-router";

const Links = () => {
  const client = useSelector((state) => state.client.value);
  const [errorDataObject, setErrorDataObject] = useState(null);
  const [inputError, setInputError] = useState({
    tiktok: false,
    whatsapp: false,
    instagram: false,
    facebook: false,
  });
  const [reRender, setReRender] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });

  const navigate = useNavigate();

  const [links, setLinks] = useState({
    awaShop: client?.profileDetails?.business?.slug,
    instagram: client?.links?.instagram,
    tiktok: client?.links?.tiktok,
    whatsapp: client?.links?.whatsapp,
    facebook: client?.links?.facebook,
  });

  const urls = {
    awaShop: "https://awashop.ng/",
    instagram: "https://instagram.com/",
    tiktok: "https://tiktok.com/@",
    whatsapp: "https://wa.me/+234",
    facebook: "https://facebook.com/",
  };

  const awaShopRef = useRef(null);
  const instagramRef = useRef(null);
  const tiktokRef = useRef(null);
  const whatsappRef = useRef(null);
  const facebookRef = useRef(null);

  const { message, status, heading } = toastMessage;

  useEffect(() => {
    setLinks({
      awaShop: client?.profileDetails?.business?.slug,
      instagram: client?.links?.instagram,
      tiktok: client?.links?.tiktok,
      whatsapp: client?.links?.whatsapp,
      facebook: client?.links?.facebook,
    });

    return () => {};
  }, [client?.profileDetails]);

  const [
    updateLinks,
    {
      isLoading,
      isSuccess: linksUpdateSuccess,
      isError: linksUpdateError,
      error,
    },
  ] = useUpdateLinksMutation();

  const handleLinkEdit = (field, value) => {
    setLinks({
      ...links,
      [field]: value,
    });
  };

  // const linksData = {
  //   instagram: `instagram.com/${links.instagram}`,
  //   facebook: facebookLinkValue,
  //   tiktok: tiktokLinkValue,
  //   whatsapp: whatsappLinkValue,
  //   // awashop: urls.awaShop + links?.awaShop,
  // };

  const handleUpdateLinks = async () => {
    setReRender(!reRender);
    const dynamicLinksData = {};
    if (links.awaShop !== client?.profileDetails?.business?.slug) {
      dynamicLinksData.awaShop = `awashop.ng/${links.awaShop}`;
    }

    if (
      links.instagram !== "" &&
      links.instagram !== client?.links?.instagram
    ) {
      dynamicLinksData.instagram = `instagram.com/${links.instagram}`;
    }

    if (links.tiktok !== "" && links.tiktok !== client?.links?.tiktok) {
      dynamicLinksData.tiktok = `tiktok.com/@${links.tiktok}`;
    }

    if (links.whatsapp !== "" && links.whatsapp !== client?.links?.whatsapp) {
      dynamicLinksData.whatsapp = `wa.me/+234${links.whatsapp}`;
    }

    if (links.facebook !== "" && links.facebook !== client?.links?.facebook) {
      dynamicLinksData.facebook = `facebook.com/${links.facebook}`;
    }

    if (Object.keys(dynamicLinksData).length > 0) {
      await updateLinks(dynamicLinksData);
    }
  };

  useEffect(() => {
    if (linksUpdateSuccess) {
      setToastMessage({
        message: "Links updated successfully",
        status: "success",
        heading: "Success",
      });
    }

    return () => {};
  }, [linksUpdateSuccess]);

  let errorMessage = "Something went wrong";
  let errorStatus = "error";
  let errorHeading = "Error";

  const handleInputChange = (field) => {
    const anyFieldIsTrue = Object.values(inputError).some((value) => value);
    if (anyFieldIsTrue) {
      console.log("checked");
      setInputError((prevState) => ({
        ...prevState,
        [field]: false,
      }));
    }
  };

  useEffect(() => {
    if (linksUpdateError) {
      if (error?.status === 422) {
        const errorData = error?.data?.data;
        setErrorDataObject(errorData);

        console.table(errorData);

        if (error?.data?.status) {
          errorHeading = `${error?.data?.status}`;
        }

        if (errorData) {
          const firstError = errorData;

          if (firstError) {
            if (firstError.instagram) {
              errorMessage = `"${links?.instagram}" ${firstError.instagram[0]}`;
            }
            if (firstError.tiktok) {
              errorMessage = `"${links?.tiktok}" ${firstError.tiktok[0]}`;
            }

            if (firstError.whatsapp) {
              errorMessage = `"${links?.whatsapp}" ${firstError.whatsapp[0]}`;
            }
            if (firstError.facebook) {
              errorMessage = `"${links?.facebook}" ${firstError.facebook[0]}`;
            }
          }
        }
        setToastMessage({
          message: errorMessage,
          status: errorStatus,
          heading: errorHeading,
        });
      } else if (error?.status === 401) {
        setToastMessage({
          message: error?.data?.message || "Something went wrong",
          status: "error",
          heading: error?.data?.status || "Error",
        });
        localStorage.removeItem("authToken");
        setTimeout(() => {
          navigate(clientPages.login);
        }, 2000);
      } else {
        setToastMessage({
          message: error?.data?.message || "Something went wrong",
          status: "error",
          heading: error?.data?.status || "Error",
        });
      }
    }
    return () => {};
  }, [
    reRender,
    linksUpdateError,
    error?.status,
    error?.data,
    error?.data?.data,
  ]);

  useEffect(() => {
    if (linksUpdateError && errorDataObject) {
      if (
        "instagram" in errorDataObject ||
        "whatsapp" in errorDataObject ||
        "facebook" in errorDataObject ||
        "tiktok" in errorDataObject
      ) {
        setInputError({
          tiktok: "tiktok" in errorDataObject,
          whatsapp: "whatsapp" in errorDataObject,
          instagram: "instagram" in errorDataObject,
          facebook: "facebook" in errorDataObject,
        });
      }
    }
  }, [errorDataObject, linksUpdateError]);

  console.log("itis", errorDataObject);

  // facebook: ["is not a valid Facebook profile URL"];
  // instagram: ["is not a valid Instagram profile URL"];
  // tiktok: ["is not a valid TikTok profile URL"];

  return (
    <>
      {linksUpdateSuccess ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="bottom-right"
        />
      ) : (
        " "
      )}
      {linksUpdateError && error?.status === 422 ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {linksUpdateError && error?.status !== 422 ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      <section className={styles["links"]}>
        <Form headerTitle={"Links"} title={"Shop Links"}>
          <LinkInput
            url={urls.awaShop}
            icon={"lock"}
            hasLabel={false}
            ref={awaShopRef}
            value={links.awaShop}
            onChange={(e) => handleLinkEdit("awaShop", e.target.value.trim())}
            disabled
          />
          <h1 className="form-title-main">Social Media Links</h1>
          <LinkInput
            url={urls.instagram}
            icon={"instagram"}
            label={"Instagram Account"}
            ref={instagramRef}
            value={links?.instagram?.split(".com/")[1]}
            onChange={(e) => handleLinkEdit("instagram", e.target.value.trim())}
            activeLinks={client.links.instagram ? true : false}
            outline={inputError.instagram}
            onInput={() => handleInputChange("instagram")}
          />
          <LinkInput
            url={urls.tiktok}
            icon={"tiktok"}
            label={"TikTok Account"}
            ref={tiktokRef}
            value={links?.tiktok?.split(".com/@")[1]}
            onChange={(e) => handleLinkEdit("tiktok", e.target.value.trim())}
            activeLinks={client.links.tiktok ? true : false}
            outline={inputError.tiktok}
            onInput={() => handleInputChange("tiktok")}
          />
          <LinkInput
            url={urls.whatsapp}
            icon={"whatsapp"}
            label={"Whatsapp Account"}
            ref={whatsappRef}
            value={links?.whatsapp?.split(".com/")[1]}
            onChange={(e) => handleLinkEdit("whatsapp", e.target.value.trim())}
            activeLinks={client.links.whatsapp ? true : false}
            outline={inputError.whatsapp}
            onInput={() => handleInputChange("whatsapp")}
          />
          <LinkInput
            url={urls.facebook}
            icon={"facebook"}
            label={"Facebook Account"}
            ref={facebookRef}
            value={links?.facebook?.split(".com/")[1]}
            onChange={(e) => handleLinkEdit("facebook", e.target.value.trim())}
            activeLinks={client.links.facebook ? true : false}
            outline={inputError.facebook}
            onInput={() => handleInputChange("facebook")}
          />
          <Button
            color={"completed"}
            label={isLoading ? "" : "Update Links"}
            hasIcon
            iconPosition="right"
            icon={isLoading ? "spinner-alt" : ""}
            height={36}
            handleClick={handleUpdateLinks}
          />
        </Form>
      </section>
    </>
  );
};

export default Links;
