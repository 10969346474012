import React, { useState } from "react";
import styles from "./Onboarding.module.scss";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import StepFive from "./Steps/StepFive";
import LastStep from "./Steps/LastStep";

const Onboarding = () => {
  const [nextStep, setNextStep] = useState(0);

  const stepsArray = [
    <StepOne key={0} setNextStep={setNextStep} nextStep={nextStep} />,
    <StepTwo key={1} setNextStep={setNextStep} nextStep={nextStep} />,
    <StepThree key={2} setNextStep={setNextStep} nextStep={nextStep} />,
    <StepFour key={3} setNextStep={setNextStep} nextStep={nextStep} />,
    <StepFive key={4} setNextStep={setNextStep} nextStep={nextStep} />,
    <LastStep key={5} setNextStep={setNextStep} nextStep={nextStep} />,
  ];

  return (
    <div className={styles.onboarding}>
      {stepsArray.map((step, index) => {
        return index === nextStep ? step : null;
      })}
    </div>
  );
};

export default Onboarding;
