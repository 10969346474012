import React from "react";
import Button from "../../../Components/Atoms/Button/Button";
import styles from "./Form.module.scss";

const Form = ({
  buttonLabel,
  children,
  onKeyPress,
  color,
  handleButtonClick,
  onSubmit,
  onKeyDown,
  onPaste,
  icon,
}) => {
  return (
    <form
      className={styles.form}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      onSubmit={onSubmit}
      onPaste={onPaste}
    >
      {children}
      <div className={styles.actions}>
        <Button
          label={buttonLabel}
          color={color || "completed"}
          hasIcon={true}
          iconPosition="right"
          icon={icon || "arrow-small-right"}
          handleClick={handleButtonClick}
          height={36}
        />
        <span>press enter</span>
      </div>
    </form>
  );
};

export default Form;
