import React from "react";
import Modal from "../Modal";
import { Button, LineBreak } from "../../Atoms";
import styles from "./ViewOrders.module.scss";
import OrdersCardDetails from "../../Cards/Orders/OrdersCardDetails/OrdersCardDetails";
import { useSelector } from "react-redux";

const ViewOrders = ({ orderID, modalOpen, setModalOpen, total }) => {
  const client = useSelector((state) => state.client.value);
  const phoneScreenWidth = 650;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  console.log("everything is", client?.selectedCart);
  return (
    <Modal
      headerTitle={orderID}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      modalSize={!isPhoneScreen ? 549 : "80%"}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={styles.info}>
        <h6 className={styles["info-header"]}>Buyer's Information</h6>
        <ul className={styles["info-list-container"]}>
          <li className={styles.list}>
            {client?.selectedCart[0]?.recipientName}
          </li>
          <li className={styles.list}>
            {client?.selectedCart[0]?.recipientStreet}
          </li>
          <li className={styles.list}>{`${
            client?.selectedCart[0]?.recipientCity
          }, ${client?.selectedCart[0]?.recipientState} ${
            client?.selectedCart[0]?.recipientPostCode || ""
          }`}</li>
          <li className={styles.list}>
            {client?.selectedCart[0]?.recipientCountry}
          </li>
        </ul>
      </div>

      {client?.selectedCart?.map((item, index) => (
        <OrdersCardDetails
          key={index}
          image={item.productImage}
          productTitle={item.productTitle}
          productDescription={item.productDescription}
          numberOfItems={item.numberOfItems}
          totalPrice={item.price}
        />
      ))}

      <LineBreak color={"var(--shade-100"} />
      <div className={styles.bottom}>
        Total{" "}
        <p className={styles.total}>
          NGN<span className={styles["total-bold"]}>{total}</span>
        </p>
      </div>
      <Button color={"orange"} label={"Refund"} height={36} />
    </Modal>
  );
};

export default ViewOrders;
