import React, { useState, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Text.module.scss";
import Card from "../../../Card/Card";
import Icon from "../../../Icons/Icon";

const Text = React.forwardRef(
  (
    {
      iconPosition,
      hasLabel,
      hasIcon,
      placeholder,
      icon,
      type,
      iconColor,
      label,
      disabled,
      name,
      borderRadius,
      value,
      onChange,
      height,
      outline,
      onIconClick,
      id,
      onInput,
      onPaste,
      autoFocus,
      background,
      className,
      maxLength,
      width,
    },
    ref
  ) => {
    const [isActive, setisActive] = useState(false);
    const inputRef = useRef();

    // const activate = () => {
    //   inputRef.current.focus();
    // };

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return inputRef.current.value;
      },
      focus: () => {
        inputRef.current.focus();
      },
    }));

    const setActive = () => {
      setisActive(true);
    };

    const unsetActive = () => {
      setisActive(false);
    };

    return (
      <div
        className="space-ui-text-input"
        style={{ cursor: disabled ? "not-allowed" : "" }}
      >
        {hasLabel ? <label htmlFor="text-input">{label}</label> : ""}
        <Card
          id="text-input"
          className={`${
            styles["input-container"]
          } ${className}  space-ui-text-input ${
            isActive ? styles.active : ""
          } ${outline ? styles.error : ""}`}
          style={{
            marginTop: hasLabel ? "10px" : "",
            borderRadius: borderRadius,
            height: height,
            background: background,
            minWidth: width,
          }}
        >
          {iconPosition === "left" && (
            <>
              {hasIcon ? (
                <Icon
                  icon={icon}
                  color={iconColor}
                  width="14"
                  height="14"
                  onClick={onIconClick}
                />
              ) : (
                ""
              )}
              <input
                type={type}
                name={name}
                ref={inputRef}
                onPaste={onPaste}
                placeholder={placeholder}
                className={styles.input}
                onFocus={setActive}
                onBlur={unsetActive}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onInput={onInput}
                id={id}
                autoFocus={autoFocus}
                maxLength={maxLength}
                style={{ cursor: disabled ? "not-allowed" : "" }}
              />
            </>
          )}

          {iconPosition === "right" && (
            <>
              <input
                type={type}
                name={name}
                ref={inputRef}
                onPaste={onPaste}
                placeholder={placeholder}
                className={styles.input}
                onFocus={setActive}
                onBlur={unsetActive}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onInput={onInput}
                id={id}
                autoFocus={autoFocus}
                maxLength={maxLength}
                style={{ cursor: disabled ? "not-allowed" : "" }}
              />
              {hasIcon ? (
                <Icon
                  icon={icon}
                  color={iconColor}
                  width="14"
                  height="14"
                  onClick={onIconClick}
                />
              ) : (
                ""
              )}
            </>
          )}
        </Card>
      </div>
    );
  }
);

Text.defaultProps = {
  type: "text",
  iconPosition: "left",
  placeholder: "enter text here",
  outline: false,
};

Text.propTypes = {
  iconPosition: PropTypes.oneOf(["left", "right"]),
  hasLabel: PropTypes.bool,
  hasIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  // value: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
};

export default Text;
