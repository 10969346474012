import React from "react";
import styles from "./LineBreak.module.scss";

const LineBreak = ({ color }) => {
  return (
    <div style={{ background: color }} className={styles["line-break"]}></div>
  );
};

export default LineBreak;
