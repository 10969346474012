import React, { useEffect, useRef, useState } from "react";
import Header from "../../Headers/Headers";
import styles from "./DashboardBase.module.scss";
import { useGetProfileQuery } from "../../../redux/services/client/accounts";
import { useDispatch, useSelector } from "react-redux";
import { updateClientData } from "../../../redux/features/newClient";
import {
  Loader,
  RequestToGoLive,
  Toast,
  VerificationPrompt,
} from "../../Atoms";
import { useNavigate } from "react-router-dom";
import { clientPages } from "../../../Routes/routes";

const DashboardBase = ({ children }) => {
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [idVerified, setIdVerified] = useState(false);

  const [verificationStatus, setVerificationStatus] = useState("");

  const client = useSelector((state) => state.client.value);

  const [toastMessage, setToastMessage] = useState({
    message: null,
    status: "",
    heading: "",
  });
  const { message, status, heading } = toastMessage;
  const {
    data: profileDetails,
    isSuccess,
    isError,
    error,
  } = useGetProfileQuery();

  const navigate = useNavigate();

  const {
    first_name,
    last_name,
    email,
    business,
    phone_verified,
    identity_verification,
  } = profileDetails?.data || {};
  const { social_link, active, status: verifiedStatus } = business || {};

  const dispatch = useDispatch();

  const phoneScreenWidth = 768;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const [containerHeight, setContainerHeight] = useState(0);

  const handleLiftHeight = (height) => {
    setContainerHeight(height);
  };

  useEffect(() => {
    if (isSuccess) {
      if (profileDetails?.data?.status !== "active") {
        localStorage.setItem(
          "AwashopCompanyStatus",
          profileDetails?.data?.status
        );
        navigate(clientPages.signup);
        return;
      } else
        dispatch(
          updateClientData({
            links: social_link || {},
            profileDetails: profileDetails?.data,
          })
        );
    }
    return () => {};
  }, [isSuccess, profileDetails?.data]);

  useEffect(() => {
    if (isError && error?.status === 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
      localStorage.removeItem("authToken");
      setTimeout(() => {
        navigate(clientPages.login);
      }, 2000);
    }

    if (isError && error?.status !== 401) {
      setToastMessage({
        message: error?.data?.message || "Something went wrong",
        status: "error",
        heading: error?.data?.status || "Error",
      });
    }

    return () => {};
  }, [isError, error?.data, error?.status]);

  useEffect(() => {
    if (phone_verified === true) {
      setPhoneVerified(true);
    }
    if (identity_verification?.status === "verified") {
      setIdVerified(true);
    }
    if (
      phone_verified === true &&
      identity_verification?.status === "verified" &&
      verifiedStatus !== "pending"
    ) {
      setVerificationStatus("new");
    }
    if (verifiedStatus === "pending") {
      setVerificationStatus("pending");
    }

    if (verifiedStatus === "available") {
      setVerificationStatus("available");
    }
    return () => {};
  }, [isSuccess, profileDetails?.data]);

  useEffect(() => {
    if (isError && error?.status === 404) {
      navigate("*");
    }
    return () => {};
  }, [isError, error?.status]);

  useEffect(() => {
    // Empty function body to force re-render
  }, [phoneVerified, idVerified]);

  useEffect(() => {
    // Empty function body to force re-render
  }, [verificationStatus, containerHeight]);

  return (
    <>
      {isError && error?.status === 401 ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}
      {isError && error?.status !== 401 ? (
        <Toast
          heading={heading}
          status={status}
          message={message}
          position="top-right"
        />
      ) : (
        ""
      )}

      <div className={styles["dashboard-base"]} id="dashboardBase">
        {!first_name ? (
          <Loader size={70} />
        ) : (
          <div className={styles.inner}>
            <Header userName={`${first_name} ${last_name}`} ownership={email} />
            <VerificationPrompt
              idVerified={idVerified}
              phoneVerified={phoneVerified}
              liftHeight={handleLiftHeight}
            />
            <RequestToGoLive
              verificationStatus={verificationStatus}
              liftHeight={handleLiftHeight}
            />
            <div className={styles.main__inner_body}>{children}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardBase;
