import React, { useImperativeHandle, useRef } from "react";
import styles from "./Date.module.scss";
import { Icon } from "../../../../Atoms";

const Date = React.forwardRef(
  (
    { value, onChange, hasLabel, label, placeholder, iconColor, textColor },
    ref
  ) => {
    const inputRef = useRef(null);

    const handleCalendarClick = () => {
      inputRef.current.showPicker();
    };

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return inputRef.current.value;
      },
    }));

    return (
      <div className={styles["date-input-container"]}>
        {hasLabel ? <label htmlFor={inputRef}>{label}</label> : ""}
        <div className={styles["date-input"]}>
          <Icon
            icon="calendar"
            color={iconColor || "shade-100"}
            onClick={handleCalendarClick}
          />
          <input
            type="date"
            placeholder={placeholder || "December 1, 2023"}
            value={value}
            onChange={onChange}
            ref={inputRef}
            style={{ color: textColor }}
          />
        </div>
      </div>
    );
  }
);

Date.defaultProps = {
  hasLabel: true,
};

export default Date;
