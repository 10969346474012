import React, { useRef, useEffect } from "react";
import Modal from "../Modal";

const MonitorPayment = ({
  modalOpen,
  setModalOpen,
  onClose,
  url,
  isForCheckout,
}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleMessage = (event) => {
      if (event) {
        // console.log("URL changed:", event.origin);
        // setModalOpen(false);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <Modal
      headerTitle={"Complete Payment"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClose={onClose}
      modalSize={"100%"}
      isForCheckout={isForCheckout}
      modalheight={"100vh"}
    >
      <iframe
        src={url}
        title="Payment"
        width="100%"
        height="100%"
        id="mainframe"
        style={{ border: "none", width: "100%", height: "100%" }}
        ref={iframeRef}
        className="payment-iframe"
      ></iframe>
    </Modal>
  );
};

export default MonitorPayment;
