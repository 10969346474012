import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateClientData } from "../../../../redux/features/newClient";
import { clientPages } from "../../../../Routes/routes";
import { useValidateInvitationQuery } from "../../../../redux/services/client/onboarding";
import { useLocation, useNavigate } from "react-router-dom";
import { useClientStartLogic } from "../../../../Hooks/onboardingHooks";

export function useStepOneLogic(setNextStep, nameRef) {
  const client = useSelector((state) => state.client.value);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [inputValue, setInputValue] = useState("");
  const [invalidName, setInvalidName] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const typedLength = inputValue.length >= 3;
    const addOutline = typedLength && invalidName;
    if (addOutline) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
    return () => {};
  }, [inputValue, inputValue.length, invalidName]);

  const isInvitation = window.location.pathname.includes("invitations");

  // if (
  //   localStorage.getItem("authToken") !== null &&
  //   localStorage.getItem("hasCompanyData") === "false"
  // ) {
  //   setNextStep(4);
  // }

  const params = new URLSearchParams(location.search);

  let reference, email, companySlug;

  if (isInvitation) {
    reference = params.get("ref");
    email = params.get("email");
    companySlug = params.get("company_slug");
  }

  const {
    data: response,
    isSuccess,
    isError,
  } = useValidateInvitationQuery(
    {
      companySlug: companySlug,
      reference: reference,
      email: email,
    },
    { skip: !isInvitation }
  );

  if (isSuccess) {
    if (response.data.usable === false) {
      navigate(clientPages.start);
    } else {
      dispatch(
        updateClientData({
          reference: reference,
          email_address: email,
          isInvited: true,
        })
      );
    }
  }

  if (isError) {
    navigate(clientPages.start);
  }

  const { setIsReadyForStart } = useClientStartLogic(client, setNextStep);

  const handleTextInput = (e) => {
    const value = e.target.value.trim();
    setInputValue(value);
    const words = value.split(/\s+/);

    if (words.length === 2) {
      setInvalidName(false);
      e.target.value = words.slice(0, 2).join(" ");
    } else if (words.length === 1) {
      setInvalidName(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleButtonClick();
    }
  };

  const handleButtonClick = () => {
    const words = nameRef.current.getValue().split(/\s+/);
    if (words.length === 2) {
      const [first, last] = words;
      const signUpData = {
        first_name: first,
        last_name: last,
      };
      dispatch(
        updateClientData({
          first_name: signUpData.first_name.trim(),
          last_name: signUpData.last_name.trim(),
        })
      );
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        if (isInvitation) {
          setIsReadyForStart(true);
        } else {
          setNextStep(1);
        }
      }, 1000);
    }
  };

  return {
    handleTextInput,
    handleKeyPress,
    handleButtonClick,
    isLoading,
    isTyping,
  };
}
